import { Activity } from './Activity';
import { ActivityPSRSnapshot } from './ActivityPSRSnapshot';
import { MTABaseEntity } from './MTABaseEntity';

export class WBS extends MTABaseEntity {
	Id: number;
	GUID: string;
	Code: string;
	Name: string;
	ForecastFinishDate: string;
	ForecastStartDate: string;
	ObsId: number;
	Status: string;
	ParentId: number;
	ProjectId: number;
	IntegratedType: string;
	SequenceNumber: number;
	UpdatedBy: string;
	UpdatedDate: string;
	WBSCategoryId: number;
	GlobalOrder: number;
	// -- helpers
	equipmentClassWBS: WBS;
	equipmentClassLevel: number;
	wbsPath: string;
	wbsCategoryId: number;
	wbsCategoryName: string;
	activityCount: number;
	activities: Activity[];
	filteredActivities: Activity[];
	filteredActivityCount: number;
	orderNumber: number;
	paths: string[];
	longPath: string;
	pathSeparactor: string;
	isSearchFufilled: boolean;
	IsReadOnly: boolean;
	activitiesForPSR: ActivityPSRSnapshot[];
	children: WBS[];
	parent: WBS;
	HasNoContractors: boolean;

	init(json: any): void {
		this.GUID = json.GUID;
		this.Id = json.Id;
		this.Name = json.Name;
		this.ObsId = json.ObsId;
		this.ProjectId = json.ProjectId;
		this.Status = json.Status;
		this.UpdatedBy = json.UpdatedBy;
		this.UpdatedDate = json.UpdatedDate;
		this.Code = json.Code;
		this.ForecastFinishDate = json.ForecastFinishDate;
		this.ForecastStartDate = json.ForecastStartDate;
		this.ParentId = json.ParentId;
		this.IntegratedType = json.IntegratedType;
		this.SequenceNumber = json.SequenceNumber;
		this.WBSCategoryId = json.WBSCategoryId;
		this.GlobalOrder = json.GlobalOrder ? json.GlobalOrder : 0;
	}
}
