import { Component, Injector, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ActivityInternalStatus } from '../../../data/ActivityInternalStatus';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-tri-toggle-tablet',
	templateUrl: 'tri-toggle.component-tablet.html',
})
export class TriToggleTabletComponent extends MTAContainerComponent {
	@Input()
	status: ActivityInternalStatus;
	@Input()
	disabled: boolean;
	@Input()
	getStyle: () => Array<string>;

	constructor(public injector: Injector, private alertCtrl: AlertController) {
		super(injector);
	}

	onClick() {
		if (
			this.status === ActivityInternalStatus.Rejected ||
			this.status === ActivityInternalStatus.Approved
		) {
			this.status = ActivityInternalStatus.Updated;

			this.something.emit({ operation: 'onClick', params: this.status });
		}
	}
	async onTogglePan(e) {
		const originStatus = this.status;
		const changeX = e.deltaX;
		if (
			(this.status === ActivityInternalStatus.Rejected && changeX > 20) ||
			((this.status === ActivityInternalStatus.Pending ||
				this.status === ActivityInternalStatus.Updated) &&
				changeX > 12)
		) {
			this.status = ActivityInternalStatus.Approved;
		} else if (changeX < 0) {
			// moving left
			if (
				(this.status === ActivityInternalStatus.Approved &&
					Math.abs(changeX) > 20) ||
				((this.status === ActivityInternalStatus.Pending ||
					this.status === ActivityInternalStatus.Updated) &&
					Math.abs(changeX) > 12)
			) {
				this.status = ActivityInternalStatus.Rejected;
			}
		}

		if (this.status !== originStatus) {
			if (this.status === ActivityInternalStatus.Rejected) {
				const alert = await this.alertCtrl.create({
					header: 'Confirm',
					message: 'Are you sure you want to reject this activity?',
					backdropDismiss: false,
					buttons: [
						{
							text: 'Yes',
							handler: () => {
								this.something.emit({
									operation: 'onTogglePan',
									params: this.status,
								});
							},
						},
						{
							text: 'No',
							handler: () => {
								this.status = originStatus;
							},
						},
					],
				});
				await alert.present();
			} else {
				this.something.emit({ operation: 'onTogglePan', params: this.status });
			}
		}
	}
}
