import { Component, Injector, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityStartJustification, ActivityStartJustificationType, User } from 'src/data/EntityIndex';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { DbService } from './../../services/db.service';
import { JustificationCellPhoneComponent } from './phone/justification-cell.component-phone';
import { JustificationCellTabletComponent } from './tablet/justification-cell.component-tablet';

@Component({
  selector: 'app-justification-cell',
  templateUrl: './justification-cell.component.html',
  styleUrls: ['./justification-cell.component.scss'],
})
export class JustificationCellComponent extends MTAContainerComponent implements OnInit {
  @Input() justification: ActivityStartJustification;
  @Input() justificationTypes: ActivityStartJustificationType[];
  private _shiftIcon = 'moon';
  get shiftIcon(): string {
    return this._shiftIcon;
  }

  set shiftIcon(value: string) {
    this._shiftIcon = value;
  }

  shiftName = this.translate.instant('INSTANT.Day');
  usr: Promise<User>;
  constructor(private dbManager: DbService, public injector: Injector, private translate: TranslateService) {
    super(injector);
  }

  ngOnInit() {
    this.initShiftInfo();
    this.subComponentJson = { tablet: JustificationCellTabletComponent, phone: JustificationCellPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      justification: this.justification,
      shiftName: this.shiftName,
      shiftIcon: this.shiftIcon,
      getUserInitial: () => {
        return this.getUserInitial();
      },
      getUserFullname: () => {
        return this.getUserFullName();
      },
      getJustificationTypeName: value => {
        return this.getJustificationTypeName(value);
      }
    };
    this.outputs = {};
  }

  getJustificationTypeName(justification: ActivityStartJustification): string {
    let ret = '';
    if (this.justificationTypes) {
      for (const justificationType of this.justificationTypes) {
        if (justification.TypeId === justificationType.Id) {
          ret = justificationType.Text;
          break;
        }
      }
    }

    return ret;
  }

  getCreatedUser(justification: ActivityStartJustification): Promise<User> {
    const uid = justification.CreatedById;
    return this.dbManager.getUserById(uid);
  }

  getUserInitial(): string {
    return `${this.justification.userFirstname.charAt(0)}${this.justification.userLastname.charAt(0)}`;
  }

  getUserFullName(): string {
    return `${this.justification.userFirstname} ${this.justification.userLastname}`;
  }

  initShiftInfo() {
    if (this.justification.NewDate) {
      const newDate = new Date(this.justification.NewDate).getHours();
      if (newDate >= 5 && newDate < 17) {
        this.shiftName = this.translate.instant('INSTANT.Day');
        this.shiftIcon = 'sunny';
      } else {
        this.shiftName = this.translate.instant('INSTANT.Night');
        this.shiftIcon = 'moon';
      }
    } else {
      this.shiftName = 'N/A';
      this.shiftIcon = '';
    }
  }

}
