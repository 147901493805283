import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-resource-list-phone',
	styleUrls: ['resource-list.component-phone.scss'],
	templateUrl: 'resource-list.component-phone.html',
})
export class ResourceListPhoneComponent extends MTAContainerComponent {
	@Input()
	title: string;
	@Input()
	showResources: boolean;
	@Input()
	resources: Array<any>;

	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}

	toggleResources() {
		this.showResources = !this.showResources;
		if (this.showResources) {
			this.something.emit({ operation: 'getResources', params: null });
		}
	}
}
