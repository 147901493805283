import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { ProjectConfigEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class ProjectConfigServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = ProjectConfigEntityManager;

  public serviceName = 'activity-code-configuration';
  public downloadOperationName = 'project/list';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${this.downloadOperationName}/${config.project}`;
    } else {
      return null;
    }
  }
  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
