import { Injectable } from '@angular/core';
import {
  ActivityPSRSnapshot,
  Project,
  WBS,
  Activity,
  UDFCodeValue,
  WBSCategory,
  FlattenActivityCode,
} from '../../data/EntityIndex';
import {
  ActivityEntityManager,
  WBSEntityManager,
} from '../../data/EntityManagerIndex';
import { Filters, Filter_Shift } from '../../data/Filters';
import { ActivityInternalStatus } from './../../data/ActivityInternalStatus';
import { RoleType } from './../../data/InternalTypes';
import { SyncManager } from '../../services/sync/SyncManager';
import { db } from '../indexDb.service';
import * as moment from 'moment';

export interface IWbsResult {
  Id: number;
  ProjectId: number;
  Name: string;
  GlobalOrder: number;
  wbsCategoryName: string;
  wbsCategoryId: number;
  orderNumber: string;
  Priority: number;
  HasNoContractors?: boolean;
  IsReadOnly?: boolean;
  filteredActivityCount?: number;
  filteredActivities?: Array<any>;
  CrewId?: number;
}
/*
  Generated class for the WbsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class WbsProvider {
  allWbs: WBS[] = []; // the list of WBS which has more than 1 activity
  equipmentClassWBS: WBS;
  rootWbs: WBS;
  project: Project;
  crewIdStr: string;
  workOrders: any[];

  unflattenedHierarchyArray: WBS[];
  flattenedHierarchyArray: WBS[];
  fullFlattenedHierarchyArray: WBS[];

  constructor(
    private activityProvider: ActivityEntityManager,
    private wbsProvider: WBSEntityManager,
    public sm: SyncManager
  ) {}

  async initForProject(project: Project, projectId: number): Promise<boolean> {
    if (
      this.project === null ||
      this.project === undefined ||
      this.project.Id !== projectId
    ) {
      this.project = project;
      this.allWbs = [];
      this.workOrders = null;
      this.unflattenedHierarchyArray = null;
      this.flattenedHierarchyArray = null;
      this.fullFlattenedHierarchyArray = null;
      await this._queryWorkOrderNumberForWBSs();
    }
    return true;
  }

  async getWBSCategoriesForUser$(params: {
    project: Project;
    role: RoleType;
    crews: any[];
    crewFilter: Filters;
    shiftFilter?: string;
    orderBy?: string;
    activityStatus?: ActivityInternalStatus[];
    query?: string;
    activityFilter?: boolean;
  }): Promise<Array<IWbsResult>> {
    await this.initForProject(params?.project, params?.project?.Id);

    //return new Promise(async (resolve, reject) => {
    const crewsIdStr = params?.crews?.map((c) => c.CrewId);
    const helpers = this._getHelpers(params.activityStatus);

    const activitiesList = await this._getActivityListForWBSCategoriesUser(
      params?.project.Id,
      helpers
    );
    const wbsList = await db.wbs
      .where('Id')
      .anyOf(activitiesList.map((a) => a.WbsId))
      .toArray();
    const wbsCategoryList = await db.wbsCategory
      .where('Id')
      .anyOf(wbsList.map((w) => w.WBSCategoryId))
      .toArray();
    let flattenActivityCodeList = await db.flattenActivityCode
      .where('ActivityId')
      .anyOf(activitiesList.map((a) => a.Id))
      .and((fac) => fac.TawExcluded === false)
      .toArray();

    let crewAssignmentList = [];
    if (params?.crewFilter === Filters.MyJobs) {
      const userId = +localStorage.getItem('UserId');
      crewAssignmentList = await db.crewAssignment
        .where('ProjectId')
        .anyOf(activitiesList.map((a) => a.ProjectId))
        .and((ca) => ca.UserId == userId)
        .toArray();
      flattenActivityCodeList = flattenActivityCodeList.filter((fac) => {
        if (params.crewFilter === Filters.MyJobs) {
          if (params.role === RoleType.CR) {
            return crewsIdStr.includes(fac.CompanyRepId);
          } else if (params.role === RoleType.CF) {
            return (
              crewsIdStr.includes(fac.MainCrewId) ||
              crewsIdStr.includes(fac.SupportCrewId)
            );
          }
        }
        return true;
      });
    }
    const priorities = flattenActivityCodeList.map((fac) => +fac.Priority);
    const Priority = Math.min.apply(null, priorities);

    const partialResult = this._getActivitiesPartialResult(
      activitiesList,
      wbsList,
      wbsCategoryList,
      flattenActivityCodeList,
      Priority,
      params?.query,
      params?.shiftFilter
    );

    const crew = params.crews.filter((c) => c.IsDeleted === false)[0];

    return this._formatFinalActivitiesResult(
      partialResult,
      wbsList,
      wbsCategoryList,
      activitiesList,
      flattenActivityCodeList,
      crew,
      params?.role,
      params?.shiftFilter,
      params?.activityStatus
    );
    //});
  }

  // init work order number for wbs
  async getWorkOrderNumberForWBSs(
    WBSs: WBS[],
    projectId: number
  ): Promise<WBS[]> {
    //return new Promise<WBS[]>(async (resolve, reject) => {
    for (const wbs of WBSs) {
      wbs.orderNumber = this._getWorkOrderNumberForWbs(wbs.Id);
    }
    return WBSs as WBS[];
    //});
  }

  async getWBSActivityProperties(
    wbs: WBS,
    crewsIdStr: string,
    role: string,
    filterProps: Filters,
    activityStatus?: ActivityInternalStatus[],
    TCOWbs?: boolean,
    activityQuery?: string
  ): Promise<any> {
    const activitiesList = await this.getActivitiesList(wbs, activityStatus, activityQuery);
    const activityIds = activitiesList.map((a) => a.Id);

    const facList = await this.getFlattenActivityCodeList(activityIds, crewsIdStr, role, filterProps);

    const { udfList, justificationList, isReadyList } =
      await this.getActivityDependentLists(wbs, activityIds, TCOWbs);

    const returnedList: Activity[] = activitiesList.reduce((acc, activity) => {
      const fac = facList.find((f) => f.ActivityId === activity.Id);
      const udf = udfList.find((udf) => udf.ForeignId === activity.Id);
      const isCommentAvailable =justificationList.some((j) => j.ActivityId === activity.Id);
      const isReady = Boolean(isReadyList.find((item) => item.Id === activity.Id)?.IsReady);
      const textAndTypeTitle = TCOWbs && udf ? { Text: udf.Text, UDFTypeTitle: udf.UDFTypeTitle } : {};

      if (fac && (!TCOWbs || udf)) {
        acc.push({
          activityPriority: fac.Priority,
          Ewo: fac.Ewo,
          CompanyRepId: fac.CompanyRepId,
          MainCrewId: fac.MainCrewId,
          SupportCrewId: fac.SupportCrewId,
          CompanyRep: fac.CompanyRep,
          MainCrew: fac.MainCrew,
          SupportCrew: fac.SupportCrew,
          WorkOrderTask: fac.WorkOrderTask,
          TawExcluded: fac.TawExcluded,
          isCommentAvailable,
          isReady,
          ...activity,
          ...textAndTypeTitle,
        });
      }

      return acc;
    }, []);

    const activitiesHasComment = returnedList.filter(
      (a) => a.isCommentAvailable
    );

    if (activitiesHasComment?.length) {
      const comments =
        await this.activityProvider.getRejectCommentsForActivities(
          activitiesHasComment
        );

      for (const activity of activitiesHasComment) {
        const commentsForActivity = comments.filter(
          (c) => (c.ActivityId === activity.Id)
        );
        activity.lastRejectComment =
          commentsForActivity?.length ? [commentsForActivity[0]] : null;
      }
    }

    returnedList.forEach((item) => {
      item.isReadOnly = wbs.IsReadOnly;

      if (role === RoleType.CR) {
        item.HasNoContractors = wbs.HasNoContractors;
      }
    });

    returnedList.sort((a, b) =>
      moment.utc(a.StartDate).diff(moment.utc(b.StartDate))
    )

    return returnedList;
  }

  private getActivitiesList(
    wbs: WBS,
		activityStatuses?: ActivityInternalStatus[],
		activityQuery?: string
  ): Promise< Partial<Activity>[]> {
    return db.activity
      .where({ WbsId: wbs.Id, Type: 'TaskDependent' })
      .and((activity) => {
        // all 5 activity status in the filter:0: '', 1: 'Approved', 2: 'Updated', 3: 'CantWork' ,4: 'Rejected'
        const isNotCompleted = activity.Status !== 'Completed' && activity.PercentComplete !== 1;
        const isNotDeleted = activity.IsDeleted === false;
        let activityQueryPass = true;
        let activityStatusPass = this.checkActivityStatusPass(activity, activityStatuses);

        if (activityQuery !== null) {
          activityQueryPass = activity.ActivityId.includes(activityQuery) || activity.Name.includes(activityQuery);
        }

        return isNotCompleted && isNotDeleted && activityStatusPass && activityQueryPass;
      })
      .toArray();
  }

  private async getFlattenActivityCodeList(
    activityIds: number[],
		crewsIdStr: string,
		role: string,
		filterProps: Filters,
  ): Promise<Partial<FlattenActivityCode>[]> {
    return (
      await db.flattenActivityCode
        .where('ActivityId')
        .anyOf(activityIds)
        .and((fac) => {
          const baseCondition = fac.TawExcluded === false;

          if (filterProps === Filters.MyJobs) {
            if (role === RoleType.CF) {
              return (
                baseCondition &&
                (crewsIdStr.indexOf(`${fac.MainCrewId}`) > -1 ||
                  crewsIdStr.indexOf(`${fac.SupportCrewId}`) > -1)
              );
            } else {
              return (
                baseCondition &&
                crewsIdStr.indexOf(`${fac.CompanyRepId}`) > -1
              );
            }
          }

          return baseCondition;
        })
        .sortBy('Priority')
    ).map<Partial<FlattenActivityCode>>((fac) => {
      let isReadOnly = true;

      if (role === RoleType.CF) {
        isReadOnly =
          crewsIdStr.indexOf(`${fac.MainCrewId}`) === -1 &&
          crewsIdStr.indexOf(`${fac.SupportCrewId}`) === -1;
      } else {
        isReadOnly = crewsIdStr.indexOf(`${fac.CompanyRepId}`) === -1;
      }

      return { ...fac, isReadOnly };
    });
  }

  private async getActivityDependentLists(
    wbs: WBS,
    activityIds: number[],
    TCOWbs: boolean
  ) {
    let udfList: Partial<UDFCodeValue>[] = [];

    if (TCOWbs) {
      udfList = await db.udfCodeValue
        .where('ForeignId')
        .anyOf(activityIds)
        .toArray();
    }

    const justificationList = await db.justification
      .where('ActivityId')
      .anyOf(activityIds)
      .and((j) => j.IsDeleted === false)
      .toArray();

      const activity2List = await db.activity
      .where({
        ProjectId: wbs.ProjectId,
        Type: 'TaskDependent',
      })
      .and((a) => a.IsDeleted === false)
      .toArray();

    const relationshipList: Array<{
      Id: number;
      SuccessorActivityId: number;
      PredecessorActivityId: number;
    }> = (
      await db.relationship
        .where('SuccessorActivityId')
        .anyOf(activity2List.map((a) => a.Id))
        .toArray()
    ).reduce((acc, r) => {
      const a = activity2List.find((a) => a.Id === r.SuccessorActivityId);

      if (a)
        acc.push({
          Id: a.Id,
          SuccessorActivityId: r.SuccessorActivityId,
          PredecessorActivityId: r.PredecessorActivityId,
        });
      return acc;
    }, []);

    const isReadyList: Array<{
      Id: number;
      IsReady: boolean;
    }> = (
      await db.activity
        .where('Id')
        .anyOf(relationshipList.map((r) => r.PredecessorActivityId))
        .toArray()
    ).reduce((acc, a, _, tempList) => {
      if (acc.every((e) => e.Id !== a.Id)) {
        acc.push({
          Id: a.Id,
          IsReady: tempList.reduce((avg, item, _, { length }) => {
            return avg + item.PercentComplete / length;
          }, 0),
        });
      }

      return acc;
    }, []);

    return { udfList, justificationList, isReadyList };
  }

  private checkActivityStatusPass(
    activity: Partial<Activity>,
    activityStatuses: ActivityInternalStatus[]
  ): boolean {
    if (!activityStatuses?.length || activityStatuses.length === 5) {
      return true;
    }

    const isActivityStatusNull = activity.InternalStatus === null;
    const tempList = activityStatuses.filter(status => status !== ActivityInternalStatus.NoUpdate);
    let activityStatusPass = true;
    let internalStatus = false;

    if (tempList.length) {
      const statusStr = tempList.map((a) => `'${a}'`).join(',');
      activityStatusPass = statusStr.indexOf(activity.InternalStatus) > -1;
      internalStatus = activityStatusPass;
    }

    if (activityStatuses.some((status) => status === ActivityInternalStatus.NoUpdate)) {
      if (tempList.length) {
        activityStatusPass = activityStatusPass || isActivityStatusNull;
      } else {
        activityStatusPass = internalStatus
          ? activityStatusPass && isActivityStatusNull
          : isActivityStatusNull;
      }
    }

    return activityStatusPass;
  }

  async getWBSActivityPropertiesForPSR(
    project: Project,
    shiftNumber: number
  ): Promise<ActivityPSRSnapshot[]> {
      const apsList = await db.activityPSRSnapshot
        .where({
          ProjectId: project.Id,
          ShiftNumber: shiftNumber,
        })
        .sortBy('WbsId');
      const activityList = await db.activity
        .where('Id')
        .anyOf(apsList.map((aps) => aps.ActivityId))
        .sortBy('StartDate');
      const returnedList: ActivityPSRSnapshot[] = apsList.reduce((acc, aps) => {
        const a = activityList.find(
          (activity) => activity.Id === aps.ActivityId
        );
        if (a) {
          acc.push({
            ActivityId: a.Id,
            ActivityIdText: a.ActivityId,
            Name: a.Name,
            ShiftNumber: aps.ShiftNumber,
            WbsId: aps.WbsId,
            P6PercentOrigin: aps.P6PercentOrigin,
            P6PercentNew: aps.P6PercentNew,
            InternalPercentOrigin: aps.InternalPercentOrigin,
            InternalPercentNew: aps.InternalPercentNew,
            InternalStatusOrigin: aps.InternalStatusOrigin,
            InternalStatusNew: aps.InternalStatusNew,
          });
        }
        return acc;
      }, []);

      returnedList.sort((a, b) => moment.utc(a.StartDate).diff(moment.utc(b.StartDate)))

      return returnedList;
  }

  async getFlattenHierarchyArrayWithParent(project: Project): Promise<WBS[]> {
    await this.initForProject(project, project.Id);
    return new Promise<WBS[]>((resolve, reject) => {
      this._getHierarchyForEnvent(project).then((rets) => {
        this.unflattenedHierarchyArray = rets;
        this.fullFlattenedHierarchyArray = [];
        const hierarchyRoots = this._buildHierarchy(
          this.unflattenedHierarchyArray,
          project.WbsId
        );
        if (hierarchyRoots) {
          for (const hierarchyRoot of hierarchyRoots) {
            this._flattenHierarchyHierarchy(hierarchyRoot);
          }
        }
        resolve(this.fullFlattenedHierarchyArray);
      });
    });
  }

  getHierarchyPathStringForHierarchy(hierarchy: WBS): string {
    let str = '';
    if (hierarchy) {
      const hierarchyPath: WBS[] = [];

      // get the wbs from the flatten hierarchy list
      const hierarchyInList = this.fullFlattenedHierarchyArray.find(
        (e) => e.Id === hierarchy.Id
      );
      if (hierarchyInList) {
        this._getHierarchyPathForHierarchy(hierarchyInList, hierarchyPath);
        hierarchy.paths = hierarchyPath.slice(2).map((hir) => hir.Name);
        str = hierarchy.paths.join('&emsp;&rarr;&emsp;');
      }

      hierarchy.longPath = str;
    }

    return str;
  }

  private _getHierarchyPathForHierarchy(
    hierarchy: WBS,
    hirerarchyPath: WBS[]
  ): WBS[] {
    hirerarchyPath.push(hierarchy);
    if (hierarchy.parent) {
      return this._getHierarchyPathForHierarchy(
        hierarchy.parent,
        hirerarchyPath
      );
    } else {
      hirerarchyPath = hirerarchyPath.reverse();
      return hirerarchyPath;
    }
  }

  private _flattenHierarchyHierarchy(unflattenedHierarchy: WBS): void {
    if (unflattenedHierarchy) {
      this.fullFlattenedHierarchyArray.push(unflattenedHierarchy);
      const unflattenedChildHierarchyhierarchy = unflattenedHierarchy.children;
      if (unflattenedChildHierarchyhierarchy) {
        unflattenedChildHierarchyhierarchy.forEach((childHierarchy) => {
          childHierarchy.parent = unflattenedHierarchy;
          this._flattenHierarchyHierarchy(childHierarchy);
        });
      }
    }
  }

  private _buildHierarchy(arr: WBS[], projectWbsId: number): WBS[] {
    // get the root level - project
    const hierarchyRoots: WBS[] = [];

    for (const hirEle of arr) {
      hirEle.children = [];
      if (hirEle.ParentId === projectWbsId) {
        hierarchyRoots.push(hirEle);
      }
    }

    hierarchyRoots.forEach((root) => {
      root.children = this._getNestedChildren(arr, root.Id);
    });

    return hierarchyRoots;
  }

  private _getNestedChildren(arr: WBS[], parent: number): WBS[] {
    const out: WBS[] = [];
    for (const i in arr) {
      if (arr[i].ParentId === parent) {
        const children = this._getNestedChildren(arr, arr[i].Id);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }

  private async _getHierarchyForEnvent(project: Project): Promise<WBS[]> {
    await this.initForProject(project, project.Id);

    if (!this.unflattenedHierarchyArray) {
      this.unflattenedHierarchyArray =
        await this.wbsProvider.getHierarchyForEnvent(project.Id);
    }

    return this.unflattenedHierarchyArray;
  }

  private _getWorkOrderNumberForWbs(wbsId: number): number {
    let workOrderNumber: number;
    for (const workOrder of this.workOrders) {
      if (workOrder.wbsId === wbsId) {
        workOrderNumber = workOrder.Ewo;
        break;
      }
    }

    return workOrderNumber;
  }

  private async _queryWorkOrderNumberForWBSs(): Promise<
    {
      WbsId: any;
      Ewo: any;
    }[]
  > {
    // 	const sql = `select distinct a.WbsId, fac.Ewo from activity a
    // left join flattenActivityCode fac on a.Id = fac.ActivityId
    // where a.ProjectId =  ${this.project.Id} group by a.WbsId order by a.WbsId ASC;`;
    //return new Promise<any>(async (resolve, reject) => {
    const activities = await db.activity
      .where('ProjectId')
      .equals(this.project.Id)
      .distinct()
      .sortBy('WbsId');
    const flattenActivityCode = await db.flattenActivityCode
      .where('ActivityId')
      .anyOf(activities.map((a) => a.Id))
      .toArray();
    const workOrders = activities.reduce((wo: Array<{ WbsId; Ewo }>, a) => {
      if (wo.some((g) => g.WbsId === a.WbsId)) return wo;
      const fac = flattenActivityCode.find((fac) => fac.ActivityId == a.Id);
      if (fac) {
        wo.push({ WbsId: a.WbsId, Ewo: fac.Ewo });
      }
      return wo;
    }, []);
    this.workOrders = workOrders;
    return workOrders;
    //});
  }

  private _getFilteredActivities(
    activities: Partial<Activity>[],
    activityStatus: ActivityInternalStatus[],
    shiftFilter: string
  ): Partial<Activity>[] {
    let filteredActivities: Partial<Activity>[] = [];
    if (activities?.length > 0) {
      for (const activity of activities) {
        if (!this._isActivityInStatusFilters(activity, activityStatus)) {
          continue;
        }
        // filter for shift

        if (shiftFilter !== Filter_Shift.Shift_All) {
          if (!this._isActivityInCurrentShift(activity, shiftFilter)) {
            continue;
          }
        }
        filteredActivities.push(activity);
      }
    }
    return filteredActivities;
  }

  private _isActivityInCurrentShift(
    activity: Partial<Activity>,
    shift: string
  ): boolean {
    if (shift === Filter_Shift.Shift_All) {
      return true;
    }
    let shiftDiff = 0;
    switch (shift) {
      case Filter_Shift.Shift_24:
        shiftDiff = 1000 * 60 * 60 * 24;
        break;
      case Filter_Shift.Shift_48:
        shiftDiff = 1000 * 60 * 60 * 48;
        break;
      // case Filter_Shift.Shift_7:
      // 	shiftDiff = 1000 * 60 * 60 * 168;
      // 	break;
      // case Filter_Shift.Shift_14:
      // 	shiftDiff = 1000 * 60 * 60 * 336;
      // 	break;
      default:
        shiftDiff = 1000 * 60 * 60 * 12;
        break;
    }
    const startDate = new Date(activity.StartDate);
    const dataDate = new Date(activity.DataDate);
    if (startDate.getTime() <= dataDate.getTime() + shiftDiff) {
      // current shift
      return true;
    } else {
      return false;
    }
  }

  private _isActivityInStatusFilters(
    activity: Partial<Activity>,
    statusList: ActivityInternalStatus[]
  ) {
    let ret = false;
    if (
      statusList === undefined ||
      statusList?.length === 0 ||
      (statusList.indexOf(ActivityInternalStatus.NoUpdate) !== -1 &&
        activity.InternalStatus === null) ||
      statusList.indexOf(ActivityInternalStatus[activity.InternalStatus]) !== -1
    ) {
      ret = true;
    }

    return ret;
  }

  private _getActivitiesPartialResult(
    activitiesList: Partial<Activity>[],
    wbsList: Partial<WBS>[],
    wbsCategoryList: Partial<WBSCategory>[],
    flattenActivityCodeList: Partial<FlattenActivityCode>[],
    Priority: any,
    query?: string,
    shiftFilter?: string
  ): IWbsResult[] {
    console.log('_getActivitiesPartialResult');
    return activitiesList?.reduce((acc: Array<IWbsResult>, a) => {
      if (acc.every((el) => el.Id !== a.WbsId)) {
        const w = wbsList.find((wbs) => wbs.Id === a.WbsId);
        const wc = wbsCategoryList.find((wbs) => wbs.Id === w.WBSCategoryId);
        const fac = flattenActivityCodeList.find(
          (flattenActivityCode) => flattenActivityCode.ActivityId === a.Id
        );
        let queryFilterCondition = true;
        if (query)
          queryFilterCondition =
            a.ActivityId.indexOf(query) > -1 ||
            a.Name.indexOf(query) > -1 ||
            w.Name.indexOf(query) > -1 ||
            fac?.Ewo?.indexOf(query) > -1;
        let shiftCondition = true;
        if (shiftFilter) {
          switch (shiftFilter) {
            case Filter_Shift.Shift_12:
              shiftCondition = moment(a.StartDate).isSameOrBefore(
                moment(a.DataDate).add(0.5, 'd')
              );
              break;
            case Filter_Shift.Shift_24:
              shiftCondition = moment(a.StartDate).isSameOrBefore(
                moment(a.DataDate).add(1, 'd')
              );
              break;
            case Filter_Shift.Shift_48:
              shiftCondition = moment(a.StartDate).isSameOrBefore(
                moment(a.DataDate).add(2, 'd')
              );
              break;
          }
        }

        if (w && wc && fac && queryFilterCondition && shiftCondition)
          acc.push({
            Id: a.WbsId,
            ProjectId: a.ProjectId,
            Name: w.Name,
            GlobalOrder: w.GlobalOrder,
            wbsCategoryName: wc.Name,
            wbsCategoryId: wc.Id,
            orderNumber: fac?.Ewo,
            Priority,
          });
      }
      return acc;
    }, []);
  }

  private _formatFinalActivitiesResult(
    partialResult: IWbsResult[],
    wbsList: Partial<WBS>[],
    wbsCategoryList: Partial<WBSCategory>[],
    activitiesList: Partial<Activity>[],
    flattenActivityCodeList: Partial<FlattenActivityCode>[],
    crew: any,
    role: RoleType,
    shiftFilter: string,
    activityStatus: ActivityInternalStatus[]
  ) {
    const finalResponse = partialResult.map((item) => {
      const w = wbsList.find((wbs) => wbs.Id === item.Id);
      const wc = wbsCategoryList.find((wbs) => wbs.Id === w.WBSCategoryId);
      const activitiesFiltered = activitiesList.filter((a) => {
        const fac = flattenActivityCodeList.find(
          (fac) => fac.ActivityId === a.Id
        );
        return a.WbsId === item.Id && fac;
      });

      item.filteredActivities = this._getFilteredActivities(
        activitiesFiltered,
        activityStatus,
        shiftFilter
      );
      item.filteredActivityCount = item.filteredActivities.length || 0;
      if (crew) {
        if (role === RoleType.CR) {
          item.HasNoContractors = crew.HasNoContractors;
        }
        item.IsReadOnly = crew.IsReadOnly;
      }
      return item;
    });
    return finalResponse.sort((a, b) => a.GlobalOrder - b.GlobalOrder);
  }

  private _getHelpers(activityStatus): {
    noUpdateIndex: number;
    tempList: any[];
  } {
    let helpers: {
      noUpdateIndex: number;
      tempList: any[];
    } = null;
    if (
      activityStatus &&
      activityStatus?.length !== 0 &&
      activityStatus?.length !== 5
    ) {
      helpers = activityStatus.reduce(
        (acc, activity, index) => {
          if (activity === ActivityInternalStatus.NoUpdate) {
            acc.noUpdateIndex = index;
            return acc;
          }
          acc.tempList.push(activity);
          return acc;
        },
        { noUpdateIndex: -1, tempList: [] }
      );
    }
    return helpers;
  }

  private async _getActivityListForWBSCategoriesUser(
    projectId: number,
    helpers: any
  ): Promise<any> {
    const activitiesList = await db.activity
      .where({
        ProjectId: projectId,
        Type: 'TaskDependent',
      })
      .and((a) => {
        const baseCondition =
          a.Status !== 'Completed' &&
          a.PercentComplete !== 1 &&
          a.IsDeleted === false;
        if (helpers !== null) {
          let isInternalStatus = false;
          if (helpers.tempList.length > 0) {
            isInternalStatus = helpers.tempList
              .map((a) => `'` + a + `'`)
              .includes(a.InternalStatus);
          }
          if (helpers.noUpdateIndex >= 0) {
            if (helpers.tempList.length > 0) {
              return (
                baseCondition && (isInternalStatus || a.InternalStatus === null)
              );
            } else {
              return baseCondition && a.InternalStatus === null;
            }
          }
        }
        return baseCondition;
      })
      .toArray();
    return activitiesList;
  }
}
