import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { ResourceLite } from 'src/data/InternalTypes';

@Component({
  selector: 'app-resource-cell-tablet',
  styleUrls: ['./resource-cell.component-tablet.scss'],
  templateUrl: 'resource-cell.component-tablet.html'
})
export class ResourceCellTabletComponent extends MTAContainerComponent {
  @Input() resource: ResourceLite;
  showResources: boolean;
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
