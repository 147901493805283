import { Component, Injector, Input } from '@angular/core';
import { WBS } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
  selector: 'app-wbs-tablet',
  styleUrls: ['./wbs.component-tablet.scss'],
  templateUrl: 'wbs.component-tablet.html'
})
export class WBSTabletComponent extends MTAContainerComponent {
  
	@Input()
	mtaWbs: WBS;
	@Input()
	isCompanyRep: boolean;
	@Input()
	isChevronUser: boolean;
	@Input()
	isExpanded: boolean;
	@Input()
	iconExpand: string;
	@Input()
	crewsIdStr: string;
	@Input()
	isCompletedAllBtnDisabled: boolean;
	@Input()
	isApproveAllToggleDisabled: boolean;
	@Input()
	getWBSHierarchyNames: string;
	@Input()
	updateActivity: (event) => void;
	@Input()
	updatePercent: (event) => void;
	@Input()
	confirmApproveAll: () => void;
	@Input()
	approveActivityIOS: (activity, item) => void;
	@Input()
	rejectActivityIOS: (activity, item) => void;
	@Input()
	completeActivityIOS: (activity, item) => void;
	@Input()
	cannotWork: (activity, item) => void;
	@Input()
	undoStatusIOS: (activity, item) => void;
  
  constructor(public injector: Injector) {
    super(injector);
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'chevron-up-outline' : 'chevron-down-outline';
    this.something.emit({ operation: 'toggle', params: null });
  }

  confirmCompleteAll() {
    this.something.emit({ operation: 'confirmCompleteAll', params: null });
  }
  viewToNotesOptions(hierarchyId: number, event?: any) {
    this.something.emit({ operation: 'viewToNotesOptions', params: hierarchyId });
    if (event) {
      event.stopPropagation();
    }
  }
}
