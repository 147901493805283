import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment'; // breakin fix
import { LoginProvider } from 'src/shared/login';

declare let PushNotification;

/*
  Generated class for the PushNotificationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class PushNotificationProvider {
  static serviceURLForRegister = '/NotificationRegistries';
  static serviceURLForUnregister = '/NotificationRegistries';
  private msgPlatform = '';
  private androidConfig = {
    sound: true,
    senderID: environment.FCMSenderId,
    icon: 'ic_push'
  };
  private iosConfig = {
    alert: 'true',
    badge: true,
    sound: 'true'
  };

  constructor(
    public http: HttpClient,
    private platform: Platform,
    private loginProvider: LoginProvider,
    private toastCtrl: ToastController,
    ) {
    if (this.platform.is('ios')) {
      this.msgPlatform = 'apns';
    } else if (this.platform.is('android')) {
      this.msgPlatform = 'fcm';
    } else {
      this.msgPlatform = '';
    }
  }

  async registerInNotificationHub(): Promise<void> {
   // return new Promise<void>(async (resolve, reject) => {
      const isRegisterNeeded = await this.isResisterNeeded();
      if (isRegisterNeeded) {
        this.loginProvider.login().then(
          async usr => {
            const push = PushNotification.init({
              notificationHubPath: environment.PushNotificationHubPath,
              connectionString: environment.PushNonnectionString,
              android: this.androidConfig,
              ios: this.iosConfig
            });
            push.on('registration', async (data: any) => {
              localStorage.setItem('PushEnabled', 'true');
              localStorage.setItem('PNSHandler', data.registrationId);
              await this.registerInAzure(data.registrationId);
            });
            push.on('notification', (data: any) => {
              this.showMsg(data.title, data.message);
            });
            push.on('error', err => {
              this.showMsg('Error', JSON.stringify(err));
            });
            return;
          }
        );
      } else {
        return;
      }
   // });
  }

  hasPermission(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.platform.ready().then(() => {
          resolve(false);
      });
    });
  }

  async registerInAzure(PNSHandler: string): Promise<void> {
   // return new Promise<void>(async (resolve, reject) => {
    try {
      const options = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.loginProvider.getToken()).set('Content-Type', 'application/json')
      };
      let obs: Observable<any>;
      const body = {
        Handle: PNSHandler,
        Platform: this.msgPlatform
      };

      obs = this.http.post(environment.BaseAPI + PushNotificationProvider.serviceURLForRegister, body, options);
      const res = await obs.pipe(take(1)).toPromise();
      return res;
    }
    catch (err) {
      throw (err);
    }
   // });
  }

  unregisterInAzure() {
   // return new Promise<void>((resolve, reject) => {
    let obs: Observable<any>;
    try {
      const ures = obs.pipe(take(1)).toPromise();
      return ures;
    }
    catch (ex) {
      throw (ex);
    }
  //  });
  }

  async isResisterNeeded(): Promise<boolean> {
    return  Promise.resolve(false);
  }

  async showMsg(title: string, msg: string) {
    if (title === null || title === undefined) {
      title = 'Notification';
    }
    const toast = await this.toastCtrl.create({ message: `${title} - ${msg}`, duration: 3000, position: 'bottom' });

    toast.present();
  }
}
