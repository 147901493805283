import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { Project } from 'src/data/EntityIndex';
import { Filters } from 'src/data/Filters';

@Component({
	selector: 'app-home-page-tablet',
	styleUrls: ['./../home.page.scss', 'home.page-tablet.scss'],
	templateUrl: 'home.page-tablet.html',
})
export class HomePageTabletComponent extends MTAContainerComponent {
  @Input()
  crewFilter = Filters;

	@Input()
	welcomeMsg: string;
	@Input()
	isUpdateAvailable: boolean;
	@Input()
	offlineProjects: Project[];

	@Input()
	hasOfflineData: boolean;

	@Input()
	checkIfLookAheadBtnDisable: () => boolean;
	@Input()
	wasUserLoggedin: () => boolean;
	@Input()
	userIsExternal: () => boolean;

	@Input()
	sync: () => void;

	@Input()
	openSlides: () => void;

	@Input()
	setWelcomeMessage: () => void;

	@Input()
	logout: () => void;

	@Input()
	openJobPage: (type: any) => void;
	@Input()
	showFeedback: (event: any) => void;

	constructor(public injector: Injector) {
		super(injector);
	}
}
