import { Component, Injector, Input, OnInit } from '@angular/core';
import { Activity } from 'src/data/EntityIndex';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ActivityDetailPhoneComponent } from './phone/activity-detail.component-phone';
import { ActivityDetailTabletComponent } from './tablet/activity-detail.component-tablet';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss'],
})
export class ActivityDetailComponent extends MTAContainerComponent  implements OnInit {

  @Input() activity: Activity;

  constructor(injector: Injector) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: ActivityDetailTabletComponent, phone: ActivityDetailPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      activity: this.activity,
    };
    this.outputs = {};
  }

}
