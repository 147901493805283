import { Pipe, PipeTransform } from '@angular/core';
import { ActivityInternalStatus } from 'src/data/ActivityInternalStatus';

/**
 * Generated class for the StatusPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'StatusPipe'
})
export class StatusPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    if (value === ActivityInternalStatus.CantWork) {
      value = `Can't Work`;
    }
    return value;
  }
}
