import { Component, Injector, Input } from '@angular/core';
import { ActivityInternalStatus } from '../../../data/ActivityInternalStatus';
import { Activity } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { MTAUtility } from '../../../shared/utility';

/**
 * Generated class for the ActivityActionsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-activity-actions-tablet',
  styleUrls: ['./activity-actions.component-tablet.scss'],
  templateUrl: 'activity-actions.component-tablet.html'
})
export class ActivityActionsTabletComponent extends MTAContainerComponent {
  @Input()
  status: ActivityInternalStatus;
  @Input()
  isCompanyRep: boolean;
  @Input()
  activity: Activity;

  get isDisabled() {
    if (!this.activity.isReadOnly && !this.activity.HasNoContractors) {
      if (this.activity.InternalPercentComplete === MTAUtility.getRoundedMinValue(this.activity.PercentComplete)) {
        return true;
      } else { return false; }
    } else { return true; }
  }

  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

  viewActivity() {
    this.something.emit({ operation: 'viewActivity', params: null });
  }

  changeStatus(status) {
    this.something.emit({ operation: 'changeStatus', params: status });
  }

  cantWork() {
    this.something.emit({ operation: 'cantWork', params: null });
  }

  undoStatus(activity: Activity) {
    if (activity.InternalPercentComplete === MTAUtility.getRoundedMinValue(activity.PercentComplete)) {
      this.something.emit({ operation: 'changeStatus', params: null });
    } else {
      this.something.emit({ operation: 'changeStatus', params: ActivityInternalStatus.Updated });
    }
  }

  navigateToTONotes(hierarchyId: number) {
    this.something.emit({ operation: 'navigateToTONotes', params: hierarchyId });
  }
}
