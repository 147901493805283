import { ActivityStartJustification } from './ActivityStartJustification';
import { Justification } from './Justification';
import { MTABaseEntity } from './MTABaseEntity';

export class ActivityPSRSnapshot extends MTABaseEntity {
  Id: string;
  ShiftNumber: number;
  ProjectId: number;
  WbsId: number;
  ActivityId: number;
  P6PercentOrigin: number;
  P6PercentNew: number;
  InternalPercentOrigin: number;
  InternalPercentNew: number;
  InternalStatusOrigin: string;
  InternalStatusNew: string;
  UpdatedDate: string;
  UpdatedBy: string;
  CreatedDate: string;
  CreatedBy: string;
  // helper properties
  status: string;  
  statusImg: string;
  UDFTypeTitle: string;
  Text: string;
  Name?: string;
  StartDate?: string;
  ActivityIdText: number;
  lastCommentInShift: Justification;
  lastJustificationInShift: ActivityStartJustification;

  init(json: any): void {
    this.Id = json.Id;
    this.ProjectId = json.ProjectId;
    this.ActivityId = json.ActivityId;
    this.WbsId = json.WbsId;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.ShiftNumber = json.ShiftNumber;
    this.P6PercentOrigin = json.P6PercentOrigin;
    this.P6PercentNew = json.P6PercentNew;
    this.InternalPercentOrigin = json.InternalPercentOrigin;
    this.InternalPercentNew = json.InternalPercentNew;
    this.InternalStatusOrigin = json.InternalStatusOrigin;
    this.InternalStatusNew = json.InternalStatusNew;
    this.CreatedDate = json.CreatedDate;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.CreatedBy = json.CreatedBy;
  }
}
