export enum CommentType {
  ProjectedEndDate = 'PE',
  ActivityComment = 'AC',
  RejectComment = 'CR',
  SchedulerReject = 'SR',
  SchedulerOverride = 'SO',
}

export enum CrewType {
  Crew1 = 'Crew-1',
  Crew2 = 'Crew-2',
  CoReps = 'Co-Reps',
}

export enum RoleType {
  CF = 'CF',
  CR = 'CR',
  SC = 'SC',
}

export enum MTAErrorType {
  COMMON_ERROR = 1,
  MULTIPLE_PROJECTS_ASSIGNED,
  NO_PROJECT_ASSIGNED,
  MULTIPLE_ROLES_ASSIGNED,
  NO_ROLE_ASSIGNED,
  AUTHENTICATION_ERROR,
  NOT_AUTHORIZED_USER,
  DATABASE_ERROR,
  NETWORK_ERROR,
  SERVICE_ERROR,
  COMMON_SYNC_ERROR,
  APP_VERSION_UNSUPPORTED,
  LOOK_AHEAD_ERROR
}

export enum CommentTypeDescription {
  ProjectedEndDate = 'Projected Start-Date Comment',
  ActivityComment = 'General Activity Comment',
  RejectComment = 'Company Rep Reject Comment',
  SchedulerReject = 'Scheduler Reject Comment',
  SchedulerOverride = 'Scheduler Override Comment',
}

export enum MTAPlatform {
  iOS = 'iOS',
  windows = 'windows',
  android = 'Android',
  js = 'js',
  core = 'core',
}

export enum JAScreenSortOrder {
  Wbs = 'GlobalOrder',
  Priority = 'Priority',
}

export class ResourceLite {
  public Name: string;
  public ResourceType: string;
}

export enum UserType {
  chevron = 'chevron',
  nonchevron = 'nonchevron',
}
export enum MobileVersionStatus {
  current = 'Current',
  compatible = 'Compatible',
  notSupported = 'Not Supported',
}
export class MobileVersion {
  public Version: string;
  public Status: MobileVersionStatus;
}

export enum LogLevel {
  Trace = 'Trace',
  Info = 'Information',
  Warn = 'Warnings',
  Error = 'Error',
}
