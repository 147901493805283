import { MTABaseEntity } from './MTABaseEntity';

export class Project extends MTABaseEntity {
  Id: number;
  ProjectId: string;
  GUID: string;
  Name: string;
  Description: string;
  DataDate: string;
  CreateDate: string;
  SummaryDurationPercentComplete: number;
  PlannedStartDate: string;
  StartDate: string;
  ScheduledFinishDate: string;
  MustFinishByDate: string;
  FinishDate: string;
  Status: string;
  RiskLevel: number;
  ActivityIdPrefix: string;
  ActivityIdSuffix: string;
  ActivityIdIncrement: number;
  ObsId: number;
  ParentEpsId: number;
  InstanceId: number;
  LocationName: string;
  ActDefaultPercentCompleteType: string;
  WbsCodeSeparator: string;
  WbsId: number;
  CreatedDate: string;
  CreatedBy: string;
  ProjectedCompleteDate: string;
  UpdatedDate: string;
  UpdatedBy: string;
  init(json: any): void {
    this.ActDefaultPercentCompleteType = json.ActDefaultPercentCompleteType;
    this.ActivityIdIncrement = json.ActivityIdIncrement;
    this.ActivityIdPrefix = json.ActivityIdPrefix;
    this.ActivityIdSuffix = json.ActivityIdSuffix;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.CreatedDate = json.CreatedDate;
    this.DataDate = json.DataDate;
    this.Description = json.Description;
    this.FinishDate = json.FinishDate;
    this.GUID = json.GUID;
    this.Id = json.Id;
    this.LocationName = json.LocationName;
    this.MustFinishByDate = json.MustFinishByDate;
    this.Name = json.Name;
    this.ObsId = json.ObsId;
    this.ParentEpsId = json.ParentEpsId;
    this.PlannedStartDate = json.PlannedStartDate;
    this.ProjectedCompleteDate = json.ProjectedCompleteDate;
    this.ProjectId = json.ProjectId;
    this.RiskLevel = json.RiskLevel;
    this.ScheduledFinishDate = json.ScheduledFinishDate;
    this.StartDate = json.StartDate;
    this.Status = json.Status;
    this.SummaryDurationPercentComplete = json.SummaryDurationPercentComplete;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.WbsCodeSeparator = json.WbsCodeSeparator;
    this.WbsId = json.WbsId;
    this.InstanceId = json.InstanceId;
  }
}
