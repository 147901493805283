import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-dependency-list-phone',
	templateUrl: 'dependency-list.component-phone.html',
	styleUrls: ['./dependency-list.component-phone.scss'],
})
export class DependencyListPhoneComponent extends MTAContainerComponent {
	@Input()
	title: string;
	@Input()
	activities: any[];

	showActivities = false;
	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}

	toggleActivities() {
		this.showActivities = !this.showActivities;
	}
}
