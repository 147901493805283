import { User } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

export class UserEntityManager extends BaseEntityManager {
  entityType = User;
	entityTableName = 'user';

  async getUserById(uid: number): Promise<User> {
   // return new Promise<User>(async (resolve, reject) => {
      try {
      const users = await db.user.where('Id').equals(uid).toArray();
        return(users[0] as User);
      } catch (e) {
        throw(e);
      }
   // });
  }
}
