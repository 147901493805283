import { MTABaseEntity } from './MTABaseEntity';

export class ActivityStartJustificationType extends MTABaseEntity {
  Id: number;
  Enabled: boolean;
  Text: string;
  CreateDate: string;
  CreatedById: number;
  UpdatedById: number;
  UpdatedDate: string;
  init(json: any): void {
    this.Enabled = json.Enabled;    
    this.Text = json.Text;
    this.CreateDate = json.CreateDate;
    this.CreatedById = json.CreatedById;
    this.Id = json.Id;
    this.UpdatedById = json.UpdatedById;
    this.UpdatedDate = json.UpdatedDate;
  }
}
