// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-checkbox {
  --border-style: none !important;
  --border-radius: 0 !important;
  --checkbox-background: none;
  --border-style: none;
  --border-color: none;
  --checkbox-background-checked: transparent;
  --checkmark-color: #751669;
}
ion-checkbox .checkbox-inner {
  width: 8px;
  height: 18px;
  --border-color: var(--ion-color-primary);
}

.selected {
  color: #751669;
}

.item:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/activity-filter/activity-filter.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,6BAAA;EACA,2BAAA;EACA,oBAAA;EACA,oBAAA;EACA,0CAAA;EACA,0BAAA;AACJ;AACI;EACE,UAAA;EACA,YAAA;EACA,wCAAA;AACN;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,eAAA;AADF","sourcesContent":["ion-checkbox {\n    --border-style: none !important;\n    --border-radius: 0 !important;\n    --checkbox-background: none;\n    --border-style: none;\n    --border-color: none;\n    --checkbox-background-checked: transparent;\n    --checkmark-color: #751669;\n\n    & .checkbox-inner {\n      width: 8px;\n      height: 18px;\n      --border-color:  var(--ion-color-primary);\n    }\n\n}\n\n.selected {\n  color:#751669;\n}\n\n.item:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
