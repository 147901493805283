import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { SharedPlatformProvider } from './shared/platform';

@Directive()
export class MTAContainerComponent {
  @Output() something = new EventEmitter<any>();
  public subComponentJson: { tablet: any; phone: any };
  public subComponent: typeof MTAContainerComponent;
  public inputs: any;
  public outputs: any;
  public platform: SharedPlatformProvider;

  constructor(public injector: Injector) {
    this.platform = this.injector.get(SharedPlatformProvider);
  }

  public getComponentType(): typeof MTAContainerComponent {
    const isMobile = this.platform.isMobile();
    if (isMobile) {
      return this.subComponentJson.phone;     
    } else {
      return this.subComponentJson.tablet;
    }
  }
}
