import { MTABaseEntity } from './MTABaseEntity';

export class ActivityStartJustification extends MTABaseEntity {
  Id: string;
  ActivityId: number;
  NewDate: string;
  Text: string;
  TypeId: number;
  CreateDate: string;
  CreatedById: number;
  UpdatedById: number;
  UpdatedDate: string;  
  /**
   * helpers
   */
  userFirstname: string;
  userLastname: string;

  public init(json: any): void {
    this.ActivityId = json.ActivityId;
    this.NewDate = json.NewDate;
    this.Text = json.Text;
    this.TypeId = json.TypeId;
    this.CreateDate = json.CreateDate;
    this.CreatedById = json.CreatedById;
    this.Id = json.Id;
    this.UpdatedById = json.UpdatedById;
    this.UpdatedDate = json.UpdatedDate;
  }
}
