import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ActivityStartJustification } from 'src/data/EntityIndex';

@Component({
	selector: 'app-justification-cell-tablet',
	templateUrl: 'justification-cell.component-tablet.html',
	styleUrls: ['./justification-cell.component-tablet.scss'],
})
export class JustificationCellTabletComponent extends MTAContainerComponent {
	@Input()
	justification: ActivityStartJustification;
	@Input()
	shiftName: string;
	@Input()
	shiftIcon: string;
	@Input()
	getUserInitial: () => string;
	@Input()
	getUserFullname: () => string;
	@Input()
	getJustificationTypeName: (value) => string;

	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}
}
