export class Constants {
  public static AppName = 'TA Pro';
  // Analytics Events
  public static SyncProgress = 'sync-progress';
  public static Database = 'db-logs';
  public static UnHandled = 'unhandled';
  public static Login = 'login';
  public static uploadError = 'upload-error';
  public static httpError = 'http-error';
  public static SyncError = 'sync-error';
  public static generalError = 'general-error';

  // SecureStorage
  public static SecureStorageKey = 'fwemta';

  // DB Constants
  public static DBName = 'fwemta.db';
  public static DBVersion = '1.0';
  public static BulkSize = 10000;

  // Work Orders per page
  public static workOrderPagingNumber = 10;

  public static CvxTawValues = class {
    public static CodeTypeName = 'CVX TAW';
    public static ExcludedCodeValues = ['Conting', 'Delete', 'CRNN', 'CRTAW'];
  };
  public static pp = 'mvLBiZsiTbGwrfJB';

  // Locale
  public static localeKey = 'locale';
  public static localeUS = 'en-US';
  public static timezoneOffset = 'Z';
}
