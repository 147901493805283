import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable, IUploadable } from 'src/services/sync/SyncInterface';
import { ActivityDTO } from 'src/data/dto/ActivityDTO';
import { ActivityEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class ActivityServiceProvider extends BaseServiceProvider implements IDownloadable, IUploadable {
  public _entityManger = ActivityEntityManager;

  public serviceName = 'activity';
  public uploadOperationName = 'update';

  public currentVersionForDownload = '1.0';

  public currentVersionForUpload = '2.0';
  public activitiesForUpload: any[];

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${config.project}`;
    } else {
      return null;
    }
  }

  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }

  public async upload(config: SyncConfig): Promise<any> {
      try {
        const em = new this._entityManger();
        this.activitiesForUpload = await em.getDeltaData(config);
        if (this.activitiesForUpload && this.activitiesForUpload.length > 0) {
          return super.upload_base(config)
        //  resolve(super.upload_base(config));
        } else {
          return null;
        //  resolve(null);
        }
      } catch (e) {
        return e;
      //  reject(e);
      }
  //  });
  }

  public getBodyForDownload(config: SyncConfig): Promise<object> {
    return Promise.resolve({
      projectId: config.project,
      lastUpdateDate: config.lastDownloadTime,
    });
  }

  getBodyForUpload(config: SyncConfig): any {
    const activities: ActivityDTO[] = [];
    if (this.activitiesForUpload && this.activitiesForUpload.length > 0) {
      this.activitiesForUpload.forEach((activity) => {
        activities.push({
          Id: activity.Id,
          InternalPercentComplete: activity.InternalPercentComplete,
          InternalStatus: activity.InternalStatus,
          UpdatedDate: activity.UpdatedDate,
          ActualStartDate: activity.ActualStartDate,
          ActualFinishDate: activity.ActualFinishDate,
          ActualsManuallyUpdated: activity.ActualsManuallyUpdated,
          OutOfSequence: activity.OutOfSequence
        });
      });
    }
    const body = { SyncId: config.syncId, Activities: activities };
    return body;
  }
}
