import { Component, Injector, Input } from '@angular/core';
import { Activity } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-activity-cell-tablet',
	styleUrls: ['./activity-cell.component-tablet.scss'],
	templateUrl: 'activity-cell.component-tablet.html',
})
export class ActivityCellTabletComponent extends MTAContainerComponent {
	@Input()
	activity: Activity;
	@Input()
	mainCrewId: string;
	@Input()
	supportCrewId: string;
	@Input()
	crewsIdStr: string;
	@Input()
	isCompanyRep: boolean;
	@Input()
	getActivityStatusImg: () => string;
	@Input()
	getClassByStatus: () => string;
	@Input()
	isTranslatedActivity: boolean;

	constructor(public injector: Injector) {
		super(injector);
	}

	changePercentage(value) {
		this.something.emit({ operation: 'changePercentage', params: value });
	}

	viewActivity() {
		this.something.emit({ operation: 'viewActivity', params: null });
	}

	changeStatus(status) {
		this.something.emit({ operation: 'changeStatus', params: status });
	}

	cantWork(activity) {
		this.something.emit({ operation: 'cantWork', params: activity });
	}

	navigateTONotes(activity) {
		this.something.emit({ operation: 'navigateTONotes', params: activity.Id });
	}

	updateStartFinishDate(activity) {
		this.something.emit({
			operation: 'updateStartFinishDate',
			params: activity,
		});
	}
}
