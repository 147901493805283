import { CrewAssignment } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

export class CrewAssignmentEntityManager extends BaseEntityManager {
	entityType = CrewAssignment;
	entityTableName = 'crewAssignment';

	async getCrewAssignment(uid: number, projectId: number): Promise<CrewAssignment[]> {
	//	return new Promise<CrewAssignment[]>(async (resolve, reject) => {
			try {
				const crewAssignments = await db.crewAssignment
					.where({
						ProjectId: projectId,
						UserId: uid,
					})
					.and((ca) => ca.IsDeleted === false)
					.toArray();

				const crews = await db.crew
					.where('CrewId')
					.anyOf(crewAssignments.map((ca) => ca.CrewId))
					.and((c) => c.ProjectId == projectId && c.IsDeleted === false)
					.toArray();

				const crewAssignmentsResult = crewAssignments.reduce(
					(result: any[], ca) => {
						const crew = crews.find((c) => c.CrewId == ca.CrewId);
						if (crew) {
							result.push({
								CrewId: ca.CrewId,
								Type: crew.Type,
								ProjectId: crew.ProjectId,
								IsReadOnly: ca.IsReadOnly,
							});
						}
						return result;
					},
					[]
				);
				if (crewAssignmentsResult) {
					return(crewAssignmentsResult as CrewAssignment[]);
				} else {
					return(null);
				}
			} catch (e) {
				throw(e);
			}
		//});
	}
}
