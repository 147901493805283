import { Project } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';
import { Injectable } from "@angular/core";

@Injectable()
export class ProjectEntityManager extends BaseEntityManager {
	entityType = Project;
	entityTableName = 'project';

  async deleteProjectsNotAssignedWithQueryRunner(
		assignedProjects: Project[]
	): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
			try {
				const updatedTime = new Date().toISOString();
				await db.project
					.where('Id')
					.noneOf(assignedProjects.map((project) => project.Id))
					.and((p) => p.Status !== 'Inactive')
					.modify({
						Status: 'Inactive',
						UpdatedDate: updatedTime,
					});
				return;
			} catch (e) {
				throw(e);
			}
		//});
	}

	async getActiveProjects(): Promise<Project[]> {
	//	return new Promise<Project[]>(async (resolve, reject) => {
			try {
				const projects = await db.project
					.where('Status')
					.equals('Active')
					.toArray();
				if (projects) {
					return(projects as Project[]);
				} else {
					return(null);
				}
			} catch (e) {
				throw(e);
			}
	//	});
	}

	async insertProjectData(projects: Project[]) {
		await this.insertData(projects);
	}

	async insertorUpdateProjectData(projects: Project[]) {
		await this.insertOrUpdateData(projects);
	}

	async getProjectsByIds(projectIds: Set<number>): Promise<Project[]> {
    if (!projectIds?.size) {
      return null;
    }

    const projects = await db.project
      .where('Id')
      .anyOf(Array.from(projectIds))
      .toArray();

    if (projects) {
      return projects as Project[];
    }

    return null;
	}
}
