// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-family: "Gotham-Bold";
  font-size: 4em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #751669;
}

.logo {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/brand/tablet/brand.component-tablet.scss","webpack://./colors.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cCXe;ADUjB;;AAIA;EACE,aAAA;EACA,uBAAA;AADF","sourcesContent":["@import './../../../../colors.scss';\n\nh1 {\n  font-family: 'Gotham-Bold';\n  font-size: 4em;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: $darkish-purple;\n}\n\n.logo {\n  display: flex;\n  justify-content: center;\n}\n","$darkish-purple: #751669;\n$white-two: #e5e5e5;\n$greyish-brown: #4a4a4a;\n$white-three: #ebebeb;\n$sun-yellow: #f8e71c;\n$pinkish-grey: #cbcbcb;\n$white-four: #e2e2e2;\n$white-five: #d8d8d8;\n$greyish-brown-two: #575757;\n$warm-grey: #979797;\n$white-six: #f4f4f4;\n$black-33: rgba(0, 0, 0, 0.33);\n$white-seven: #eeeeee;\n$scarlet: #d0021b;\n$warm-purple: #ba3093;\n$apple-green: #7ed321;\n$pale-grey: #dad8da;\n$eggplant: #3a0d36;\n$slate-grey: #6b6d6f;\n$white-eight: #ececec;\n$avocado-green: #65a420;\n$tomato: #e5601f;\n$greyish: #ababab;\n$brownish-grey: #666666;\n$white: white;\n$black: black;\n$greyish-two: #a9a9a9;\n$white-nine: #ededed;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
