import { Injectable } from '@angular/core';
import { BaseServiceProvider } from 'src/data/entity-service/base-service';
import { SyncConfig } from './SyncConfig';
import { IDownloadable } from './SyncInterface';

@Injectable()
export class SyncQueryService extends BaseServiceProvider implements IDownloadable {
  public _entityManger = null;

  public serviceName = 'sync';

  public downloadOperationName = 'entities';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${this.downloadOperationName}`;
    } else {
      return null;
    }
  }

  public getOptions(config?: SyncConfig): { headers: any; params: any } {
    const opt = super.getOptions(config);
    if (config) {
      opt.params = opt.params.set('projectId', config.project + '');
    }

    return opt;
  }

  public download(config: SyncConfig): Promise<any> {
    return super.downloadWithOutSave(config);
  }

  public getBodyForDownload(config: SyncConfig): Promise<object> {
    console.log('config', config)
    return Promise.resolve({
      lastUpdateDate: config.lastDownloadTime,
      version: this.currentVersionForDownload,
      projectId: config.project,
    });
  }
}
