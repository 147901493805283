import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable, IUploadable } from 'src/services/sync/SyncInterface';
import { JustificationEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';
import { Justification } from '../EntityIndex';

@Injectable()
export class JustificationServiceProvider extends BaseServiceProvider implements IDownloadable, IUploadable {
  public _entityManger = JustificationEntityManager;
  public serviceName = 'justification';
  public downloadOperationName = 'project';
  public currentVersionForDownload = '1.0';
  public currentVersionForUpload = '2.0';
  public justificationsForUpload: Justification[];

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${this.downloadOperationName}/${config.project}`;
    } else {
      return null;
    }
  }

  public getUploadUrl(): string {
    if (this.isUploadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }

  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }

  public async upload(config: SyncConfig): Promise<any> {
   // return new Promise<any>(async (resolve, reject) => {
      try {
        const em = new this._entityManger();
        this.justificationsForUpload = await em.getDeltaData(config);
        if (this.justificationsForUpload && this.justificationsForUpload.length > 0) {
          return(super.upload_base(config));
        } else {
          return(null);
        }
      } catch (e) {
        return(e);
      }
  //  });
  }

  getBodyForUpload(config: SyncConfig): any {
      let justifications = [];
      if (this.justificationsForUpload && this.justificationsForUpload.length > 0) {
        justifications = this.justificationsForUpload;
      }
      const body = { SyncId: config.syncId, Justifications: justifications };
      return body;
  }
}
