import { Component, Injector, Input, OnInit} from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { WorkOrder } from '../../data/WorkOrder';
import { DependencyListPhoneComponent } from './phone/dependency-list.component-phone';
import { DependencyListTabletComponent } from './tablet/dependency-list.component-tablet';

@Component({
  selector: 'app-dependency-list',
  templateUrl: './dependency-list.component.html',
  styleUrls: ['./dependency-list.component.scss'],
})
export class DependencyListComponent extends MTAContainerComponent implements OnInit {
  @Input() workOrder: WorkOrder;
  @Input() resource: any;
  @Input() title: string;
  @Input() activities: any[];

  constructor(injector: Injector) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: DependencyListTabletComponent, phone: DependencyListPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      title: this.title,
      activities: this.activities
    };
    this.outputs = {};
  }

}
