import { Injectable } from '@angular/core';
import { MobileVersion } from '../../data/InternalTypes';
import { BaseServiceProvider } from '../entity-service/base-service';

@Injectable()
export class MobileVersionServiceProvider extends BaseServiceProvider {
  serviceName = 'MobileVersion';
  downloadOperationName = 'getCompatibilityByVersion';
  currentVersionForDownload = '1.0';

  getVersionCompability(version: string): Promise<MobileVersion> {
    return new Promise<MobileVersion>((resolve, reject) => {
      this.loginProvider
        .login()
        .then(_ => {
          const options = this.getOptions();
          options.params = options.params.set('versionNumber', version);
          this.get(this.getDownloadUrl(), options)
            .then(ver => {
              resolve(ver);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
