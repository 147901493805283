import { Component, Injector, OnInit } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { BrandPhoneComponent } from './phone/brand.component-phone';
import { BrandTabletComponent } from './tablet/brand.component-tablet';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent extends MTAContainerComponent implements OnInit {
  public appName: string;

  constructor(public injector: Injector) {
    super(injector);
  }
  public ngOnInit() {
    this.subComponentJson = {
      tablet: BrandTabletComponent,
      phone: BrandPhoneComponent,
    };
    this.subComponent = this.getComponentType();
    this.inputs = {};
    this.outputs = {};
  }
}
