import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { ActivityStartJustificationType } from 'src/data/EntityIndex';

@Component({
  selector: 'app-previous-justification-tablet',
  templateUrl: 'previous-justifications.component-tablet.html',
  styleUrls: ['./previous-justifications.component-tablet.scss']
})
export class PreviousJustificationsTabletComponent extends MTAContainerComponent {
  @Input()
  previousJustifications: Array<any>;
  @Input()
  justificationTypes: ActivityStartJustificationType[];
  
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
