import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SyncLog } from 'src/data/entities/SyncLog';
import {
	Activity,
	ActivityPSRSnapshot,
	ActivityStartJustification,
	ActivityStartJustificationType,
	Crew,
	CrewAssignment,
	FlattenActivityCode,
	Justification,
	MTABaseEntity,
	OBS,
	Project,
	ProjectConfig,
	Relationship,
	Resource,
	ResourceAssignment,
	Role,
	Shift,
	UDFCodeValue,
	User,
	WBS,
	WBSCategory,
} from 'src/data/EntityIndex';
import {
	ActivityEntityManager,
	ActivityPSRSnapshotEntityManager,
	ActivityStartJustificationEntityManager,
	ActivityStartJustificationTypeEntityManager,
	CrewAssignmentEntityManager,
	JustificationEntityManager,
	ProjectConfigEntityManager,
	ProjectEntityManager,
	RelationshipEntityManager,
	ResourceAssignmentEntityManager,
	ResourceEntityManager,
	ShiftEntityManager,
	SyncLogEntityManager,
	UDFCodeValueEntityManager,
	UserEntityManager,
	WBSEntityManager,
} from 'src/data/EntityManagerIndex';
import { CommentType } from 'src/data/InternalTypes';
import { db } from './indexDb.service';
import { IPredecessorsByActivityId } from 'src/shared/interfaces/predecessorsByActivityId.interface';
import { ISucessorsByActivityId } from 'src/shared/interfaces/successorsByActivityId.interface';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class DbService {
	// entity managers
	projectManager: ProjectEntityManager;
	activityManager: ActivityEntityManager;
	activityPSRSnapshotManager: ActivityPSRSnapshotEntityManager;
	shiftManager: ShiftEntityManager;
	wbsManager: WBSEntityManager;
	relationShipManager: RelationshipEntityManager;
	justificationManager: JustificationEntityManager;
	udfCodeValueManager: UDFCodeValueEntityManager;
	resourceEntityManager: ResourceEntityManager;
	resourceAssignmentEntityManager: ResourceAssignmentEntityManager;
	userManager: UserEntityManager;
	syncLogManager: SyncLogEntityManager;
	crewAssignmentManager: CrewAssignmentEntityManager;
	projectConfigManager: ProjectConfigEntityManager;
	activityStartJustificationManager: ActivityStartJustificationEntityManager;
	activityStartJustificationTypeManager: ActivityStartJustificationTypeEntityManager;
	private readonly entities: (typeof MTABaseEntity)[] = [
		Activity,
		ActivityPSRSnapshot,
		Shift,
		Crew,
		CrewAssignment,
		Justification,
		OBS,
		Project,
		Relationship,
		Resource,
		Role,
		SyncLog,
		User,
		WBS,
		WBSCategory,
		UDFCodeValue,
		Resource,
		ResourceAssignment,
		ProjectConfig,
		ActivityStartJustification,
		ActivityStartJustificationType,
		FlattenActivityCode,
	];

	constructor(public http: HttpClient, private platform: Platform) {
		this.projectManager = ProjectEntityManager.Instance as ProjectEntityManager;
		this.activityManager =
			ActivityEntityManager.Instance as ActivityEntityManager;
		this.wbsManager = WBSEntityManager.Instance as WBSEntityManager;
		this.relationShipManager =
			RelationshipEntityManager.Instance as RelationshipEntityManager;
		this.justificationManager =
			JustificationEntityManager.Instance as JustificationEntityManager;
		this.udfCodeValueManager =
			UDFCodeValueEntityManager.Instance as UDFCodeValueEntityManager;
		this.resourceEntityManager =
			ResourceEntityManager.Instance as ResourceEntityManager;
		this.resourceAssignmentEntityManager =
			ResourceAssignmentEntityManager.Instance as ResourceAssignmentEntityManager;
		this.userManager = UserEntityManager.Instance as UserEntityManager;
		this.syncLogManager = SyncLogEntityManager.Instance as SyncLogEntityManager;
		this.crewAssignmentManager =
			CrewAssignmentEntityManager.Instance as CrewAssignmentEntityManager;
		this.projectConfigManager =
			ProjectConfigEntityManager.Instance as ProjectConfigEntityManager;
		this.activityStartJustificationManager =
			ActivityStartJustificationEntityManager.Instance as ActivityStartJustificationEntityManager;
		this.activityStartJustificationTypeManager =
			ActivityStartJustificationTypeEntityManager.Instance as ActivityStartJustificationTypeEntityManager;
		this.activityPSRSnapshotManager =
			ActivityPSRSnapshotEntityManager.Instance as ActivityPSRSnapshotEntityManager;
		this.shiftManager = ShiftEntityManager.Instance as ShiftEntityManager;
	}

	async saveComment(comment: Justification) {
		await this.saveComments([comment]);
	}
	async saveComments(comments: Justification[]) {
		await this.justificationManager.insertorUpdateComments(comments);
	}

	async ready(): Promise<boolean> {
		try {
			localStorage.setItem('isDBExisted', 'true');
			return true;
		} catch (ex) {
			throw ex;
		}
	}

	async getCurrentShift(projectId: number): Promise<Shift | null> {
		return this.shiftManager.getCurrentShift(projectId);
	}

	getUserById(uid: number): Promise<User> {
		return this.userManager.getUserById(uid);
	}

	async reset(): Promise<void> {
		try {
			localStorage.setItem('isDBExisted', 'false');
			const tables = db.tables;
			for (let t of tables) {
				await t.clear();
			}
			return;
		} catch (ex) {
			throw ex;
		}
	}

	async getActiveProjects(): Promise<Project[]> {
		return this.projectManager.getActiveProjects();
	}

	async deleteProjectsNotAssignedWithQueryRunner(
		projectsAssigned: Project[]
	): Promise<void> {
		return this.projectManager.deleteProjectsNotAssignedWithQueryRunner(
			projectsAssigned
		);
	}

	async createSyncLogWithQueryRunner(
		projectId,
		lastUploadTime,
		lastDownloadTime
	): Promise<void> {
		try {
			const syncLog = new SyncLog();
			syncLog.LastUploadTime = lastUploadTime;
			syncLog.LastDownloadTime = lastDownloadTime;
			syncLog.UserId = localStorage.getItem('UserId');
			syncLog.CreatedDate = new Date().toISOString();
			syncLog.ProjectId = projectId;

			try {
				await this.syncLogManager.insertOrUpdateDataWithQueryRunner(syncLog);
			} catch (e) {}
			await db.syncLog.add(syncLog);
			return;
		} catch (e) {
			throw e;
		}
	}

	async getLastSyncLogForProjects(): Promise<SyncLog[]> {
    const syncLog = await db.syncLog.toArray();

    return syncLog.reduce(
      (acc, syncLogItem) => {
        const existedRecord = acc.find((a) => a.ProjectId === syncLogItem.ProjectId);

        if (existedRecord) {
          if (
            moment(existedRecord?.LastUploadTime).isBefore(
              moment(syncLogItem.LastUploadTime)
            )
          ) {
            existedRecord.LastUploadTime = syncLogItem.LastUploadTime;
          }

          if (
            moment(existedRecord?.LastDownloadTime).isBefore(
              moment(syncLogItem.LastDownloadTime)
            )
          ) {
            existedRecord.LastDownloadTime = syncLogItem.LastDownloadTime;
          }

          if (
            moment(existedRecord?.CreatedDate).isBefore(moment(syncLogItem.CreatedDate))
          ) {
            existedRecord.CreatedDate = syncLogItem.CreatedDate;
          }
        } else {
          acc.push({
            Id: syncLogItem?.Id,
            UserId: syncLogItem.UserId,
            ProjectId: syncLogItem.ProjectId,
            LastUploadTime: syncLogItem.LastUploadTime,
            LastDownloadTime: syncLogItem.LastDownloadTime,
            DeviceId: syncLogItem?.DeviceId,
            CreatedDate: syncLogItem.CreatedDate,
          });
        }

        return acc;
      },
      []
    );
	}

	async getLastSyncLog(projectId: number): Promise<SyncLog[]> {
		try {
			if (projectId) {
				const cai = localStorage.getItem('CAI');
				const uid = localStorage.getItem('UserId');
				const syncLog = (
					await db.syncLog
						.filter(
							(s) => (s.UserId === cai || s.UserId === uid) &&
								s.ProjectId === projectId
						)
						.sortBy('CreatedDate')
				).reverse();
				if (syncLog) {
					return syncLog as SyncLog[];
				} else {
					return null;
				}
			} else {
				return null;
			}
		} catch (e) {
			return e;
		}
	}

	async getShiftBySequence(
		projectId: number,
		sequence: number
	): Promise<Shift> {
		return this.shiftManager.getShiftBySequence(projectId, sequence);
	}

	async getSiteForProjectByObs(projectObsId: number): Promise<string | null> {
		try {
			const obsHierarchy: OBS[] = await this.getObsHierarchy(projectObsId);
			if (obsHierarchy && obsHierarchy.length > 2) {
				return obsHierarchy[obsHierarchy.length - 2].Name;
			} else if (obsHierarchy && obsHierarchy.length === 1) {
				return obsHierarchy[obsHierarchy.length - 1].Name;
			} else {
				return null;
			}
		} catch (error) {
			return error;
		}
	}

	async getObsHierarchy(projectObsId: number): Promise<OBS[]> {
		try {
			const hierarchy: OBS[] = [];
			const initialObs = await db.obs.where({ Id: projectObsId }).first();
			hierarchy.push(initialObs as OBS);
			while (
				hierarchy[hierarchy.length - 1].ParentId !== 0 &&
				hierarchy[hierarchy.length - 1].ParentId !== null
			) {
				const obsItem = await db.obs
					.where({ Id: hierarchy[hierarchy.length - 1].ParentId })
					.first();
				hierarchy.push(obsItem as OBS);
			}
			return hierarchy;
		} catch (error) {
			console.error('error in getObsHierarchy', error);
			throw error;
		}
	}

	async getJustificationStartDetailsForActivity(
		activity: Activity
	): Promise<ActivityStartJustification[] | null> {
		try {
			const activitystartjustificationList = (
				await db.activityStartJustification
					.where({ ActivityId: activity.Id })
					.sortBy('UpdatedDate')
			).reverse();
			if (activitystartjustificationList.length > 0) {
				return activitystartjustificationList as ActivityStartJustification[];
			} else {
				return null;
			}
		} catch (error) {
			console.error('error in activitystartjustification', error);
			throw error;
		}
	}

	async getCommentsByActivityIdWithCreator(activityId: number) {
		try {
			const justificationList = (
				await db.justification
					.where({ ActivityId: activityId })
					.sortBy('UpdatedDate')
			).reverse();
			const userList = await db.user
				.where('Id')
				.anyOf(justificationList.map((j) => j.CreatedUserId))
				.toArray();

			const result = justificationList.map((j) => {
				const user = userList.find((u) => u.Id === j.CreatedUserId);
				return {
					...j,
					firstName: user.FirstName,
					lastName: user.LastName,
					nickName: user.PersonalName,
					id: user.Id,
				};
			}) as Justification[];

			return result;
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	async getSuccessorsByActivityId(
		activityId: string
	): Promise<ISucessorsByActivityId[]> {
		try {
			const indRelationships = await db.relationship
				.where('PredecessorActivityId')
				.equals(activityId)
				.toArray();
			const activityList = await db.activity
				.where('ActivityId')
				.anyOf(indRelationships.map((r) => r.SuccessorActivityId))
				.toArray();
			return activityList.map((a) => {
				return {
					ActivityId: a.ActivityId,
					Name: a.Name,
					Percentcomplete: a.PercentComplete,
				};
			});
		} catch (e) {
			throw e;
		}
	}

	async getPredecessorsByActivityId(
		activityId: string
	): Promise<IPredecessorsByActivityId[]> {
		try {
			const indRelationships = await db.relationship
				.where('SuccessorActivityId')
				.equals(activityId)
				.toArray();
			const activityList = await db.activity
				.where('Id')
				.anyOf(indRelationships.map((r) => r.PredecessorActivityId))
				.toArray();

			return activityList.map((a) => {
				return {
					ActivityId: a.ActivityId,
					Name: a.Name,
					Percentcomplete: a.PercentComplete,
				};
			});
		} catch (e) {
			throw e;
		}
	}

	async getCommentsByActivityIdWithCreatorAndType(
		activityId: number,
		type: CommentType
	): Promise<Justification[]> {
		try {
			const justificationList = (
				await db.justification
					.where({ Type: type, ActivityId: activityId })
					.sortBy('UpdatedDate')
			).reverse();
			const userList = await db.user
				.where('Id')
				.anyOf(justificationList.map((j) => j.CreatedUserId))
				.toArray();

			const justificationlist = justificationList.map((j) => {
				const user = userList.find((u) => u.Id === j.CreatedUserId);
				return {
					...j,
					firstName: user.FirstName,
					lastName: user.LastName,
					nickName: user.PersonalName,
					id: user.Id,
				};
			}) as Justification[];
			return justificationlist;
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	getActivityStartJustificationType(): Promise<
		ActivityStartJustificationType[]
	> {
		return this.activityStartJustificationTypeManager.getActivityStartJustificationTypes();
	}

	async getStartJustificationByActivityId(activityId: number): Promise<any[]> {
		try {
			const users = await db.user.toArray();
			const justifications = await db.activityStartJustification
				.where('CreatedById')
				.anyOf(users.map((u) => u.Id))
				.and((j) => j.ActivityId === activityId)
				.toArray();

			const justification = justifications.map((j) => {
				const user = users.find((u) => u.Id == j.CreatedById);
				return {
					...j,
					userFirstname: user?.FirstName,
					userLastname: user?.LastName,
				};
			});
			return justification;
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	async getLastSyncLogWithQueryRunner(projectId: number): Promise<SyncLog[]> {
    if (projectId) {
      const cai = localStorage.getItem('CAI');
      const uid = localStorage.getItem('UserId');

      const syncLog = await db.syncLog
        .where('UserId')
        .anyOf([uid, cai])
        .and((s) => s.ProjectId === projectId)
        .toArray();

      if (syncLog) {
        return syncLog as SyncLog[];
      }
    }

    return null;
	}

	getProjectsByIds(projectIds: Set<number>): Promise<Project[]> {
		return this.projectManager.getProjectsByIds(projectIds);
	}

	async getPredecessorsById(activityId: string): Promise<any[]> {
		try {
			const successorActivityIdList = await db.relationship
				.where('SuccessorActivityId')
				.equals(activityId)
				.toArray();
			const activitiesList = await db.activity
				.where('Id')
				.anyOf(successorActivityIdList.map((s) => s.PredecessorActivityId))
				.toArray();
			const udfCodeValueList = await db.udfCodeValue
				.where('ForeignId')
				.anyOf(activitiesList.map((a) => a.Id))
				.toArray();
			const result = activitiesList.map((a) => {
				const u = udfCodeValueList.find(
					(udfCode) => udfCode.ForeignId === a.Id
				);
				return { ...a, UDFTypeTitle: u?.UDFTypeTitle, Text: u?.Text };
			});
			return result;
		} catch (e) {
			throw e;
		}
	}
}
