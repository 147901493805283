import { Component, Injector, Input } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { Activity, WBS } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { MTAUtility } from '../../../shared/utility';

@Component({
	selector: 'app-wbs-phone',
	templateUrl: 'wbs.component-phone.html',
	styleUrls: ['./wbs.component-phone.scss'],
})
export class WBSPhoneComponent extends MTAContainerComponent {
	@Input()
	mtaWbs: WBS;
	@Input()
	isCompanyRep: boolean;
	@Input()
	isChevronUser: boolean;
	@Input()
	isExpanded: boolean;
	@Input()
	iconExpand: string;
	@Input()
	crewsIdStr: string;
	@Input()
	isCompletedAllBtnDisabled: boolean;
	@Input()
	isApproveAllToggleDisabled: boolean;
	@Input()
	getWBSHierarchyNames: string;
	@Input()
	updateActivity: (event) => void;
	@Input()
	updatePercent: (event) => void;
	@Input()
	confirmApproveAll: () => void;
	@Input()
	approveActivityIOS: (activity, item) => void;
	@Input()
	rejectActivityIOS: (activity, item) => void;
	@Input()
	completeActivityIOS: (activity, item) => void;
	@Input()
	cannotWork: (activity, item) => void;
	@Input()
	undoStatusIOS: (activity, item) => void;

	constructor(public injector: Injector) {
		super(injector);
	}
	toggle() {
		this.isExpanded = !this.isExpanded;
		this.iconExpand = this.isExpanded
			? 'chevron-up-outline'
			: 'chevron-down-outline';
		this.something.emit({ operation: 'toggle', params: null });
	}

	confirmCompleteAll(event) {
		this.something.emit({ operation: 'confirmCompleteAll', params: null });
		if (event) {
			event.stopPropagation();
		}
	}

	presentprogressModal(event, item, activity) {
		const data = { event, activity, item };
		this.something.emit({ operation: 'presentProgressModal', params: data });
	}

	isDisabled(activity) {
		if (!activity.isReadOnly && !activity.HasNoContractors) {
			if (
				activity.InternalPercentComplete ===
				MTAUtility.getRoundedMinValue(activity.PercentComplete)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	viewToNotesOptions(
		hierarchyId: number,
		event?
	) {
		this.something.emit({
			operation: 'viewToNotesOptions',
			params: hierarchyId,
		});
		if (event) {
			event.stopPropagation();
		}
	}
}
