import { Injectable } from '@angular/core';
import { ResourceLite } from '../../data/InternalTypes';
import { SyncConfig } from '../../services/sync/SyncConfig';
import { BaseServiceProvider } from '../entity-service/base-service';

@Injectable()
export class ResourceLiteServiceProvider extends BaseServiceProvider{
  serviceName = 'resource';
  currentVersionForDownload = '2.0';

  getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }

  getResources(activityId: number): Promise<ResourceLite[] | null> {
    return new Promise<ResourceLite[] | null>((resolve, reject) => {
      this.loginProvider
        .login()
        .then(_ => {
          const options = this.getOptions();
          options.params = options.params.set('activityId', activityId + '');
          this.get(this.getDownloadUrl(), options)
            .then(resources => {
              resolve(resources as ResourceLite[]);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
