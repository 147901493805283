import { MTABaseEntity } from './MTABaseEntity';

export class OBS extends MTABaseEntity {
  Id: number;
  Name: string;
  ParentId: number;
  SequenceNumber: number;
  Description: string;
  CreateDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  init(json: any): void {
    this.Description = json.Description;
    this.Id = json.Id;
    this.Name = json.Name;
    this.ParentId = json.ParentId;
    this.SequenceNumber = json.SequenceNumber;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
  }
}
