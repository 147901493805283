import { Component, Injector, Input, OnInit } from '@angular/core';
import { ResourceLite } from 'src/data/InternalTypes';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ResourceCellPhoneComponent } from './phone/resource-cell.component-phone';
import { ResourceCellTabletComponent } from './tablet/resource-cell.component-tablet';

@Component({
  selector: 'app-resource-cell',
  templateUrl: './resource-cell.component.html',
  styleUrls: ['./resource-cell.component.scss'],
})
export class ResourceCellComponent extends MTAContainerComponent implements OnInit {
  @Input() resource: ResourceLite;
  constructor(public injector: Injector) {
    super(injector);
  }


  ngOnInit() {
    this.subComponentJson = { tablet: ResourceCellTabletComponent, phone: ResourceCellPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = { resource: this.resource };
    this.outputs = {};
  }

}
