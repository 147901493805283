import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WindowRef } from '../window-ref';

@Injectable()
export class ActivationContextProvider {

    constructor(private winRef: WindowRef) {
    }

      

    /**
     * returns the protocol activation type
     */
    get protocolKind() {
        try {
            return this.winRef.nativeWindow.Windows.ApplicationModel.Activation.ActivationKind.protocol;
        } catch (e) {
            return null;
        }
    }

    async launchToNotes(eventId,hierarchyId) {
          window.open(environment.TonotesRedirect +eventId + '/' +hierarchyId);      
    }
    
    async launchExternalApp(schemeName, schemeParams) {
        const uri = new this.winRef.nativeWindow.Windows.Foundation.Uri(schemeName + schemeParams);
        await this.winRef.nativeWindow.Windows.System.Launcher.launchUriAsync(uri);
    }
}
