import { Resource } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';

export class ResourceEntityManager extends BaseEntityManager {
  entityType = Resource;
	entityTableName = 'resource';

  async insertResourceData(resources: Resource[]) {
    await this.insertData(resources);
  }

  async insertorUpdateResourceData(resources: Resource[]) {
    await this.insertOrUpdateData(resources);
  }
}
