import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { MobileVersionStatus, MTAErrorType } from 'src/data/InternalTypes';
import { MTAError } from 'src/data/MTAError';
import { MobileVersionServiceProvider } from 'src/providers/ondemand-services/mobileVersion-service';

@Injectable({
    providedIn: 'root',
  })
export class AppVersionProvider {

    private _isUpdateAvailable = false;
    get isUpdateAvailable(): boolean {
        return this._isUpdateAvailable;
    }
    set isUpdateAvailable(value: boolean) {
        this._isUpdateAvailable = value;
    }

    constructor(
        private platform: Platform,
        private mobileVersionService: MobileVersionServiceProvider,
        private alertController: AlertController,
        private toastController: ToastController
    ) { }

    async getBuildVersion(): Promise<string> {
            try {
                let version = require('package.json').version;
                version = `${version.split('.')[0]}.${version.split('.')[1]}.${version.split('.')[2]}`;
                return String(version);
            } catch (error) {
                throw new MTAError(MTAErrorType.APP_VERSION_UNSUPPORTED, 'App version not found');
            }
    }

    async handleBuildVersion(): Promise<MobileVersionStatus> {
        const version = await this.getBuildVersion();
        return this.mobileVersionService.getVersionCompability(version)
            .then(ver => {
                switch (ver.Status) {
                    case MobileVersionStatus.current:
                        this.isUpdateAvailable = false;
                        break;
                    case MobileVersionStatus.compatible:
                        this.isUpdateAvailable = true;
                        break;
                    case MobileVersionStatus.notSupported:
                        this.isUpdateAvailable = true;
                        this.presentUpdateAlert();
                        break;
                    default:
                        break;
                }
                return ver.Status;
            })
            .catch(error => {
                throw JSON.stringify(error);
            });
    }

   async presentUpdateAlert() {
        const alertController = await this.alertController.create({
            header: 'Info',
            subHeader: '',
            message: 'Your application version is <b>Not Supported</b>, you cannot continue with sync process. Please update your application and continue',
            buttons: [{
                text: 'OK',
                handler: () => {
                }
            }
            ]
        });
        alertController.present();
    }

    async presentUpdateToast() {
        const toast = await this.toastController.create({
            message: 'A new version is available, you can download it from the corresponding catalog or store',
            // breaking fix
            // showCloseButton: true,
            // closeButtonText: 'Dismiss',
            position: 'bottom'
        });
        toast.present();
    }


}
