// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3,
.nav-header-3 {
  text-align: center;
  font-family: "Gotham-Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.25em;
  line-height: 1.3em;
}

.form-group-modal {
  margin: 1.5em;
}

.feedback-form-modal {
  width: 100%;
}
.feedback-form-modal .remove-button {
  font-size: 18px;
  float: right;
  cursor: pointer;
  font-weight: bold;
  margin-right: 8px;
  line-height: 24px;
}
.feedback-form-modal .form-control {
  display: block;
  width: 100%;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1.4em;
  padding: 6px 12px;
}
.feedback-form-modal textarea {
  resize: none;
}`, "",{"version":3,"sources":["webpack://./src/components/feedback-modal/feedback-modal.scss"],"names":[],"mappings":"AACE;;EAEE,kBAAA;EACA,0EAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,aAAA;AAAJ;;AAEE;EACE,WAAA;AACJ;AAAI;EACE,eAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;AAEN;AACI;EACE,cAAA;EACA,WAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;AACN;AACI;EACE,YAAA;AACN","sourcesContent":["\n  h3,\n  .nav-header-3 {\n    text-align: center;\n    font-family: \"Gotham-Bold\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    font-size: 1.25em;\n    line-height: 1.3em;\n  }\n  // padding: 0.8em;\n  .form-group-modal {\n    margin: 1.5em;\n  }\n  .feedback-form-modal {\n    width: 100%;\n    .remove-button {\n      font-size: 18px;\n      float: right;\n      cursor: pointer;\n      font-weight: bold;\n      margin-right: 8px;\n      line-height: 24px;\n  \n    }\n    .form-control {\n      display: block;\n      width: 100%;\n      background-image: none;\n      border: 1px solid #ccc;\n      border-radius: 4px;\n      line-height: 1.4em;\n      padding: 6px 12px;\n    }\n    textarea {\n      resize: none;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
