import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { WBS } from 'src/data/EntityIndex';

@Component({
  selector: 'app-lookahead-wbs-phone',
  templateUrl: 'lookahead-wbs.component-phone.html',
  styleUrls: ['./lookahead-wbs.component-phone.scss']
})
export class LookaheadWBSPhoneComponent extends MTAContainerComponent {
	@Input()
	mtaWbs: WBS;
	@Input()
	isCompanyRep: boolean;
	@Input()
	isChevronUser: boolean;
	@Input()
	isExpanded: boolean;
	@Input()
	iconExpand: string;
	@Input()
	crewsIdStr: string;
	@Input()
	isCompletedAllBtnDisabled: boolean;
	@Input()
	isApproveAllToggleDisabled: boolean;
	@Input()
	getWBSHierarchyNames: string;
  
  constructor(public injector: Injector) {
    super(injector);
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'chevron-up-outline' : 'chevron-down-outline';
    this.something.emit({ operation: 'toggle', params: null });
  }

}
