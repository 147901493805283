import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import { Activity } from '../../data/EntityIndex';
import { ActivityEntityManager } from '../../data/EntityManagerIndex';
import { MTAContainerComponent } from './../../MTAContainerComponent';
import { PercentageBarModalPhoneComponent } from './phone/percentage-bar-modal.component-phone';
import { PercentageBarModalTabletComponent } from './tablet/percentage-bar-modal.component-tablet';

@Component({
  selector: 'app-percentage-bar-modal',
  templateUrl: './percentage-bar-modal.component.html',
  styleUrls: ['./percentage-bar-modal.component.scss'],
})
export class PercentageBarModalComponent extends MTAContainerComponent implements OnInit {
  activity: Activity;
  updatedValue: number;
  isCompanyRep: boolean;
  @Output() changedValue: EventEmitter<number> = new EventEmitter();
  constructor( public injector: Injector,
               private router: Router,
               private popoverCtrl: PopoverController,
               private navParams: NavParams,
               private activityManager: ActivityEntityManager,
               private navCtrl: NavController) {
    super(injector);
    this.activity = this.navParams.get('data').activity as Activity;
    this.isCompanyRep = this.navParams.get('data').isCompanyRep as boolean;
   }

  ngOnInit() {
    this.subComponentJson = { tablet: PercentageBarModalTabletComponent, phone: PercentageBarModalPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      activity: this.activity,
      isCompanyRep: this.isCompanyRep,
      dismissPopOver: () => {
        this.dismissPopOver();
      },
      changePercentage: value => {
        this.changePercentage(value);
      },
      navigateToJustification: () => {
        this.navigateToJustification();
      }
    };
    this.outputs = {};
  }


  changePercentage(value: number) {
    this.updatedValue = value;
    this.activityManager.changePercentage(this.activity, value, this.isCompanyRep);
    this.activityManager.updateEntity(this.activity);
  }

  dismissPopOver() {
    this.popoverCtrl.dismiss();
  }
  navigateToJustification() {
    if (!this.activity.isReadOnly) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          activity: this.activity
        }
      };
      this.router.navigate(['justification'], navigationExtras);
      this.dismissPopOver();
    }
  }

}
