import { ActivityStartJustificationType } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

export class ActivityStartJustificationTypeEntityManager extends BaseEntityManager {
	entityType = ActivityStartJustificationType;
	entityTableName = 'activityStartJustificationType';

	async getActivityStartJustificationTypes(): Promise<ActivityStartJustificationType[]> {
		//return new Promise<ActivityStartJustificationType[]>(async (resolve, reject) => {
				try {
					const activityStartJustificationTypes =
						await db.activityStartJustificationType
							.filter((asj) => asj.Enabled === true)
							.sortBy('Text');

					return(
						activityStartJustificationTypes as ActivityStartJustificationType[]
					);
				} catch (e) {
					throw(e);
				}
			//});
	}
}
