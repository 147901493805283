import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Filters, Filter_Shift } from 'src/data/Filters';
import { JAScreenSortOrder } from 'src/data/InternalTypes';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { StatusFilterComponent } from './../../components/status-filter/status-filter.component';
import { FilterSearchPhoneComponent } from './phone/filter-search.component-phone';
import { FilterSearchTabletComponent } from './tablet/filter-search.component-tablet';

@Component({
  selector: 'app-filter-search',
  templateUrl: 'filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent extends MTAContainerComponent implements OnInit {
  @Input() shift: Filter_Shift;
  @Input() crewType: Filters;
  @Input() isCompanyRep: boolean;
  @Input() awaitingApprovalOnly: boolean;
  @Input() selectedStatusFilter: string[];
  searchText = '';

  @Output() changedSearchFilter: EventEmitter<[any, string]> = new EventEmitter();
  @Output() save: EventEmitter<null> = new EventEmitter();
  @Output() exit: EventEmitter<null> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() searchCancel: EventEmitter<null> = new EventEmitter();

  filters = Filter_Shift;
  sortOrderOptions = Object.keys(JAScreenSortOrder);
  selectedSearchType = ['Highlight', 'Filter'];
  sortOrder: string = this.sortOrderOptions[0];

  statusList = [
    { id: 'NoUpdate', status: this.translate.instant('INSTANT.FilterStatusList.Pending')},
    { id: 'Pending', status: this.translate.instant('INSTANT.FilterStatusList.Pending')},
    { id: 'Approved', status: this.translate.instant('INSTANT.FilterStatusList.Approved')},
    { id: 'AwaitingApproval', status: this.translate.instant('INSTANT.FilterStatusList.AwaitingApproval')},
    { id: 'Updated', status: this.translate.instant('INSTANT.FilterStatusList.Updated')},
    { id: 'CantWork', status: this.translate.instant('INSTANT.FilterStatusList.CantWork')},
    { id: 'Rejected', status: this.translate.instant('INSTANT.FilterStatusList.Rejected')}
  ];

  constructor(public injector: Injector,
              private popOverCtrl: PopoverController,
              public translate: TranslateService) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: FilterSearchTabletComponent, phone: FilterSearchPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      isCompanyRep: this.isCompanyRep,
      awaitingApprovalOnly: this.awaitingApprovalOnly,
      searchText: this.searchText,
      filters: this.filters,
      filterKeys: Object.keys(this.filters),
      selectedShiftFilter: this.translateShift(this.shift),
      selectedStatusFilter: this.selectedStatusFilter,
      sortOrder: this.sortOrder,
      sortOrderOptions: this.sortOrderOptions,
      selectedSearchType: this.selectedSearchType,
      itemStatus: (item, isCompanyRep) => {
        return this.itemStatus(item, isCompanyRep);
      },
      pressChip: value => {
        return this.pressChip(value);
      },
      openFilter: _ => {
        this.openFilter(_);
      },
      getStyle: value => {
        return this.getStyle(value);
      },
      goHome: () => {
        this.goHome();
      },
      sync: () => {
        this.sync();
      }
    };

    this.outputs = {
      something: data => {
        const opt = data.operation;
        const params = data.params;
        switch (opt) {
          case 'changeShift':
            this.changeShift(params);
            break;
          case 'changeApprovalFlag':
            this.changeApprovalFlag();
            break;
          case 'searchInput':
            this.searchInput(params);
            break;
          case 'changeFilter':
            this.changeFilter(params);
            break;
          case 'changeSortOrder':
            this.sortJAScreen(params);
            break;
          case 'changeSearchType':
            this.changeSearchType(params);
            break;
        }
      }
    };
  }
  keys() {
    return Object.keys(this.filters);
  }

  translateShift(shift: string) {
    switch (shift) {
      case 'All':
        return this.translate.instant('FILTERS.Shift_All');
      case '12 hrs':
        return this.translate.instant('FILTERS.Shift_12');
      case '24 hrs':
        return this.translate.instant('FILTERS.Shift_24');
      case '48 hrs':
        return this.translate.instant('FILTERS.Shift_48');
      default:
        return this.translate.instant('FILTERS.Shift_All');
    }
  }

  changeShift(shift: Filter_Shift) {
    this.shift = shift;
    this.changedSearchFilter.emit([this.shift, 'shift']);
  }

  changeApprovalFlag() {
    this.awaitingApprovalOnly = !this.awaitingApprovalOnly;
    this.changedSearchFilter.emit([this.awaitingApprovalOnly, 'await']);
  }

  sortJAScreen(data) {
    this.changedSearchFilter.emit([data, 'sortJAScreen']);
  }

  changeSearchType(data) {
    this.changedSearchFilter.emit([data, 'changeSearchType']);
  }

  changeFilter(data) {
    let filterValue: any; // filter added on migration
    if (data.data !==  undefined) {
       filterValue = data.data.status;
       this.shift = data.data.shift;
    } else {
       filterValue = data.status;
       this.shift = data.shift;
    }

    this.selectedStatusFilter = [];
    for (const statusObj of filterValue) {
      if (statusObj.checked) {
        this.selectedStatusFilter.push(statusObj.id);
      }
    }

    this.inputs.selectedStatusFilter = this.selectedStatusFilter;
    this.inputs.selectedShiftFilter = this.translateShift(this.shift);

    if (data.data === undefined) { // filter added on migration
      this.changedSearchFilter.emit([data, 'changeFilter']);
    } else {
      this.changedSearchFilter.emit([data.data, 'changeFilter']);
    }
    if (typeof this.searchText !== 'undefined') {
      this.inputs.searchText = this.searchText;
    }
  }

  itemStatus(item, isCompanyRep) {
    if (isCompanyRep && item === 'Updated') {
      return this.statusList.find(x => x.id === 'AwaitingApproval').status;
    } else {
      return this.statusList.find(x => x.id === item).status;
    }
  }

  getStyle(value: string) {
    if (value === this.shift) {
      return 'btn-highlighted';
    } else {
      return 'btn-normal';
    }
  }

  goHome() {
    this.exit.emit();
  }

  sync() {
    this.save.emit();
  }

  searchInput(searchText: string) {
    if (searchText?.trim()?.length > 2) {
      this.searchText = searchText;
      this.search.emit(searchText.trim());
    } else if (searchText === '') {
      this.search.emit(searchText);
    }
  }

  pressChip(status: { type: string; value: string }) {
    const statusArray = [];
    if (status.type === 'shift') {
      if (this.shift !== Filter_Shift.Shift_All) {
        this.shift = Filter_Shift.Shift_All;
      }
    }
    for (const item of this.selectedStatusFilter) {
      if (status.type === 'status' && item === status.value) {
        statusArray.push({ checked: false, id: item});
      } else {
        statusArray.push({ checked: true, id: item});
      }
    }

    this.changeFilter({ shift: this.shift, status: statusArray });
  }

  async openFilter(ev: any) {
    const css = this.platform.isMobile() ? 'status-list-modal': 'status-list-modal-tablet';

    const statusListModal = await this.popOverCtrl.create({
      component: StatusFilterComponent,
      cssClass: css,
      backdropDismiss: true,
      showBackdrop: true,
      event: ev,
      componentProps : { isCompanyRep: this.isCompanyRep, shift: this.shift, status: this.selectedStatusFilter }
    });

    statusListModal.onDidDismiss().then((filterObj) => {
      if (filterObj) {
        if (filterObj.data !== undefined) {
          this.changeFilter(filterObj);
        }
      }
    });


    // tslint:disable-next-line: deprecation
    await statusListModal.present();
  }
}
