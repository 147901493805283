import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Filter_Shift } from 'src/data/Filters';
import { SearchbarInputEventDetail } from '@ionic/angular';

@Component({
  selector: 'app-filter-search-phone',
  templateUrl: 'filter-search.component-phone.html',
  styleUrls: ['./filter-search.component-phone.scss'],

})
export class FilterSearchPhoneComponent extends MTAContainerComponent {
	@Input()
	isCompanyRep: boolean;
	@Input()
	awaitingApprovalOnly: boolean;
	@Input()
	searchText: string;
	@Input()
	filters: typeof Filter_Shift;
	@Input()
	filterKeys: Array<string>;
	@Input()
	selectedShiftFilter: any;
	@Input()
	selectedStatusFilter: Array<string>;
	@Input()
	sortOrder: string;
	@Input()
	sortOrderOptions: Array<string>;
	@Input()
	selectedSearchType: Array<string>;
	@Input()
	itemStatus: (item, isCompanyRep) => any;
	@Input()
	pressChip: (value) => void;
	@Input()
	openFilter: (_) => void;
	@Input()
	getStyle: (value) => 'btn-highlighted' | 'btn-normal';
	@Input()
	goHome: () => void;
	@Input()
	sync: () => void;

  isSearchBarOpened = false;
  searchType = 'Highlight';
  isSearchFocus = false;

  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

  expand(){
    this.isSearchFocus = true;
  }

  unExpand() {
    this.isSearchFocus = false;
  }

  changeApprovalFlag() {
    this.awaitingApprovalOnly = !this.awaitingApprovalOnly;
    this.something.emit({ operation: 'changeApprovalFlag', params: null });
  }

  searchInput(evt: CustomEvent<SearchbarInputEventDetail>) {
    this.something.emit({ operation: 'searchInput', params: evt.detail.value });
  }

  changeShift(shift: string) {
    this.something.emit({ operation: 'changeShift', params: this.selectedShiftFilter });
  }

  changeFilter(data) {
    this.something.emit({ operation: 'changeFilter', params: data });
  }

  changeSortOrder() {
    this.something.emit({ operation: 'changeSortOrder', params: this.sortOrder });
  }

  changeSearchType() {
    this.something.emit({ operation: 'changeSearchType', params: this.searchType });
  }
}
