import { Component, Injector, Input, OnInit } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { DependencyListCellPhoneComponent } from './phone/dependency-list-cell.component-phone';
import { DependencyListCellTabletComponent } from './tablet/dependency-list-cell.component-tablet';

@Component({
  selector: 'app-dependency-list-cell',
  templateUrl: './dependency-list-cell.component.html',
  styleUrls: ['./dependency-list-cell.component.scss'],
})
export class DependencyListCellComponent extends MTAContainerComponent  implements OnInit {
  @Input() activity: any;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.subComponentJson = { tablet: DependencyListCellTabletComponent, phone: DependencyListCellPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = { activity: this.activity };
    this.outputs = {};
  }

}
