import { MTABaseEntity } from './MTABaseEntity';

export class ResourceAssignment extends MTABaseEntity {
  ResourceId: number;
  ActivityId: number;
  ProjectId: number;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.ResourceId = json.ResourceId;
    this.ActivityId = json.ActivityId;
    this.ProjectId = json.ProjectId;
  }
}
