export const environment = {
    production: false,
    mode: 'QA',
    LogonAzureAPI: 'https://mobileturnaround-api-qa-cvx.azurewebsites.net/api/login',
    BaseAPI: 'https://mobileturnaround-api-qa-cvx.azurewebsites.net/api',
    //BaseAPI: 'http://localhost:24193/api',
    AuthenticateScope: 'https://turnaroundms-test.azure.chevron.com/user_impersonation',
    TonotesRedirect: 'https://tonotesweb-qa-cvx.azurewebsites.net/#/home/TaPro/',
    PushNotificationHubPath: 'mobileturnaround-nh-test-cvx',
    PushNonnectionString:
    'https://turnaroundms-t10us-cvx.vault.azure.net/secrets/TaProPushNonnectionString/f025f568348f465ba009fb1c568f4407',
    FCMSenderId: '380548788333',
    schemeName: 'com.chevron.tonotestest://',
    TCOInstanceId: ['5'],
    adtionalDbOptions: ['error'],
  
    appRegistryB2C: {
      clientId: '7fb4b6b8-b711-46e0-bad1-2c42653f70bf',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      names: {
        signUpSignIn: 'B2C_1_v1_signupandsignin_fwe'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://chevronb2c.b2clogin.com/chevronb2c.onmicrosoft.com/B2C_1_v1_signupandsignin_fwe'
        }
      },
      knownAuthorities: ['chevronb2c.b2clogin.com']
    },
    // Insights
    aiKey: '8ec6f906-75ce-4526-97ce-2e14239f6829',
  };
  