import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { ProjectEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class ProjectServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = ProjectEntityManager;

  public serviceName = 'project';
  public getProjects = 'projects';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/`;
    } else {
      return null;
    }
  }

  public getOptions(config?: SyncConfig): { headers: any; params: any } {
    const opt = super.getOptions(config);
    if (config) {
      opt.params = opt.params.set('id', config.project + '');
    }
    return opt;
  }

  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
