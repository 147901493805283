import { MTABaseEntity } from './MTABaseEntity';

export class User extends MTABaseEntity {
  Id: number;
  Cai: string;
  FirstName: string;
  LastName: string;
  Email: string;
  PersonalName: string;
  ResourceId: number;
  GlobalProfiledId: number;
  Role: string;
  DateTimeFormatType: string;
  CreatedBy: string;
  CreatedDate: string;
  UpdatedBy: string;
  UpdatdDate: string;
  IsDeleted: boolean;
  init(json: any): void {
    this.IsDeleted = json.IsDeleted;
    this.CreatedBy = json.CreatedBy;
    this.Id = json.Id;
    this.UpdatedBy = json.UpdatedBy;
    this.Cai = json.Cai;
    this.FirstName = json.FirstName;
    this.LastName = json.LastName;
    this.Email = json.Email;
    this.PersonalName = json.PersonalName;
    this.ResourceId = json.ResourceId;
    this.GlobalProfiledId = json.GlobalProfiledId;
    this.Role = json.Role;
    this.DateTimeFormatType = json.DateTimeFormatType;
    this.CreatedDate = json.CreatedDate;
    this.UpdatdDate = json.UpdatdDate;
  }
}
