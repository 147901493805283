import { Component, Injector, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Activity, Project } from 'src/data/EntityIndex';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { UtilCalendarPhoneComponent } from './phone/util-calendar.component-phone';
import { UtilCalendarTabletComponent } from './tablet/util-calendar.component-tablet';

@Component({
  selector: 'app-util-calendar',
  templateUrl: './util-calendar.component.html',
  styleUrls: ['./util-calendar.component.scss'],
})
export class UtilCalendarComponent extends MTAContainerComponent implements OnInit {
  @Input() activity: Activity;
  @Input() project: Project;
  minDate: string = new Date().toISOString();
  constructor(injector: Injector, private router: Router, private translate: TranslateService) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: UtilCalendarTabletComponent, phone: UtilCalendarPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      activity: this.activity,
      navigateToJustification: () => {
        this.navigateToJustification(this.activity, this.project);
      }
    };
    this.outputs = {
      // smething: data => {
      //   let opt = data['operation'];
      //   let params = data['params'];
      //   switch (opt) {
      //     case '':
      //       break;
      //   }
      // }
    };
  }

  navigateToJustification(activity: Activity, project: Project) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        activity, project
      }
    };
    this.router.navigate(['justification'], navigationExtras);
  }

  getDisplayShift(): string {
    const date = new Date(this.activity.ProjectedEndDate);
    if (date.getHours() > 12) {
      return this.translate.instant('INSTANT.DayShift');
    } else {
      return this.translate.instant('INSTANT.NightShift');
    }
  }

}
