import { AuthService } from './../../services/auth.service';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { CrewType, RoleType, UserType } from './../../data/InternalTypes';
import { NavigationExtras, Router } from '@angular/router';
import {
	ActionSheetController,
	AlertController,
	ModalController,
	NavController,
	PopoverController,
	ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackModalComponent } from 'src/components/feedback-modal/feedback-modal';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { PushNotificationProvider } from 'src/providers/push-notification/push-notification';
import { DbService } from 'src/services/db.service';
import { SyncProvider } from 'src/services/sync/sync';
import { SyncProgress } from 'src/services/sync/sync-progress';
import { AppVersionProvider } from 'src/shared/appVersion';
import { LoadingControllerHandler } from 'src/shared/loading-controller-handler';
import { LoginProvider } from 'src/shared/login';
import { SharedPlatformProvider } from 'src/shared/platform';
import { Project, User } from '../../data/EntityIndex';
import { Filters } from '../../data/Filters';
import { WelcomeSlidesPage } from '../welcome-slides/welcome-slides.page';
import { HomePagePhoneComponent } from './phone/home.page-phone';
import { HomePageTabletComponent } from './tablet/home.page-tablet';
import { SyncManager } from '../../services/sync/SyncManager';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from 'src/services/network.service';

@Component({
	selector: 'app-home',
	templateUrl: 'home.page.html',
	styleUrls: ['home.page.scss'],
})
export class HomePage extends MTAContainerComponent implements OnInit {
	@Input() welcomeMsg: string;
	buildNumber: string;
	hasOfflineData: boolean;
	offlineProjects: Project[];
	isLookAheadBtnDisable = false;
	crewFilter = Filters;
	syncAutomatic = false;
	user: User;
	networkStatus: boolean = false;
	networkStatus$: Subscription = Subscription.EMPTY;

	constructor(
		public router: Router,
		public injector: Injector,
		public navCtrl: NavController,
		private networkService: NetworkService,
		private actionSheetCtrl: ActionSheetController,
		private toastCtrl: ToastController,
		private alertCtrl: AlertController,
		private modalCtrl: ModalController,
		private popoverCtrl: PopoverController,
		private loadingControllerHandler: LoadingControllerHandler,
		private appVersionProvider: AppVersionProvider,
		private pushManager: PushNotificationProvider,
		private loginProvider: LoginProvider,
		private authService: AuthService,
		public mtaPlatform: SharedPlatformProvider,
		private syncProvider: SyncProvider,
		private dbService: DbService,
		private network: NetworkService,
		public dm: DbService,
		public sm: SyncManager,
		private translate: TranslateService
	) {
		super(injector);
		this.actionSheetCtrl = injector.get(ActionSheetController);
		this.syncProvider = injector.get(SyncProvider);
		this.dbService = injector.get(DbService);
	}

	public ngOnInit() {
		window.addEventListener('online', (e) => {
			if (this.syncAutomatic) {
				this.syncOffLine();
			}
		});

		if (!localStorage.isNoticeAccepted) {
			this.router.navigate(['notice']);
		}

		this.subComponentJson = {
			tablet: HomePageTabletComponent,
			phone: HomePagePhoneComponent,
		};
		this.subComponent = this.getComponentType();

		this.inputs = {
			crewFilter: this.crewFilter,
			welcomeMsg: this.welcomeMsg,
			isUpdateAvailable: this.appVersionProvider.isUpdateAvailable,
			hasOfflineData: this.hasOfflineData,
			offlineProjects: this.offlineProjects,
			checkIfLookAheadBtnDisable: () => {
				return this.isLookAheadBtnDisable;
			},
			wasUserLoggedin: () => {
				return this.loginProvider.wasUserLoggedin();
			},
			userIsExternal: () => {
				if (this.user !== null && this.user !== undefined) {
					const userType = localStorage.getItem('UserType');
					return (
						this.user.Cai === '' ||
						this.user.Cai === null ||
						this.user.Cai.toLowerCase() === 'null' ||
						userType !== UserType.chevron
					);
				} else {
					return false;
				}
			},
			sync: () => {
				this.sync();
			},
			openSlides: () => {
				this.openSlides();
			},

			setWelcomeMessage: () => {
				this.setWelcomeMessage();
			},

			logout: () => {
				this.logout();
			},

			openJobPage: (type: Filters) => this.openJobPage(type),
			showFeedback: (event) => {
				this.showFeedback(event);
			},
		};

		this.setWelcomeMessage();
		this.outputs = {};
	}

	checkNetworkStatus() {
		this.networkStatus = navigator.onLine;
		this.networkStatus$ = merge(
			of(null),
			fromEvent(window, 'online'),
			fromEvent(window, 'offline')
		)
			.pipe(map(() => navigator.onLine))
			.subscribe((status) => {
				console.log('network status home', status);
				this.networkStatus = status;
			});
	}

	ngOnDestroy(): void {
		this.networkStatus$.unsubscribe();
	}

	ionViewWillEnter() {
		this.user = this.loginProvider.getUser();
		this.setWelcomeMessage();
		this.syncProvider.hasOfflineData().then(async (offlineProjects) => {
			this.offlineProjects = await this.dm.getProjectsByIds(offlineProjects);

			this.hasOfflineData = Boolean(offlineProjects?.size);
			this.inputs.hasOfflineData = this.hasOfflineData;
			this.inputs.offlineProjects = this.offlineProjects;
		});

		this.checkProject();
	}

	async syncOffLine(): Promise<void> {
		const ping = await this.networkService.pingServer();
		// console.log('start date',new Date());
		// setTimeout(() =>
		// {
		if (ping === true) {
			this.syncAutomatic = false;
			let projectId = 0;
			this.offlineProjects?.forEach((p, index) => {
				projectId = p.Id;
				const syncProgress = new SyncProgress();
				this.syncProvider
					.sync(syncProgress, projectId)
					.then(
						async () => {
							this.user = this.authService.getUser();
							this.setWelcomeMessage();
							// TODO: implementation
							await this.checkProject();
							if (
								(this.user === null || this.user === undefined) &&
								this.loginProvider.wasUserLoggedin()
							) {
								this.user = this.loginProvider.getUser();
								this.setWelcomeMessage();
							}
							this._evalProjects(projectId);
							this.inputs.isUpdateAvailable =
								this.appVersionProvider.isUpdateAvailable;
							await this.loadingControllerHandler.dismissAllLoaders();
						},
						async (err) => {
							await this.loadingControllerHandler.dismissAllLoaders();
						}
					)
					.catch(async () => {
						await this.loadingControllerHandler.dismissAllLoaders();
					});
			});
		} else {
			this.syncOffLine();
		}
		//console.log('end date',new Date());
		// },10000);
	}

	private _evalProjects(projectId: number): void {
		if (this.offlineProjects && this.offlineProjects.length > 0) {
			this.offlineProjects.forEach((q, Index) => {
				if (q.Id === projectId) {
					this.offlineProjects.splice(Index, 1);
				}
			});
			if (this.offlineProjects.length > 0) {
				this.hasOfflineData = true;
				this.inputs.hasOfflineData = this.hasOfflineData;
				this.inputs.offlineProjects = this.offlineProjects;
			} else {
				this.hasOfflineData = false;
				this.inputs.hasOfflineData = null;
				this.inputs.offlineProjects = null;
			}
		}
	}

	public async sync(projectId?: number) {
		this.checkNetworkStatus();
		const ping = await this.network.pingServer();
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			content: 'loading',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();

		if (this.networkStatus) {
			this._noNetworkSync(projectId);
		} else {
			if (ping === false) {
				this._networkAndPingSync();
			}
		}
	}

	private async _networkAndPingSync(): Promise<void> {
		this.loadingControllerHandler.dismissAllLoaders();
		const alert = await this.alertCtrl.create({
			header: 'Network Not Available',
			message: 'Do you Like automatic sync, when network is available',
			buttons: [
				{
					text: this.translate.instant('INSTANT.Cancel'),
					role: 'cancel',
					handler: () => {
						this.syncAutomatic = false;
					},
				},
				{
					text: 'OK',
					handler: () => {
						this.syncAutomatic = true;
					},
				},
			],
		});
		alert.present();
	}

	private async _noNetworkSync(projectId: number): Promise<void> {
		const syncProgress = new SyncProgress();
		syncProgress.multipleProjectsAssigned = async (projects) => {
			this.setWelcomeMessage();
			const projectButtons = [];
			projects.forEach((project) => {
				const projectBtn = {
					text: project.ProjectId,
					handler: () => {
						this.enableLanguageSelection(project);
					},
				};

				projectButtons.push(projectBtn);
			});
			await this.pushManager.registerInNotificationHub();
			await this.loadingControllerHandler.dismissAllLoaders();
			const actionSheet = await this.actionSheetCtrl.create({
				header: this.translate.instant('INSTANT.SelectProject'),
				buttons: projectButtons,
			});
			actionSheet.present();
		};

		this.syncProvider
			.sync(syncProgress, projectId)
			.then(
				async () => {
					this.user = this.authService.getUser();
					this.setWelcomeMessage();
					// TODO: implementation
					await this.checkProject();
					if (
						(this.user === null || this.user === undefined) &&
						this.loginProvider.wasUserLoggedin()
					) {
						this.user = this.loginProvider.getUser();
						this.setWelcomeMessage();
					}
					if (this.offlineProjects && this.offlineProjects.length > 0) {
						this.offlineProjects.forEach((p, index) => {
							if (p.Id === projectId) {
								this.offlineProjects.splice(index, 1);
							}
						});
						if (this.offlineProjects.length > 0) {
							this.hasOfflineData = true;
							this.inputs.hasOfflineData = this.hasOfflineData;
							this.inputs.offlineProjects = this.offlineProjects;
						} else {
							this.hasOfflineData = false;
							this.inputs.hasOfflineData = null;
							this.inputs.offlineProjects = null;
						}
					}
					this.inputs.isUpdateAvailable =
						this.appVersionProvider.isUpdateAvailable;

					await this.loadingControllerHandler.dismissAllLoaders();
				},
				async (err) => {
					await this.loadingControllerHandler.dismissAllLoaders();
				}
			)
			.catch(async () => {
				await this.loadingControllerHandler.dismissAllLoaders();
			});
	}

	enableLanguageSelection(project) {
		localStorage.setItem('LanguageSelectionEnabled', 'true');
		this.sync(project.Id);
	}

	async openJobPage(crewFilter: Filters) {
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			content: 'loading',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		const projects = await this.dm.getActiveProjects();
		if (projects?.length > 0) {
			if (projects.length === 1) {
				await this.loadingControllerHandler.dismissAllLoaders();
				this.openProject(projects[0], crewFilter);
			} else {
				// more than one active project
				const projectButtons = [];
				projects.forEach((project) => {
					const projectBtn = {
						text: project.ProjectId,
						handler: () => {
							this.openProject(project, crewFilter);
						},
					};
					projectButtons.push(projectBtn);
				});
				const actionSheet = await this.actionSheetCtrl.create({
					header: this.translate.instant('INSTANT.SelectProject'),
					buttons: projectButtons,
				});
				await this.loadingControllerHandler.dismissAllLoaders();
				actionSheet.present();
			}
		} else {
			await this.loadingControllerHandler.dismissAllLoaders();
			this.newToast(
				this.translate.instant('INSTANT.NoProject'),
				'toastInfo',
				false,
				3000
			);
		}
	}

	async openSlides() {
		const welcomeModal = await this.modalCtrl.create({
			component: WelcomeSlidesPage,
		});
		welcomeModal.present();
	}

	 setWelcomeMessage() {
		if (this.user === null || this.user === undefined) {
			this.user =  this.authService.getUser();
			console.log('user detail',this.user)
		}

		this.welcomeMsg =
			this.user === null || this.user === undefined
				? ''
				: this.translate.instant('INSTANT.Hello') + this.user.FirstName;
		this.inputs.welcomeMsg = this.welcomeMsg;
	}

	async openProject(project: Project, crewFilter: Filters) {
		const uid = parseInt(localStorage.getItem('UserId'), 10);
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			content: 'loading',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		this.sm.getCrewAssignment(uid, project.Id).then(async (ca) => {
			console.log('openProject', {ca});
			if (ca?.length > 0) {
				let isCompanyRep = false;
				let isContractForemen = false;
				let role = '';
				let crCrews = ca.reduce((acc, element) => {
					if (element.Crew.Type === CrewType.CoReps) {
						isCompanyRep = true;
						acc.push(element);
					} else if (
						element.Crew.Type === CrewType.Crew1 ||
						element.Crew.Type === CrewType.Crew2
					) {
						isContractForemen = true;
						role = RoleType.CF;
					}
					return acc;
				}, []);
				if (isCompanyRep) {
					if (isContractForemen) {
						this._showSelectRoleAlert(ca, project, crewFilter);
						return;
					}
					role = RoleType.CR;
					this.sm.hasContractors(project.Id).then(async (hasContractors) => {
						if (!hasContractors) {
							crCrews.forEach((item) => {
								item.HasNoContractors = true;
							});
						}
					});
				}
				await this.loadingControllerHandler.dismissAllLoaders();
				this.openJobPageForRole(project, ca, role, crewFilter);
				return;
			}
			await this.loadingControllerHandler.dismissAllLoaders();
			this.newToast(
				this.translate.instant('INSTANT.NoCrew'),
				'toastInfo',
				false,
				3000
			);
		});
	}

	private async _showSelectRoleAlert(ca, project, crewFilter) {
		console.log('_showSelectRoleAlert');
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			header: this.translate.instant('INSTANT.SelectRoleHeader'),
			message: this.translate.instant('INSTANT.SelectRoleMessage'),
			buttons: [
				{
					text: this.translate.instant('INSTANT.CompanyRep'),
					handler: () => {
						console.log('_showSelectRoleAlert:CompanyRep');
						const crCrews = ca.filter((e) => e.Crew.Type === CrewType.CoReps);
						this.sm.hasContractors(project.Id).then(async (hasContractors) => {
							crCrews.forEach((item) => {
								item.HasNoContractors = !hasContractors ? true : false;
							});
							this.openJobPageForRole(
								project,
								crCrews,
								RoleType.CR,
								crewFilter
							);
						});
					},
				},
				{
					text: this.translate.instant('INSTANT.ContractForemen'),
					handler: () => {
						const cfCrews = ca.filter(
							(e) =>
								e.Crew.Type === CrewType.Crew1 || e.Crew.Type === CrewType.Crew2
						);
						this.openJobPageForRole(project, cfCrews, RoleType.CF, crewFilter);
					},
				},
			],
		});
		await this.loadingControllerHandler.dismissAllLoaders();
		alert.present();
	}

	private async newToast(
		msg: string,
		msgCSSClass: string,
		bShowButton: boolean,
		nDuration: number
	) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: nDuration,
			position: 'bottom',
			cssClass: msgCSSClass,
		});
		toast.present();
	}

	async openJobPageForRole(project, crews, role, crewFilter) {
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			content: 'loading',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		if (role === '') {
			await this.loadingControllerHandler.dismissAllLoaders();
		} else {
			const navigationExtras: NavigationExtras = {
				queryParams: {
					project,
					crews,
					role,
					crewFilter,
				},
			};
			await this.loadingControllerHandler.dismissAllLoaders();
			this.router.navigate(['wbs-category'], navigationExtras);
		}
	}

	async checkProject(): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			content: '',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		this.dm
			.getActiveProjects()
			.then(async (p) => {
				if (p.length === 1) {
					localStorage.setItem('LanguageSelectionEnabled', 'true');
				}
				await this.loadingControllerHandler.loadingController.dismiss();
				if (p.length > 0) {
					this.isLookAheadBtnDisable = false;
				} else {
					this.isLookAheadBtnDisable = true;
					if (localStorage.isNoticeAccepted) {
						this.newToast(
							this.translate.instant('INSTANT.ClicSync'),
							'toastInfo',
							false,
							5000
						);
					}
				}
			})
			.catch(async (error) => {
				this.isLookAheadBtnDisable = true;
				await this.loadingControllerHandler.loadingController.dismiss();
				if (localStorage.isNoticeAccepted) {
					this.newToast(
						this.translate.instant('INSTANT.ClicSync'),
						'toastInfo',
						false,
						5000
					);
				}
			});
		//});
	}

	async logout() {
		if (this.loginProvider.wasUserLoggedin()) {
			const alert = await this.alertCtrl.create({
				header: this.translate.instant('INSTANT.LogoutHeader'),
				message: this.translate.instant('INSTANT.LogoutMessage'),

				buttons: [
					{
						text: this.translate.instant('INSTANT.Cancel'),
						role: 'cancel',
						handler: () => {},
					},
					{
						text: this.translate.instant('INSTANT.Logout'),
						handler: () => {
							this.loginProvider
								.logOut()
								.then(async () => {
									this.isLookAheadBtnDisable = true;
									this.user = null;
									this.setWelcomeMessage();
									const toast = await this.toastCtrl.create({
										message: this.translate.instant('INSTANT.LogoutComplete'),
										duration: 5000,
									});
									this.dm.reset();
									toast.present();
								})
								.catch(async (e) => {
									const toast = await this.toastCtrl.create({
										message: this.translate.instant('INSTANT.LogoutFailed'),
										duration: 5000,
									});
									toast.present();
								});
						},
					},
				],
			});
			alert.present();
		}
	}

	async showFeedback(event: Event) {
		const feedbackModal = await this.popoverCtrl.create({
			component: FeedbackModalComponent,
			cssClass: this.mtaPlatform.isMobile()
				? 'feedback-modal-phone'
				: 'feedback-modal-tablet',
			showBackdrop: true,
			event,
			backdropDismiss: true,
		});
		await feedbackModal.present(); // breaking change , changed properties and event
	}
}
