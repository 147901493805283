// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  width: 35%;
  min-width: 150px;
  object-fit: contain;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/brand/brand.component.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AADF","sourcesContent":["@import './../../../colors.scss';\n\nimg {\n  width: 35%;\n  min-width: 150px;\n  object-fit: contain;\n  padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
