import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeedbackDTO } from '../dto/FeedbackDTO';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class FeedbackService extends BaseServiceProvider {
  public postFeedback(feedback: FeedbackDTO): Promise<any> {
    return this.post(`${environment.BaseAPI}/feedback`, { feedback }, this.getOptions());
  }
}
