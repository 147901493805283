import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ActivationContextProvider } from './activationContext';

/*
  Generated class for the DeepLinkProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class DeeplinkProvider {

  constructor(
    private activationContextProvider: ActivationContextProvider,
    private toastController: ToastController) {
  }
  get schemeName(): string {
    return environment.schemeName;
  }
  openTONotesWeb(eventId,hierarchyId){
    this.activationContextProvider.launchToNotes(eventId,hierarchyId);
  }

  openExternalApp(params: string) {
    console.log("unused method");
  }

  async presentNoToNotesToast() {
    const toast = await this.toastController.create({
      message: 'Please install \'Test TO Notes\' application from the Chevron Store',
      duration: 2000
    });
    await toast.present();
  }


}
