import { MTABaseEntity } from './MTABaseEntity';

export class SyncLog extends MTABaseEntity {
  Id: number;
  UserId: string;
  ProjectId: number;
  LastUploadTime: string;
  LastDownloadTime: string;
  DeviceId: string;
  CreatedDate: string;
}
