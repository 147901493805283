// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Job-Header-Row {
  background-color: #e5e5e5;
  min-width: 100%;
  border-radius: 8px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/lookahead-wbs/lookahead-wbs.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;AACJ","sourcesContent":[".Job-Header-Row {\n    background-color: #e5e5e5;\n    min-width: 100%;\n    border-radius: 8px;\n    padding: 10px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
