import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Constants } from '../constants';

@Injectable()
export class AppStorageProvider {
  constructor( private platform: Platform) {}

  private getKey(): string {
    return Constants.SecureStorageKey;
  }

  /**
   * Get the value from local storage using the key provided
   * @param key - Get the value for the key stored in storage
   * @param fromSecure - Optional parameter to find key in secure storage or not, default is false
   */
  public GetLocal(key: string, fromSecure?: boolean): Promise<any> {
    return Promise.resolve(localStorage.getItem(key));
  }

  /**
   * Set value to local storage using the key provided
   * @param key - Key used to store the value in storage
   * @param value - Value to be stored in the storage
   * @param toSecure - Optional parameter to find store in secure storage or not, default is false
   */
  public async SetLocal(key: string, value: any, toSecure?: boolean): Promise<any> {
        localStorage.setItem(key, value);
  }

  /**
   * Clear Local Storage
   * @param clearSecure - Clear Securage storage or not.
   */
  public async Clear(clearSecure?: boolean): Promise<any> {
    
        localStorage.clear();
      
  }
}
