import { MTABaseEntity } from './MTABaseEntity';

export class Justification extends MTABaseEntity {
  GlobalId: string;
  ActivityId: number;
  Text: string;
  Type: string;
  CreatedBy: string;
  CreateDate: string;
  IsDeleted: boolean;
  UpdatedBy: string;
  UpdatedDate: string;
  CreatedUserId: number;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  id?: number;
  init(json: any): void {
    this.IsDeleted = json.IsDeleted;
    this.Type = json.Type;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.ActivityId = json.ActivityId;
    this.Text = json.Text;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.CreatedUserId = json.CreatedUserId;
    this.GlobalId = json.GlobalId;
  }
}
