import { inject } from '@angular/core';
import { catchError, finalize, from, map, Observable, of } from 'rxjs';
import { DbService } from 'src/services/db.service';
import { LoadingService } from 'src/services/loading.service';

export const dbGuard = (): Observable<boolean> => {
	const loadingService = inject(LoadingService);
	const dbService = inject(DbService);
	loadingService.present();
	return from(dbService.ready()).pipe(
		(map(() => {
			return true;
		}),
		catchError((dbGuardError) => {
			return of(true);
		}),
		finalize(() => loadingService.dismiss()))
	);
};
