import { ResourceAssignment } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';

export class ResourceAssignmentEntityManager extends BaseEntityManager {
  entityType = ResourceAssignment;
	entityTableName = 'resourceAssignment';

  async insertResourceAssignmentData(resourceAssignments: ResourceAssignment[]) {
    await this.insertData(resourceAssignments);
  }

  async insertorUpdateResourceAssignmentData(resourceAssignments: ResourceAssignment[]) {
    await this.insertOrUpdateData(resourceAssignments);
  }
}
