import { NgModule } from '@angular/core';
import { MtaDatePipe } from './mta-date/mta-date';
import { StatusForChipPipe } from './status-for-chip/status-for-chip';
import { StatusPipe } from './status/status';

@NgModule({
  declarations: [StatusPipe, StatusForChipPipe, MtaDatePipe],
  imports: [],
  exports: [StatusPipe, StatusForChipPipe, MtaDatePipe]
})
export class PipesModule {}
