import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Constants } from 'src/shared/constants';


/**
 * Generated class for the MtaDatePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'mtaDate'
})
export class MtaDatePipe implements PipeTransform {
  transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): string {
    if (!format) {
      const localeName = navigator.language;
      return new Date(value).toLocaleString(localeName, {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    } else {
      const timezoneOffset = moment(value).format(Constants.timezoneOffset);
      return new DatePipe('en-US').transform(value, format, timezoneOffset);
    }
  }
}

// .replace(/(.*)\D\d+/, '$1')
