import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { CrewEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class CrewServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = CrewEntityManager;

  public serviceName = 'crew';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${config.project}`;
    } else {
      return null;
    }
  }
  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
