import { MTABaseEntity } from './MTABaseEntity';

export class Resource extends MTABaseEntity {
  Id: number;
  Name: string;
  EmployeeId: number;
  EmailAddress: string;
  LocationName: string;
  LocationId: number;
  PrimaryRoleId: number;
  ResourceType: string;
  SequenceNumber: number;
  ShiftId: number;
  Title: string;
  IsActive: boolean;
  UserName: string;
  UserId: number;
  ParentId: number;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.Id = json.Id;
    this.LocationName = json.LocationName;
    this.EmployeeId = json.EmployeeId;
    this.Name = json.Name;
    this.EmailAddress = json.EmailAddress;
    this.LocationId = json.LocationId;
    this.PrimaryRoleId = json.PrimaryRoleId;
    this.ResourceType = json.ResourceType;
    this.SequenceNumber = json.SequenceNumber;
    this.ShiftId = json.ShiftId;
    this.Title = json.Title;
    this.IsActive = json.IsActive;
    this.UserName = json.UserName;
    this.UserId = json.UserId;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.ParentId = json.ParentId;
  }
}
