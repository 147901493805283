import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class DateTimeServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = null;

  public serviceName = 'datetime';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }

  public download(config: SyncConfig): Promise<any> {
    return super.downloadWithOutSave(config);
  }

  public getBodyForDownload(config: SyncConfig): Promise<object> {
    return Promise.resolve(null);
  }
}
