import { MTABaseEntity } from './MTABaseEntity';

export class UDFCodeValue extends MTABaseEntity {
  UDFTypeId: number;
  ForeignId: number;
  ProjectId: number;
  Text: string;
  CodeValue: string;
  Description: string;
  UDFTypeTitle: string;
  UDFTypeSubjectArea: string;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.UDFTypeId = json.UDFTypeId;
    this.ForeignId = json.ForeignId;
    this.Text = json.Text;
    this.CodeValue = json.CodeValue;
    this.UDFTypeTitle = json.UDFTypeTitle;
    this.UDFTypeSubjectArea = json.UDFTypeSubjectArea;
    this.ProjectId = json.ProjectId;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
  }
}
