import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AlertController, IonItemSliding, PickerController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivityInternalStatus } from '../../data/ActivityInternalStatus';
import { Filters } from '../../data/Filters';
import { Activity, WBS } from './../../data/EntityIndex';
import { MTAContainerComponent } from './../../MTAContainerComponent';
import { ActivityCellComponent } from './../activity-cell/activity-cell.component';
import { PercentageBarModalComponent } from './../percentage-bar-modal/percentage-bar-modal.component';
import { WBSPhoneComponent } from './phone/wbs.component-phone';
import { WBSTabletComponent } from './tablet/wbs.component-tablet';

@Component({
  selector: 'app-wbs',
  templateUrl: './wbs.component.html',
  styleUrls: ['./wbs.component.scss'],
})
export class WbsComponent extends MTAContainerComponent implements OnInit {
  isExpanded = false;
  isWBSInitialized = false;
  wbsPathSeparator = '.';
  private _iconExpand = 'chevron-down-outline';
  get iconExpand(): string {
    return this._iconExpand;
  }
  set iconExpand(value: string) {
    this._iconExpand = value;
  }
  @Input() mtaWbs: WBS;
  @Output() selectedWbs: EventEmitter<WBS> = new EventEmitter();
  @Input() shiftDetail: Filters;
  @Input() crewsIdStr: string;
  @Input() isCompanyRep = false;
  @Input() isChevronUser = false;
  @Output() changedActivity: EventEmitter<[Activity, string, any]> = new EventEmitter();
  @Output() changedActivities: EventEmitter<[Activity[], string]> = new EventEmitter();
  @Output() navigateToTONotes: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren(ActivityCellComponent) cellComponents: QueryList<ActivityCellComponent>;
  private _isApproveAllToggleDisabled = false;
  get isApproveAllToggleDisabled(): boolean {
    this._isApproveAllToggleDisabled = (this.shiftDetail !== Filters.MyJobs && this.isCompanyRep) ||  this.mtaWbs.IsReadOnly;
    return this._isApproveAllToggleDisabled;
  }
  private _isCompletedAllBtnDisabled = false;

  get isCompletedAllBtnDisabled(): boolean {
    this._isCompletedAllBtnDisabled = (this.shiftDetail !== Filters.MyJobs) || this.mtaWbs.IsReadOnly;
    return this._isCompletedAllBtnDisabled;
  }
  constructor(public injector: Injector,
              private alertCtrl: AlertController,
              private popOverCtrl: PopoverController,
              private pickerController: PickerController,
              private translate: TranslateService) {
    super(injector);
  }

  ngOnInit() {
    this.subComponentJson = { tablet: WBSTabletComponent, phone: WBSPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      mtaWbs: this.mtaWbs,
      isCompanyRep: this.isCompanyRep,
      isChevronUser: this.isChevronUser,
      isExpanded: this.isExpanded,
      iconExpand: this.iconExpand,
      crewsIdStr: this.crewsIdStr,
      isCompletedAllBtnDisabled: this.isCompletedAllBtnDisabled,
      isApproveAllToggleDisabled: this.isApproveAllToggleDisabled,
      updateActivity: event => {
        this.updateActivity(event);
      },
      updatePercent: event => {
        this.updateInternalPercentage(event);
      },
      confirmApproveAll: () => {
        this.confirmApproveAll();
      },
      approveActivityIOS: (activity, item) => {
        this.approveActivityIOS(activity, item);
      },
      rejectActivityIOS: (activity, item) => {
        this.rejectActivityIOS(activity, item);
      },
      completeActivityIOS: (activity, item) => {
        this.completeActivityIOS(activity, item);
      },
      cannotWork: (activity, item) => {
        this.cannotWork(activity, item);
      },
      undoStatusIOS: (activity, item) => {
        this.undoStatusIOS(activity, item);
      },
      getWBSHierarchyNames: this.getWBSHierarchyNames
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        const params = data.params;
        switch (opt) {
          case 'toggle':
            this.toggle();
            break;
          case 'confirmCompleteAll':
            this.confirmCompleteAll();
            break;
          case 'confirmApproveAll':
            this.confirmApproveAll();
            break;
          case 'completeActivityIOS':
            this.completeActivityIOS(params.activity, params.item);
            break;
          case 'cannotWork':
            this.cannotWork(params.activity, params.item);
            break;
          case 'rejectActivityIOS':
            this.rejectActivityIOS(params.activity, params.item);
            break;
          case 'presentProgressModal':
            this.presentProgressModal(params.event, params.item, params.activity);
            break;
          case 'viewToNotesOptions':
            this.viewToNotesOptions(params);
            break;
        }
      }
    };
  }

  toggle() {
    if (!this.isWBSInitialized) {
      this.selectedWbs.emit(this.mtaWbs);
      this.isWBSInitialized = true;
    }

    this.changeArrow();
  }

  changeArrow() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'chevron-up' : 'chevron-down';
  }

  get getWBSHierarchyNames() {
    return `<h5>${this.mtaWbs.Name}</h5><h6>${this.mtaWbs.longPath}</h6>`;
  }

  updateActivity(activityAndOperation: [Activity, string, any]) {
    this.changedActivity.emit(activityAndOperation);
  }

  completeAllActivities() {
    this.changedActivities.emit([this.mtaWbs.filteredActivities, 'p']);
  }

  confirmCompleteAll() {
    const msg = this.translate.instant('INSTANT.ConfirmComplete');
    this.confirmOperation(msg, this.completeAllActivities.bind(this));
  }

  confirmApproveAll() {
    const msg = this.translate.instant('INSTANT.ConfirmApprove');
    this.confirmOperation(msg, this.approveAllActivities.bind(this));
  }

  async confirmOperation(msg: string, confirmHandler: any) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('INSTANT.ConfirmApprove'),
      message: msg,
      buttons: [
        {
          text: this.translate.instant('INSTANT.Cancel'),
          role: 'cancel',
          handler: () => {
            // Do nothing
          }
        },
        {
          text: this.translate.instant('INSTANT.Confirm'),
          handler: () => {
            confirmHandler();
          }
        }
      ]
    });
    await alert.present();
  }

  approveAllActivities() {
    this.changedActivities.emit([this.mtaWbs.filteredActivities, 's']);
  }

  resetActivityStatus(activity: Activity) {
    this.cellComponents.forEach(cellComponent => {
      if (cellComponent.activity.Id === activity.Id) {
        cellComponent.resetActivityStatus();
      }
    });
  }

  cannotWork(activity: Activity, slidingItem: IonItemSliding) {
    this.updateActivity([activity, 'c', null]);
    slidingItem.close();
  }

  approveActivityIOS(activity: Activity, slidingItem: IonItemSliding) {
    this.updateActivity([activity, 's', ActivityInternalStatus.Approved]);
    slidingItem.close();
  }

  rejectActivityIOS(activity: Activity, slidingItem: IonItemSliding) {
    this.updateActivity([activity, 's', ActivityInternalStatus.Rejected]);
    slidingItem.close();
  }

  undoStatusIOS(activity: Activity, slidingItem: IonItemSliding) {
    const status: ActivityInternalStatus = activity.InternalPercentComplete === activity.PercentComplete * 100 ? null : ActivityInternalStatus.Updated;
    this.updateActivity([activity, 's', status]);
    slidingItem.close();
  }

  completeActivityIOS(activity: Activity, slidingItem: IonItemSliding) {
    this.updateActivity([activity, 'p', 100]);
    slidingItem.close();
  }


  async presentProgressModal(event: any, slidingItem: IonItemSliding, activity: Activity) {

    const progressModal = await this.popOverCtrl.create({
      component: PercentageBarModalComponent,
      cssClass: 'progress-bar-modal',
      backdropDismiss: true,
      showBackdrop: true,
      event,
      componentProps : { data: {activity, isCompanyRep: this.isCompanyRep } }
    });
    await progressModal.present();
    slidingItem.close();
  }

  async presentProgressModalDial(activityAndOperation: [Activity]) {
    const activity = activityAndOperation[0];

    let percentComplete = 0;
    if (activity.PercentComplete !== 0) {
      if (activity.PercentComplete <= 0.1) {
        percentComplete = 10;
      } else if (
        activity.PercentComplete > 0.9 &&
        activity.PercentComplete < 1
      ) {
        percentComplete = Math.trunc(activity.PercentComplete * 10) * 10;
      } else {
        percentComplete = Math.round(activity.PercentComplete * 10) * 10;
      }
    }

    let actualIndex = 0;
    let intIndex = 0;

    const percentsValues = [];
    for (let index = percentComplete; index <= 100; index += 10) {
      if (index === activity.InternalPercentComplete) {
        actualIndex = intIndex;
      }
      percentsValues.push({ text: index + ' %',  value: index });
      intIndex++;
    }

    const picker = await this.pickerController.create({
      buttons: [
        {
          text: this.translate.instant('INSTANT.Confirm'),
          handler: (selected) => {
            this.updateActivity([activity, 'p', selected.percent.value]);
          }
        },
        {
          text: this.translate.instant('SHARED.CantWork'),
          handler: (selected) => {
            this.updateActivity([activity, 'c', null]);
          }
        },
        {
          text: this.translate.instant('INSTANT.Cancel'),
        }
      ],
      columns: [
        {
          name: 'percent',
          selectedIndex: actualIndex,
          options: percentsValues
        }
      ]
    });
    await picker.present();
  }

  viewToNotesOptions(hierarchyId: number) {
    this.navigateToTONotes.emit(hierarchyId);
  }

  updateInternalPercentage(activityAndOperation: [Activity])
  {
    const activity = activityAndOperation[0];
    this.updateActivity([activity, 'p', activity.InternalPercentComplete]);
  }

}
