import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
  selector: 'app-dependency-list-tablet',
  templateUrl: 'dependency-list.component-tablet.html',
  styleUrls: ['./dependency-list.component-tablet.scss'],
})
export class DependencyListTabletComponent extends MTAContainerComponent {
	@Input()
	title: string;
	@Input()
	activities: any[];
  
  showActivities = false;
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

  toggleActivities() {
    this.showActivities = !this.showActivities;
  }
}
