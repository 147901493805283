import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PushNotificationProvider } from 'src/providers/push-notification/push-notification';

@Component({
	selector: 'app-notice',
	templateUrl: './notice.page.html',
	styleUrls: ['./notice.page.scss'],
})
export class NoticePage {
	constructor(
		public router: Router,
		public navCtrl: NavController,
		private alertCtrl: AlertController,
		private pushManager: PushNotificationProvider,
		private translate: TranslateService
	) {
		const user = localStorage.getItem('UserId');
		if (user) this.onAgreeTapped();
	}

	onAgreeTapped() {
		localStorage.isNoticeAccepted = true;
		this.router.navigate(['home']);
	}

	async onDontAgreeTapped() {
		const alert = await this.alertCtrl.create({
			header: this.translate.instant('INSTANT.AccessDenied'),
			subHeader: this.translate.instant('INSTANT.AgreeTerms'),
			buttons: [
				{ text: this.translate.instant('INSTANT.OK'), handler: () => {} },
				{
					text: this.translate.instant('INSTANT.Agree'),
					handler: () => {
						this.onAgreeTapped();
					},
				},
			],
		});
		await alert.present();
	}
}
