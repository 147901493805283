import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Filters } from '../../data/Filters';
import { Activity, WBS } from './../../data/EntityIndex';
import { MTAContainerComponent } from './../../MTAContainerComponent';
import { ActivityCellComponent } from './../activity-cell/activity-cell.component';
import { LookaheadWBSPhoneComponent } from './phone/lookahead-wbs.component-phone';
import { LookaheadWBSTabletComponent } from './tablet/lookahead-wbs.component-tablet';

@Component({
  selector: 'app-lookahead-wbs',
  templateUrl: './lookahead-wbs.component.html',
  styleUrls: ['./lookahead-wbs.component.scss'],
})
export class LookaheadWbsComponent extends MTAContainerComponent implements OnInit {
  isExpanded = false;
  isWBSInitialized = false;
  wbsPathSeparator = '.';
  private _iconExpand = 'chevron-down-outline';
  get iconExpand(): string {
    return this._iconExpand;
  }
  set iconExpand(value: string) {
    this._iconExpand = value;
  }
  @Input() mtaWbs: WBS;
  @Output() selectedWbs: EventEmitter<WBS> = new EventEmitter();
  @Input() shiftDetail: Filters;
  @Input() crewsIdStr: string;
  @Input() isCompanyRep = false;
  @Input() isChevronUser = false;
  @Output() changedActivity: EventEmitter<[Activity, string, any]> = new EventEmitter();
  @Output() changedActivities: EventEmitter<[Activity[], string]> = new EventEmitter();
  @Output() navigateToTONotes: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren(ActivityCellComponent) cellComponents: QueryList<ActivityCellComponent>;
  private _isApproveAllToggleDisabled = false;
  get isApproveAllToggleDisabled(): boolean {
    this._isApproveAllToggleDisabled = (this.shiftDetail !== Filters.MyJobs && this.isCompanyRep) ||  this.mtaWbs.IsReadOnly;
    return this._isApproveAllToggleDisabled;
  }
  private _isCompletedAllBtnDisabled = false;

  get isCompletedAllBtnDisabled(): boolean {
    this._isCompletedAllBtnDisabled = (this.shiftDetail !== Filters.MyJobs) || this.mtaWbs.IsReadOnly;
    return this._isCompletedAllBtnDisabled;
  }
  constructor(public injector: Injector,
              private translate: TranslateService) {
    super(injector);
  }

  ngOnInit() {
    this.subComponentJson = { tablet: LookaheadWBSTabletComponent, phone: LookaheadWBSPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      mtaWbs: this.mtaWbs,
      isCompanyRep: this.isCompanyRep,
      isChevronUser: this.isChevronUser,
      isExpanded: this.isExpanded,
      iconExpand: this.iconExpand,
      crewsIdStr: this.crewsIdStr,
      isCompletedAllBtnDisabled: this.isCompletedAllBtnDisabled,
      isApproveAllToggleDisabled: this.isApproveAllToggleDisabled,
      getWBSHierarchyNames: this.getWBSHierarchyNames
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        if(opt ===  'toggle')
          this.toggle();
      }
    };
  }

  toggle() {
    if (this.isWBSInitialized === false) {
      this.selectedWbs.emit(this.mtaWbs);
      this.isWBSInitialized = true;
      this.changeArrow();
    } else {
      this.changeArrow();
    }
  }

  changeArrow() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'chevron-up' : 'chevron-down';
  }

  get getWBSHierarchyNames() {
    return `<h5>${this.mtaWbs.Name}</h5><h6>${this.mtaWbs.longPath}</h6>`;
  }

  resetActivityStatus(activity: Activity) {
    this.cellComponents.forEach(cellComponent => {
      if (cellComponent.activity.Id === activity.Id) {
        cellComponent.resetActivityStatus();
      }
    });
  }
}
