import { SyncConfig } from 'src/services/sync/SyncConfig';
import { Justification, SyncLog2 } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';
import * as moment from 'moment';
import { Guid } from 'src/shared/GUID';

export class JustificationEntityManager extends BaseEntityManager {
	entityType = Justification;
	entityTableName = 'justification';

	async insertorUpdateComments(comments: Justification[]) {
		comments.forEach(comment => {
			comment.GlobalId = Guid.newGuid().toString();
		});
		await this.insertOrUpdateData(comments);
	}

	async getDeltaData(config: SyncConfig): Promise<Justification[]> {
		//return new Promise<Justification[]>(async (resolve, reject) => {
			try {
				let entities: Partial<Justification>[] = [];
				if (config.lastDownloadTime && config.lastDownloadTime !== '') {
					const activities = await db.activity
						.where('ProjectId')
						.equals(config.project)
						.toArray();
					entities = await db.justification
						.where('ActivityId')
						.anyOf(activities.map((a) => a.Id))
						.and(
							(j) =>
								j.UpdatedBy == config.cai &&
								moment(j.UpdatedDate).isAfter(moment(config.lastDownloadTime))
						)
						.toArray();
				}
				return(entities as Justification[]);
			} catch (e) {
				throw(e);
			}
		//});
	}

	async hasOfflineData(syncLogs: SyncLog2[]): Promise<Set<number>> {
		//return new Promise<Set<number>>(async (resolve, reject) => {
			try {
				const offlineProjects: Set<number> = new Set();
				for (const syncLog of syncLogs) {
					let count: number;
					if (syncLog.LastDownloadTime && syncLog.LastDownloadTime !== '') {
						const activities = await db.activity
							.where('ProjectId')
							.equals(syncLog.ProjectId)
							.toArray();

						count = await db.justification
							.where('ActivityId')
							.anyOf(activities.map((a) => a.Id))
							.and(
								(j) =>
									j.UpdatedBy == localStorage.getItem('CAI') &&
									moment(j.UpdatedDate).isAfter(
										moment(syncLog.LastDownloadTime)
									)
							)
							.count();
					}
					if (!isNaN(count) && count > 0) {
						offlineProjects.add(syncLog.ProjectId);
					} else {
						continue;
					}
				}
				return(offlineProjects);
			} catch (e) {
				throw(e);
			}
		//});
	}
}
