import { Injectable, isDevMode } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AppStorageProvider } from '../appStorage/app-storage';

export enum LogLevel {
  Trace = 'Trace',
  Info = 'Information',
  Warn = 'Warnings',
  Error = 'Error',
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsProvider {
  private logLevel: LogLevel;
  private appInsights: ApplicationInsights;

  constructor(private platform: Platform, private storage: AppStorageProvider) {
    if (!isDevMode()) {
      this.platform.ready().then(async () => {
        const log = (await this.storage.GetLocal('logLevel')) as string;

        if (log) {
          this.logLevel = LogLevel[log];
        } else {
          this.storage.SetLocal('logLevel', LogLevel.Error);
          this.logLevel = LogLevel.Error; // defaults log to error
        }

        this.loadAppInsights();
      });
    }
  }

  private loadAppInsights() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.aiKey,
        url: 'assets/ApplicationInsightSDK/ai.js',
        disableAjaxTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  setLogLevel(level: LogLevel) {
    console.log('Updated log level - ' + level);
    this.logLevel = level;
  }

  getLogLevel() {
    return this.logLevel;
  }

  trace(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Trace)) {
      this.trackEvent(event, {
        LogLevel: LogLevel.Trace,
        params: params ?? {},
        message,
      });
    }
  }

  info(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Info)) {
      this.trackEvent(event, {
        LogLevel: LogLevel.Info,
        params: params ?? {},
        message,
      });
    }
  }

  warn(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Warn)) {
      this.trackEvent(event, {
        LogLevel: LogLevel.Warn,
        params: params ?? {},
        message,
      });
    }
  }

  error(event: string, message: string, params?: any) {
    console.error(`event: ${event}  | message:${message}`);

    if (this.canLog(LogLevel.Error)) {
      this.trackEvent(event, {
        LogLevel: LogLevel.Error,
        params: params ?? {},
        message,
      });
    }
  }

  private canLog(level: LogLevel) {
    switch (this.logLevel) {
      case LogLevel.Trace:
        return true;
      case LogLevel.Info:
        return level !== LogLevel.Trace;
      case LogLevel.Warn:
        return level !== LogLevel.Trace && level !== LogLevel.Info;
      case LogLevel.Error:
        return level === LogLevel.Error;
      default:
        return false;
    }
  }

  private trackEvent(event: string, params: any) {
    console.log(`MTA ---> ${event} , params ---> ${JSON.stringify(params)}`);
  }
}
