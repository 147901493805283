import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class SharedPlatformProvider {
  constructor(public platform: Platform) {}

  // tslint:disable-next-line:variable-name
  public _isMobile: boolean = undefined;
  public _isWindows: boolean = undefined;
 
  checkIsMobile() {
    let isMobiledevice = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      isMobiledevice = true;
     }
    return isMobiledevice;
  };
   
 
  public isTabletOrGreater() {
    const smallest = Math.min(this.platform.width(), this.platform.height());
    const largest = Math.max(this.platform.width(), this.platform.height());
    return smallest > 460 && smallest < 820 && largest > 780;
  }
  public isMobile(): boolean {
    if (this._isMobile === undefined) {  
       this._isMobile = this.checkIsMobile();
    }
   
    return this._isMobile;
  }

 
  
}
