import { Injectable } from '@angular/core';
import { SyncLog2 } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

@Injectable()
export class SyncLogEntityManager extends BaseEntityManager {
  entityType = SyncLog2;
	entityTableName = 'syncLog';

  async getLastSyncLogForCurrentUser(): Promise<SyncLog2> {
    const uid = localStorage.getItem('UserId');
    const cai = localStorage.getItem('CAI');
  //  return new Promise<SyncLog2>(async (resolve, reject) => {
      try {
       const log = await db.syncLog.where('UserId').equals(uid).or('UserId')
       .equals(cai).first();

        return(log as SyncLog2);
      } catch (e) {
        throw(e);
      }
   // });
  }
}
