import { MTABaseEntity } from './MTABaseEntity';

export class Relationship extends MTABaseEntity {
  Id: number;
  GUID: string;
  Type: string;
  PredecessorActivityId: number;
  PredecessorFinishDate: string;
  PredecessorProjectId: number;
  PredecessorWbsName: string;
  SuccessorActivityId: number;
  SuccessorFinishDate: string;
  SuccessorProjectId: number;
  SuccessorWbsName: string;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.GUID = json.GUID;
    this.Id = json.Id;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.Type = json.Type;
    this.PredecessorActivityId = json.PredecessorActivityId;
    this.PredecessorFinishDate = json.PredecessorFinishDate;
    this.PredecessorProjectId = json.PredecessorProjectId;
    this.PredecessorWbsName = json.PredecessorWbsName;
    this.SuccessorActivityId = json.SuccessorActivityId;
    this.SuccessorFinishDate = json.SuccessorFinishDate;
    this.SuccessorWbsName = json.SuccessorWbsName;
  }
}
