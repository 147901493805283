import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { Activity } from '../../data/EntityIndex';
import { ActivityActualStartFinishModalComponent } from '../activity-actual-start-finish-modal/activity-actual-start-finish-modal.component';
import { ActivityActualStartFinishPhoneComponent } from './phone/activity-actual-start-finish.component-phone';
import { ActivityActualStartFinishTabletComponent } from './tablet/activity-actual-start-finish.component-tablet';


@Component({
  selector: 'app-activity-actual-start-finish',
  templateUrl: './activity-actual-start-finish.component.html',
  styleUrls: ['./activity-actual-start-finish.component.scss'],
})
export class ActivityActualStartFinishComponent extends MTAContainerComponent implements OnInit {
  @Input() activity: Activity;
  @Output() changedValue: EventEmitter<Activity> = new EventEmitter();
  public maxStartDate: string;
  public maxFinishDate: string;
  public minFinishDate: string;
  public actualDisplayFormat: string;
  public disabled: boolean;

  constructor(public injector: Injector, public popoverController: PopoverController, private toastCtrl: ToastController) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: ActivityActualStartFinishTabletComponent, phone: ActivityActualStartFinishPhoneComponent };
    this.subComponent = this.getComponentType();
    this.actualDisplayFormat = moment().localeData().longDateFormat('L') + ' HH:mm';
    this.disabled = (this.activity.isReadOnly || !this.activity.CanUpdateActuals);
    this.verifyDates();
    this.inputs = {
      activity: this.activity,
      maxStartDate: this.maxStartDate,
      maxFinishDate: this.maxFinishDate,
      minFinishDate: this.minFinishDate,
      actualDisplayFormat: this.actualDisplayFormat,
      disabled: this.disabled,
      updateDates: eve => {
        this.updateDates(eve);
      },
      updateFinishDate: () => {
        this.updateFinishDate();
      },
      updateStartDate: () => {
        this.updateStartDate();
      }
    };
    this.outputs = {
      something: () => {}
    };
  }

  async verifyDates() {
    this.maxFinishDate = this.toISOLocal(new Date());
    if (this.activity.InternalActualFinishDate) {
      this.activity.ActualFinishDate = this.activity.InternalActualFinishDate;
    }

    if (this.activity.InternalActualStartDate) {
      this.activity.ActualStartDate = this.activity.InternalActualStartDate;
    }

    if (this.activity.ActualFinishDate) {
      this.maxStartDate = this.toISOLocal(new Date(this.activity.ActualFinishDate));
    } else {
      this.maxStartDate = this.maxFinishDate;
    }
    this.minFinishDate = this.toISOLocal(new Date(this.activity.ActualStartDate));
  }

  async updateFinishDate() {
    this.inputs.maxStartDate =  this.toISOLocal(new Date(this.activity.ActualFinishDate));
    this.maxStartDate = this.inputs.maxStartDate;
    this.activity.InternalActualFinishDate = this.inputs.maxStartDate;
    this.activity.ActualFinishDate = this.inputs.maxStartDate;
    this.activity.ActualsManuallyUpdated = true;
    this.activity.Status = 'Updated';
    this.activity.UpdatedDate = new Date().toISOString();
    this.changedValue.emit(this.activity);
  }

  async updateStartDate() {
    this.inputs.minFinishDate =  this.toISOLocal(new Date(this.activity.ActualStartDate));
    this.minFinishDate = this.inputs.minFinishDate;
    this.activity.InternalActualStartDate = this.inputs.minFinishDate;
    this.activity.ActualStartDate = this.inputs.minFinishDate;
    this.activity.ActualsManuallyUpdated = true;
    this.activity.Status = 'Updated';
    this.activity.UpdatedDate = new Date().toISOString();
    this.changedValue.emit(this.activity);
  }

   async updateDates(eve){
    const finishDateEmitter = new EventEmitter<Date>();
    const startDateEmitter = new EventEmitter<Date>();
    startDateEmitter.subscribe(
      startDate => {
        if (moment(this.activity.ActualStartDate).diff(startDate, 'd') >= 1) {
          this.activity.ActualStartDate = startDate.toString();
          this.activity.InternalActualStartDate = startDate.toString();
          this.activity.ActualsManuallyUpdated = true;
          this.activity.Status = 'Updated';
          this.activity.UpdatedDate = new Date().toISOString();
          this.changedValue.emit(this.activity);
        }
      }
    );
    finishDateEmitter.subscribe(
      finishDate => {
        if (moment(this.activity.ActualFinishDate).diff(finishDate, 'd') >= 1) {
          this.activity.ActualFinishDate = finishDate.toString();
          this.activity.InternalActualFinishDate =  finishDate.toString();
          this.activity.ActualsManuallyUpdated = true;
          this.activity.Status = 'Updated';
          this.activity.UpdatedDate = new Date().toISOString();
          this.changedValue.emit(this.activity);
        }
      }
    );


    const popover = await this.popoverController.create({
      component: ActivityActualStartFinishModalComponent,
      cssClass: 'popover-actual-finish-date',
      event: eve,
      mode: 'ios',
      componentProps: { startDate: this.activity.ActualStartDate,
                        finishDate: this.activity.ActualFinishDate,
                        percentCompleted: this.activity.InternalPercentComplete,
                        startDateEmitter,
                        finishDateEmitter}
    });
    await popover.present();
  }

  toISOLocal(d) {
    const z = n => (n < 10 ? '0' : '') + n;
    let off = d.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);
    // tslint:disable-next-line:no-bitwise
    return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' + z(d.getDate()) + 'T' + z(d.getHours()) + ':' + z(d.getMinutes()) + ':' + z(d.getSeconds()) + sign + z(off / 60 | 0) + ':' + z(off % 60);
  }
}
