import { MTABaseEntity } from './MTABaseEntity';

export class FlattenActivityCode extends MTABaseEntity {
  ActivityId: number;
  ProjectId: number;
  Priority: string;
  Ewo: string;
  CompanyRepId: number;
  CompanyRep: string;
  MainCrewId: number;
  MainCrew: string;
  SupportCrewId: number;
  SupportCrew: string;
  WorkOrderTask: string;
  TawExcluded: boolean;
  UpdatedDate: string;
  init(json: any): void {
    this.ActivityId = json.ActivityId;
    this.ProjectId = json.ProjectId;
    this.Priority = json.Priority;
    this.Ewo = json.Ewo;
    this.MainCrew = json.MainCrew;
    this.MainCrewId = json.MainCrewId;
    this.SupportCrew = json.SupportCrew;
    this.SupportCrewId = json.SupportCrewId;
    this.CompanyRep = json.CompanyRep;
    this.CompanyRepId = json.CompanyRepId;
    this.TawExcluded = json.TawExcluded;
    this.WorkOrderTask = json.WorkOrderTask;
    this.UpdatedDate = json.UpdatedDate;
  }
}
