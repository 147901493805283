import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable, IUploadable } from 'src/services/sync/SyncInterface';
import { BaseEntityManager } from '../EntityManagerIndex';

@Injectable()
export class BaseServiceProvider {
	_entityManger = BaseEntityManager;
	serviceName = null;
	protected _baseURL = environment.BaseAPI;
	protected downloadOperationName = null;
	protected uploadOperationName = null;
	protected currentVersionForDownload = '1.0';
	protected currentVersionForUpload = '1.0';

	constructor(private readonly _http: HttpClient) {}

	isDownloadable(): this is IDownloadable {
		return 'download' in this;
	}

	isUploadable(): this is IUploadable {
		return 'upload' in this;
	}

	getDownloadUrl(config?: SyncConfig): string {
		if (this.isDownloadable) {
			return `${this._baseURL}/${this.serviceName}/${this.downloadOperationName}`;
		} else {
			return null;
		}
	}

	getUploadUrl(): string {
		if (this.isUploadable) {
			return `${this._baseURL}/${this.serviceName}/${this.uploadOperationName}`;
		} else {
			return null;
		}
	}

	getBodyForDownload(config: SyncConfig): Promise<object> {
		return Promise.resolve({
			projectId: config.project,
			lastUpdateDate: config.lastDownloadTime,
		});
	}

	getBodyForUpload(config: SyncConfig): any {
		return {
			projectId: config.project,
			lastUpdateDate: config.lastUploadTime,
		};
	}

	getOptions(config?: SyncConfig): { headers: any; params: any } {
		const headers: HttpHeaders = new HttpHeaders().set(
			'Content-Type',
			'application/json'
		);
		let params: HttpParams = new HttpParams().set(
			'api-version',
			this.currentVersionForDownload
		);
		if (config && config.lastDownloadTime) {
			params = params.set('LastUpdateDate', config.lastDownloadTime);
		}
		return { headers, params };
	}

	post(
		url: string,
		body: any | null,
		options?: {
			headers?:
				| HttpHeaders
				| {
						[header: string]: string | string[];
				  };
			observe?: 'body';
			params?:
				| HttpParams
				| {
						[param: string]: string | string[];
				  };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			try {
				this._http
					.post(url, body, options)
					// .pipe(retry(10))
					.pipe(timeout(180000))
					.subscribe(
						(result: any) => {
							resolve(result); // TODO - How to filter exception from Azure API's
						},
						(error: HttpErrorResponse) => {
							// Http call failed;
							if (error.error) {
								reject(error.error.message);
							} else {
								reject(error);
							}
						}
					);
			} catch (e) {
				reject('Unkown error Http POST');
			}
		});
	}

	get(
		url: string,
		options?: {
			headers?:
				| HttpHeaders
				| {
						[header: string]: string | string[];
				  };
			observe?: 'body';
			params?:
				| HttpParams
				| {
						[param: string]: string | string[];
				  };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			try {
				this._http
					.get(url, options)
					.pipe(retry(10))
					.pipe(timeout(180000))
					.subscribe(
						(result: any) => {
							resolve(result); // TODO - How to filter exception from Azure API's
						},
						(error: HttpErrorResponse) => {
							// Http call failed;
							if (error.error) {
								reject(error.error.message);
							} else {
								reject(error);
							}
						}
					);
			} catch (e) {
				reject('Unkown error Http POST');
			}
		});
	}

	download_base(config: SyncConfig): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			this.get(this.getDownloadUrl(config), this.getOptions(config)).then(
				(result) => {
					try {
						resolve(result);
					} catch (e) {
						reject(e);
					}
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	upload_base(config: SyncConfig): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.post(
				this.getUploadUrl(),
				this.getBodyForUpload(config),
				this._getOptionsForUpload()
			).then(
				(result) => {
					resolve();
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	downloadWithOutSave(config: SyncConfig): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.get(this.getDownloadUrl(config), this.getOptions(config)).then(
				(result) => {
					resolve(result);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	private _getOptionsForUpload(): object {
		const headers: HttpHeaders = new HttpHeaders().set(
			'Content-Type',
			'application/json'
		);
		const params: HttpParams = new HttpParams().set(
			'api-version',
			this.currentVersionForUpload
		);

		return {
			headers,
			params,
		};
	}
}
