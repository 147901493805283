export class MTABaseEntity {
  public getInsertSqlForEntity(): string {
    let sql = '';
    let columns = '';
    let values = '';

    Object.keys(this).forEach((key) => {
      if (this.hasOwnProperty(key)) {
        const column = key;
        if (columns !== '') {
          columns += ', ';
        }
        columns += column;

        let value = this[key];
        if (typeof value === 'string') {
          // tslint:disable-next-line:quotemark
          value = value.replace(/'/g, "''");
          // tslint:disable-next-line:quotemark
          value = "'" + value + "'";
        } else if (typeof value === 'boolean') {
          value = value === true ? 1 : 0;
        } else if (value === undefined || value === null) {
          value = null;
        }

        if (values !== '') {
          values += ', ';
        }
        values += value;
      }
    });

    sql = `replace into ${this.constructor.name} (${columns}) values (${values});`;
    return sql;
  }

  public getSqlValues(entities: MTABaseEntity[]): string {
    let valuesForEntities = '';
    entities.forEach((entity) => {
      let valuesForEntity = '(';
      Object.keys(entity).forEach((key) => {
        let value = entity[key];

        if (typeof value === 'string') {
          // tslint:disable-next-line:quotemark
          value = value.replace(/'/g, "''");
          // tslint:disable-next-line:quotemark
          value = "'" + value + "'";
        } else if (typeof value === 'boolean') {
          value = value === true ? 1 : 0;
        } else if (value === undefined || value === null) {
          value = null;
        }

        if (valuesForEntity !== '(') {
          valuesForEntity += ', ';
        }

        valuesForEntity += value;
      });

      valuesForEntity += ')';

      if (valuesForEntities !== '') {
        valuesForEntities += ', ';
      }
      valuesForEntities += valuesForEntity;
    });
    return valuesForEntities;
  }

  public getInsertSqlForEntities(entities: MTABaseEntity[]): string {
    let sql = '';
    let columns = '';
    let values = '';

    Object.keys(this).forEach((key) => {
      if (this.hasOwnProperty(key)) {
        const column = key;
        if (columns !== '') {
          columns += ', ';
        }
        columns += column;
      }
    });

    values = this.getSqlValues(entities);
    sql = `replace into ${this.constructor.name} (${columns}) values ${values};`;
    return sql;
  }

  public init(json): void {
    console.info("init method");
  }
}
