import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDTO } from 'src/data/dto/FeedbackDTO';
import { FeedbackService } from 'src/data/entity-service/feedback-service';
import { LoginProvider } from 'src/shared/login';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: 'feedback-modal.html',
  styleUrls: ['./feedback-modal.scss']
})
export class FeedbackModalComponent {

  categories = ['Feedback', 'Improvement', 'Issue'];

  feedbackForm = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
    category: new UntypedFormControl(this.categories[0], Validators.required)
  });

  constructor(
    private feedbackSvc: FeedbackService,
    private loginProvider: LoginProvider,
    private viewCtrl: PopoverController,
    private loadingController: LoadingController,
    private translate: TranslateService) {
  }

  submitFeedback(): void {

    const feedback: FeedbackDTO = {
      Comment: this.feedbackForm.get('text').value,
      Category: this.feedbackForm.get('category').value
    };

    this.loginProvider.login().then(async user => {
      const loading = await this.loadingController.create({
        spinner: 'dots',
        message: this.translate.instant('INSTANT.FeedbackModal'),
        // dismissOnPageChange: true braeking change
      });
      loading.present();
      this.feedbackSvc.postFeedback(feedback)
        .then(() => {
          this.feedbackForm.get('text').setValue('');
          loading.dismiss();
          this.hideForm();
        });

    });

  }

  async hideForm() {
    await this.viewCtrl.dismiss();
  }

}
