import { Component, Injector, Input } from '@angular/core';
import { PopoverController, SearchbarInputEventDetail } from '@ionic/angular';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { ActivityFilterComponent } from '../../activity-filter/activity-filter.component';
import { Filter_Shift } from 'src/data/Filters';
@Component({
	selector: 'app-filter-search-tablet',
	templateUrl: 'filter-search.component-tablet.html',
	styleUrls: ['./filter-search.component-tablet.scss'],
})
export class FilterSearchTabletComponent extends MTAContainerComponent {
	@Input()
	isCompanyRep: boolean;
	@Input()
	awaitingApprovalOnly: boolean;
	@Input()
	searchText: string;
	@Input()
	filters: typeof Filter_Shift;
	@Input()
	filterKeys: Array<string>;
	@Input()
	selectedShiftFilter: any;
	@Input()
	selectedStatusFilter: Array<string>;
	@Input()
	sortOrder: string;
	@Input()
	sortOrderOptions: Array<string>;
	@Input()
	selectedSearchType: Array<string>;
	@Input()
	itemStatus: (item, isCompanyRep) => any;
	@Input()
	pressChip: (value) => void;
	@Input()
	openFilter: (_) => void;
	@Input()
	getStyle: (value) => 'btn-highlighted' | 'btn-normal';
	@Input()
	goHome: () => void;
	@Input()
	sync: () => void;

	isSearchBarOpened = false;
	searchType = 'Highlight';
	sortOrderObjectOptions = [
		{ name: 'Wbs', selected: true },
		{ name: 'Priority', selected: false },
	];
	searchTypeObjectOptions = [
		{ name: 'Highlight', selected: true },
		{ name: 'Filter', selected: false },
	];

	constructor(
		public mtaPlatform: Injector,
		private popoverCtrl: PopoverController
	) {
		super(mtaPlatform);
	}

	changeApprovalflag() {
		this.awaitingApprovalOnly = !this.awaitingApprovalOnly;
		this.something.emit({ operation: 'changeApprovalFlag', params: null });
	}

	searchInput(evt: CustomEvent<SearchbarInputEventDetail>) {
		this.something.emit({ operation: 'searchInput', params: evt.detail.value });
	}

	changeShift(shift: string) {
		this.something.emit({
			operation: 'changeShift',
			params: this.selectedShiftFilter,
		});
	}

	changeFilter(data) {
		this.something.emit({ operation: 'changeFilter', params: data });
	}

	changeSortOrder() {
		this.something.emit({
			operation: 'changeSortOrder',
			params: this.sortOrder,
		});
	}

	async sortMenu(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: ActivityFilterComponent,
			cssClass: 'my-custom-class',
			event: ev,
			translucent: true,
			componentProps: {
				options: this.sortOrderObjectOptions,
			},
		});
		await popover.present();

		popover.onDidDismiss().then((res) => {
			if (res.data?.selectedOption) {
				this.sortOrder = res.data.selectedOption.name;
				this.changeSortOrder();
			}
		});
	}

	async selectMenu(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: ActivityFilterComponent,
			cssClass: 'my-custom-class',
			event: ev,
			translucent: true,
			componentProps: {
				options: this.searchTypeObjectOptions,
			},
		});
		await popover.present();

		popover.onDidDismiss().then((res) => {
			if (res.data?.selectedOption) {
				this.searchType = res.data.selectedOption.name;
				this.changeSearchType();
			}
		});
	}

	changeSearchType() {
		this.something.emit({
			operation: 'changeSearchType',
			params: this.searchType,
		});
	}
}
