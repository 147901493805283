import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { ActivityStartJustificationType } from 'src/data/EntityIndex';

@Component({
  selector: 'app-previous-justification-phone',
  templateUrl: 'previous-justifications.component-phone.html',
})
export class PreviousJustificationsPhoneComponent extends MTAContainerComponent {
  @Input()
  previousJustifications: Array<any>;
  @Input()
  justificationTypes: ActivityStartJustificationType[];
   
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
