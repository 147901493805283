import Dexie, { Table } from 'dexie';
import {
	Activity,
	ActivityPSRSnapshot,
	ActivityStartJustification,
	ActivityStartJustificationType,
	Crew,
	CrewAssignment,
	FlattenActivityCode,
	Justification,
	OBS,
	Project,
	ProjectConfig,
	Relationship,
	Resource,
	ResourceAssignment,
	Role,
	Shift,
	UDFCodeValue,
	User,
	WBS,
	WBSCategory,
} from 'src/data/EntityIndex';
import { SyncLog } from 'src/data/entities/SyncLog';

export interface TodoList {
	id?: number;
	title: string;
}
export interface TodoItem {
	id?: number;
	todoListId: number;
	title: string;
	done?: boolean;
}

export class AppDB extends Dexie {
	activity!: Table<Partial<Activity>, number>;
	activityPSRSnapshot!: Table<Partial<ActivityPSRSnapshot>, number>;
	activityStartJustification!: Table<
		Partial<ActivityStartJustification>,
		number
	>;
	activityStartJustificationType!: Table<
		Partial<ActivityStartJustificationType>,
		number
	>;
	crew!: Table<Partial<Crew>, number>;
	crewAssignment!: Table<Partial<CrewAssignment>, number>;
	flattenActivityCode!: Table<Partial<FlattenActivityCode>, number>;
	justification!: Table<Partial<Justification>, number>;
	obs!: Table<Partial<OBS>, number>;
	project!: Table<Partial<Project>, number>;
	projectConfig!: Table<Partial<ProjectConfig>, number>;
	relationship!: Table<Partial<Relationship>, number>;
	resource!: Table<Partial<Resource>, number>;
	resourceAssignment!: Table<Partial<ResourceAssignment>, number>;
	role!: Table<Partial<Role>, number>;
	shift!: Table<Partial<Shift>, number>;
	syncLog!: Table<Partial<SyncLog>, number>;
	udfCodeValue!: Table<Partial<UDFCodeValue>, number>;
	user!: Table<Partial<User>, number>;
	wbs!: Table<Partial<WBS>, number>;
	wbsCategory!: Table<Partial<WBSCategory>, number>;

	constructor() {
		super('tapro');
		this.version(1).stores({
			activity: '++Id, ActivityId, ProjectId, WbsId, Status, Type',
			activityPSRSnapshot: '++, &Id, ProjectId, ShiftNumber, ActivityId',
			activityStartJustification: 'Id, ActivityId, TypeId, UserUpn, CreatedById',
			activityStartJustificationType: '++Id, CreatedById, Enabled',
			crew: '++, CrewId, ProjectId, Name, Type',
			crewAssignment: '++, CrewId,  ProjectId, UserId',
			flattenActivityCode: '++, ActivityId, ProjectId, SupportCrewId, Ewo',
			justification: 'GlobalId, ActivityId, Type, UpdatedBy',
			obs: '++Id, Name, ParentId',
			project: '++, Id, ProjectId, ObsId, Status, WbsId, Name',
			projectConfig: '++, &Id, ProjectId, Type, ActivityCodeTypeId',
			relationship:
				'++, &Id, PredecessorProjectId, PredecessorActivityId, SuccessorActivityId, SuccessorProjectId',
			resource: '++Id',
			resourceAssignment: '++, ResourceId, ActivityId, ProjectId',
			role: '++Id, Name, Type',
			shift: '++, &Id, ProjectId, Name, Sequence',
			syncLog: '++, &Id, UserId, ProjectId',
			udfCodeValue: '++, ForeignId',
			user: 'Email, Id, Cai',
			wbs: '++, &Id, ProjectId, Status',
			wbsCategory: '++Id, Name, SequenceNumber',
		});
	}

	getTableName(entityName: string): string {
		let tableName = entityName[0].toLowerCase() + entityName.substring(1);
		if (tableName === 'wBSCategory') tableName = 'wbsCategory';
		else if (tableName === 'wBS') tableName = 'wbs';
		else if (tableName === 'oBS') tableName = 'obs';
		else if (tableName === 'uDFCodeValue') tableName = 'udfCodeValue';
		return tableName;
	}

	  // function to clear local db (for debugging / testing purposes)
  // returns success
  public async clearDataFromTables() {
    await db.transaction('rw', db.tables, async () => {
      // Iterate over each table in the database
      for (const table of db.tables) {
        await table.clear(); // Clear all data from the table
      }
    });
  }
}

export const db = new AppDB();
