import { MTABaseEntity } from './MTABaseEntity';
export class WBSCategory extends MTABaseEntity {
	Id: number;
	Name: string;
	SequenceNumber: number;
	CreatedBy: string;
	CreateDate: string;
	UpdatedBy: string;
	UpdatedDate: string;
	init(json: any): void {
		this.Id = json.Id;
		this.Name = json.Name;
		this.UpdatedBy = json.UpdatedBy;
		this.UpdatedDate = json.UpdatedDate;
		this.CreateDate = json.CreateDate;
		this.CreatedBy = json.CreatedBy;
		this.SequenceNumber = json.SequenceNumber;
	}
}
