import { Relationship } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';

export class RelationshipEntityManager extends BaseEntityManager {
  entityType = Relationship;
	entityTableName = 'relationship';

  async insertRelationShipData(relationShips: Relationship[]) {
    await this.insertData(relationShips);
  }

  async insertorUpdateRelationShipData(relationShips: Relationship[]) {
    await this.insertOrUpdateData(relationShips);
  }
}
