import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ResourceListPhoneComponent } from './phone/resource-list.component-phone';
import { ResourceListTabletComponent } from './tablet/resource-list.component-tablet';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent extends MTAContainerComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() resources: any;
  @Output() getResources: EventEmitter<null> = new EventEmitter();
  showResources = false;
  constructor(public injector: Injector) {
    super(injector);
   }

   ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'resources') {
        if (!changes.resources.isFirstChange()) {
          const change = changes[propName];
          this.resources = change.currentValue;
          this.inputs.resources = this.resources;
          this.inputs.showResources = this.showResources;
        }
      }

    }
  }

  ngOnInit() {
    this.subComponentJson = { tablet: ResourceListTabletComponent, phone: ResourceListPhoneComponent};
    this.subComponent = this.getComponentType();
    this.inputs = {
      title: this.title,
      showResources: this.showResources,
      resources: this.resources
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        if(opt === 'getResources')
          this.toggleResources();
      }
    };
  }

  toggleResources() {
    this.showResources = !this.showResources;
    if (this.showResources) {
      this.getResources.emit(null);
    }
  }

}
