import { Component, Injector, Input } from '@angular/core';
import { ActivityInternalStatus } from '../../../data/ActivityInternalStatus';
import { Activity } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { MTAUtility } from '../../../shared/utility';

@Component({
  selector: 'app-activity-cell-phone',
  styleUrls: ['./activity-cell.component-phone.scss'],
  templateUrl: 'activity-cell.component-phone.html'
})
export class ActivityCellPhoneComponent extends MTAContainerComponent {

  @Input()
  activity: Activity;
  @Input()
  mainCrewId: string
  @Input()
  supportCrewId: string
  @Input()
  crewsIdStr: string
  @Input()
  isCompanyRep:boolean;
  @Input()
  getActivityStatusImg: () => string;
  @Input()
  getClassByStatus: () =>  string;
  @Input()
  isTranslatedActivity: boolean;
  
  status: ActivityInternalStatus;
  tempInternalPercentComplete = -1;
  switch = true;
  completeButtonText = 'COMPLETE';

  constructor(public injector: Injector) {
    super(injector);
  }

  viewActivity() {
    this.something.emit({ operation: 'viewActivity', params: null });
  }

  isDisabled(activity) {
    if (!activity.isReadOnly) {
      if (activity.InternalPercentComplete === MTAUtility.getRoundedMinValue(activity.PercentComplete)) {
        return true;
      } else { return false; }
    } else { return true; }
  }

  updateProgress(activity) {
    this.something.emit({ operation: 'updateProgress', params: activity });
  }

  undoStatusIOS(activity) {
    if (activity.InternalPercentComplete === MTAUtility.getRoundedMinValue(activity.PercentComplete)) {
      this.something.emit({ operation: 'changeStatus', params: null });
    } else {
      this.something.emit({ operation: 'changeStatus', params: ActivityInternalStatus.Updated });
    }
  }

  rejectActivityIOS() {
    this.something.emit({ operation: 'changeStatus', params: 'Rejected' });
  }

  approveActivityIOS() {
    this.something.emit({ operation: 'changeStatus', params: 'Approved' });
  }

  // change based on the Internal Percentage value:
  increment(activity) {
    if (!activity.isReadOnly) {
      if (this.tempInternalPercentComplete === -1) {
        this.tempInternalPercentComplete = this.activity.InternalPercentComplete;
      }

      if (activity.InternalPercentComplete < 100) {
        activity.InternalPercentComplete = activity.InternalPercentComplete + 10;
        this.updateProgress(activity);
      }
    }
  }

  decrement(activity) {
    if (!activity.isReadOnly) {
      if (this.tempInternalPercentComplete === -1) {
        this.tempInternalPercentComplete = this.activity.InternalPercentComplete;
      }
      if (activity.InternalPercentComplete > this.tempInternalPercentComplete) { // acc to what we had planned in the prev meet
        activity.InternalPercentComplete = activity.InternalPercentComplete - 10;
        this.updateProgress(activity);
      }
    }
  }

  completeSingleTask(activity) {
    if (this.tempInternalPercentComplete === -1) {
      this.tempInternalPercentComplete = this.activity.InternalPercentComplete;
    }
    if (this.switch) {
      this.activity.InternalPercentComplete = 100;
      this.completeButtonText = 'RESTORE';
      this.updateProgress(activity);
    }
    else {
      this.activity.InternalPercentComplete = this.tempInternalPercentComplete;
      this.completeButtonText = 'COMPLETE';
      this.updateProgress(activity);
    }
    this.switch = !this.switch;
  }

}
