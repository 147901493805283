import { Component, Injector, Input } from '@angular/core';
import { ActivityInternalStatus } from '../../../data/ActivityInternalStatus';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
  selector: 'app-tri-toggle-phone',
  templateUrl: 'tri-toggle.component-phone.html'
})
export class TriTogglePhoneComponent extends MTAContainerComponent {

	@Input()
	status: ActivityInternalStatus;
	@Input()
	disabled: boolean;
	@Input()
	getStyle: () => Array<string>;

  style: string;
  constructor(public injector: Injector) {
    super(injector);
  }

  onClick() {
    this.something.emit({ operation: 'onClick', params: null });
  }
  onTogglePan(e) {
    const originStatus = this.status;
    const changeX = e.deltaX;
    if (changeX > 0) {
      if ((this.status === ActivityInternalStatus.Rejected && changeX > 20) || ((this.status === ActivityInternalStatus.Pending || this.status === ActivityInternalStatus.Updated) && changeX > 12)) {
        this.status = ActivityInternalStatus.Approved;
      }
    } else if (changeX < 0) {
      if ((this.status === ActivityInternalStatus.Approved && Math.abs(changeX) > 20) || ((this.status === ActivityInternalStatus.Pending || this.status === ActivityInternalStatus.Updated) && Math.abs(changeX) > 12)) {
        this.status = ActivityInternalStatus.Rejected;
      }
      if (this.status !== originStatus) {
        this.something.emit({ operation: 'onTogglePan', params: this.status });
      }
    }
  }
}
