import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
  selector: 'app-resource-list-tablet',
  styleUrls: ['resource-list.component-tablet.scss'],
  templateUrl: 'resource-list.component-tablet.html'
})
export class ResourceListTabletComponent extends MTAContainerComponent {
	@Input()
	title: string;
	@Input()
	showResources: boolean;
	@Input()
	resources: Array<any>;
  
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

  toggleResources() {
    this.showResources = !this.showResources;
    if (this.showResources) {
      this.something.emit({ operation: 'getResources', params: null });
    }
  }
}
