import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ActivityInternalStatus } from '../../data/ActivityInternalStatus';
import { TriTogglePhoneComponent } from './phone/tri-toggle.component-phone';
import { TriToggleTabletComponent } from './tablet/tri-toggle.component-tablet';


@Component({
	selector: 'app-tri-toggle',
	templateUrl: './tri-toggle.component.html',
	styleUrls: ['./tri-toggle.component.scss'],
})
export class TriToggleComponent
	extends MTAContainerComponent
	implements OnInit
{
	private touchStartX: number;
	private statusAtTouchStart: ActivityInternalStatus;

	@Input() status: ActivityInternalStatus;
	@Input() disabled = false;
	@Output() changedStatus: EventEmitter<ActivityInternalStatus> =
		new EventEmitter();
	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		if (this.status === null) {
			this.status = ActivityInternalStatus.Pending;
		}

		this.subComponentJson = {
			tablet: TriToggleTabletComponent,
			phone: TriTogglePhoneComponent,
		};
		this.subComponent = this.getComponentType();
		this.inputs = {
			status: this.status,
			disabled: this.disabled,
			getStyle: () => {
				return this.getStyle();
			},
		};
		this.outputs = {
			something: (data) => {
				const opt = data.operation;
				const params = data.params;
				switch (opt) {
					case 'onClick':
					case 'onTogglePan':
						this.changeStatus(params);
						break;
				}
			},
		};
	}

	getStyle(): any {
		if (this.status === ActivityInternalStatus.Approved) {
			return ['true'];
		} else if (this.status === ActivityInternalStatus.Rejected) {
			return ['false'];
		} else if (this.status === ActivityInternalStatus.Updated) {
			return ['null'];
		} else {
			// Pending
			return ['null'];
		}
	}

	onToggleTouch(e) {
		this.touchStartX = e.touches[0].pageX;
		this.statusAtTouchStart = this.status;
	}

	onToggleMove(e) {
		const positionX: number = Number(e.touches[0].pageX);
		const changeX = positionX - this.touchStartX;
		const widgetWidth = e.target.clientWidth;
		if (this.statusAtTouchStart === ActivityInternalStatus.Rejected) {
			if (changeX > widgetWidth / 4 && changeX < widgetWidth / 2)
				this.status = ActivityInternalStatus.Pending;
			else if (changeX > (3 * widgetWidth) / 4)
				this.status = ActivityInternalStatus.Approved;
		} else if (
			this.statusAtTouchStart === ActivityInternalStatus.Pending &&
			changeX > widgetWidth / 4
		) {
			this.status = ActivityInternalStatus.Approved;
		} else if (changeX < 0) {
			const newStatus = this._getStatusWhenChangeLessThan0(changeX, widgetWidth, this.status);
			if (newStatus !== null) {
				this.status = newStatus;
			}
		}
		this.changeStatus(this.status);
	}

	private _getStatusWhenChangeLessThan0(changeX: number, widgetWidth: number, status: ActivityInternalStatus): ActivityInternalStatus{
		if (status === ActivityInternalStatus.Approved) {
			if (Math.abs(changeX) < widgetWidth / 2)
				return  ActivityInternalStatus.Pending;
			else if (Math.abs(changeX) > (3 * widgetWidth) / 4)
				return ActivityInternalStatus.Rejected;
		} else if (
			this.status === ActivityInternalStatus.Pending &&
			Math.abs(changeX) > widgetWidth / 4
		) {
			return ActivityInternalStatus.Rejected;
		}
		return null;
	}

	onToggleRelease(e) {
		this.touchStartX = -1;
		this.statusAtTouchStart = ActivityInternalStatus.Pending;
		this.changeStatus(this.status);
	}

	onToggleSwipe(e) {
		const changeX = e.deltaX;
		if (changeX > 10) {
			if (this.status === ActivityInternalStatus.Rejected)
				this.status = ActivityInternalStatus.Pending;
			else if (this.status === ActivityInternalStatus.Pending)
				this.status = ActivityInternalStatus.Approved;
		} else if (
			changeX > 20 &&
			this.status === ActivityInternalStatus.Rejected
		) {
			this.status = ActivityInternalStatus.Approved;
		} else if (changeX < 0) {
			// moving left
			if (this.status === ActivityInternalStatus.Approved) {
				if (Math.abs(changeX) < 10)
					this.status = ActivityInternalStatus.Pending;
				else if (Math.abs(changeX) > 20)
					this.status = ActivityInternalStatus.Rejected;
			} else if (
				this.status === ActivityInternalStatus.Pending &&
				Math.abs(changeX) > 10
			) {
				this.status = ActivityInternalStatus.Rejected;
			}
		}
		this.changeStatus(this.status);
	}

	onTogglePan(e) {
		const originStatus = this.status;
		const changeX = e.deltaX;
		if (changeX > 0) {
			// moving right
			if ((this.status === ActivityInternalStatus.Rejected && changeX > 20) || (
				(this.status === ActivityInternalStatus.Pending ||
					this.status === ActivityInternalStatus.Updated) &&
				changeX > 12
			)) {
				this.status = ActivityInternalStatus.Approved;
			}
		} else if (changeX < 0) {
			// oving left
			if ((
				this.status === ActivityInternalStatus.Approved &&
				Math.abs(changeX) > 20) || (
					(this.status === ActivityInternalStatus.Pending ||
						this.status === ActivityInternalStatus.Updated) &&
					Math.abs(changeX) > 12
				)
			) {
				this.status = ActivityInternalStatus.Rejected;
			}
			if (this.status !== originStatus) {
				this.changeStatus(this.status);
			}
		}
	}

	changeStatus(status: any) {
		this.changedStatus.emit(status);
	}

	resetActivityStatus(status: string) {
		this.status = ActivityInternalStatus[status];
	}
}
