import { Pipe, PipeTransform } from '@angular/core';
import { ActivityInternalStatus } from 'src/data/ActivityInternalStatus';

/**
 * Generated class for the StatusForChipPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'StatusForChipPipe'
})
export class StatusForChipPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    const isCompanyRep = args[0];
    switch (value) {
      case ActivityInternalStatus.Approved:
        value = 'Approved';
        break;
      case ActivityInternalStatus.CantWork:
        value = `Can't Work`;
        break;
      case ActivityInternalStatus.Completed:
        value = 'Completed';
        break;
      case ActivityInternalStatus.NoUpdate:
        value = 'Pending Status';
        break;
      case ActivityInternalStatus.Pending:
        value = 'Pending Status';
        break;
      case ActivityInternalStatus.Rejected:
        value = 'Rejected';
        break;
      case ActivityInternalStatus.Updated:
        value = isCompanyRep ? 'Awaiting Approval' : 'Updated';
        break;
    }
    return value;
  }
}
