import { MTAErrorType } from './InternalTypes';

export class MTAError {
  public type: MTAErrorType;
  public params: any;
  public details: any;
  public isPopup: boolean;
  constructor(type: MTAErrorType, params: any, details?: any, isPopup?: boolean) {
    this.type = type;
    this.params = params;
    this.details = details;
    this.isPopup = isPopup;
  }
}
