import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { ShiftEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class ShiftServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = ShiftEntityManager;

  public serviceName = 'shifts';
  public getOptions(config?: SyncConfig): { headers: any; params: any } {
    const opt = super.getOptions(config);
    if (config) {
      opt.params = opt.params.set('projectId', config.project + '');
      if (config.lastDownloadTime) {
        opt.params = opt.params.delete('LastUpdateDate');
        opt.params = opt.params.set('updatedDate', config.lastDownloadTime);
      }
    }

    return opt;
  }

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }
  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
