import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { User } from "src/data/EntityIndex";
import { environment } from "src/environments/environment";
import { ADALProvider } from "./adal/adal";
import { ADALModel } from "./adal/adal-model";
import { LoadingControllerHandler } from "./loading-controller-handler";
import { NetworkService } from "src/services/network.service";
import { SharedPlatformProvider } from "../shared/platform";

@Injectable()
export class LoginProvider {
  private adToken: string;
  private _role: string;
  get role(): string {
    return this._role;
  }
  set role(value: string) {
    this._role = value;
  }

  constructor(
    private http: HttpClient,
    private adalService: ADALProvider,
    private alert: AlertController,
    //  private platform: Platform,
    public platform: SharedPlatformProvider,
    private loadingControllerHandler: LoadingControllerHandler,
    private network: NetworkService
  ) {}

  getToken() {
    if (this.adToken === undefined || this.adToken === null) {
      this.adToken = localStorage.getItem("Token");
    }
    return this.adToken;
  }

  async loginWithAD(): Promise<User> {
    //return new Promise<User>(async (resolve, reject) => {
    return this.validTokenWithCurrentAPI(this.adToken)
      .then((user) => {
        return user;
      })
      .catch((error) => {
        throw "Current User Failed: " + JSON.stringify(error);
      });

    //});
  }

  saveMSALToken(token: string, expiresOn: string) {
    localStorage.setItem("Token", token);
    localStorage.setItem("ExpiresOn", expiresOn);
  }

  async saveMSALUser(
    lastName: string,
    firstName: string,
    cai: string,
    userId: string
  ) {
    localStorage.setItem("LastLogin", "" + new Date().getTime());
    localStorage.setItem("LastName", lastName);
    localStorage.setItem("FirstName", firstName);
    localStorage.setItem("CAI", cai);
    localStorage.setItem("UserId", userId);
  }

  private async clearLoginInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        localStorage.clear();
        localStorage.removeItem("Token");
        localStorage.removeItem("ExpiresOn");
        localStorage.removeItem("Claims_Token");
        localStorage.removeItem("LastLogin");
        localStorage.removeItem("LastName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("CAI");
        localStorage.removeItem("UserId");
        localStorage.removeItem("UserType");
        localStorage.removeItem("UserDetails");
        resolve();
      } catch (error) {
        reject("clear storage failed: " + JSON.stringify(error));
      }
    });
  }

  getUser(): User {
    const firstName = localStorage.getItem("FirstName");
    const lastName = localStorage.getItem("LastName");
    const uid = localStorage.getItem("UserId");
    const cai = localStorage.getItem("CAI");
    if (firstName === null || lastName === null || uid === null) {
      return null;
    } else {
      const user: User = new User();
      user.FirstName = firstName;
      user.LastName = lastName;
      user.Id = parseInt(uid, 10);
      user.Cai = cai;
      return user;
    }
  }

  isTokenExpired(): boolean {
    const expiresOn = parseInt(localStorage.getItem("ExpiresOn"), 10);
    let ret = true;
    if (!isNaN(expiresOn) && Date.now() < expiresOn * 1000) {
      ret = false;
    }

    return ret;
  }

  getHeaders(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders()
      .set("Authorization", "Bearer " + this.adToken)
      .set("Content-Type", "application/json");
    return headers;
  }

  validTokenWithCurrentAPI(adToken: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + adToken)
          .set("Content-Type", "application/json")
          .set("timeout", "180000"),
      };
      this.http
        .get(environment.BaseAPI + "/user/current", httpOptions)
        .subscribe(
          (usr: User) => {
            resolve(usr);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          }
        );
    });
  }

  wasUserLoggedin(): boolean {
    return localStorage.getItem("Token") ? true : false;
  }

  async logOut(): Promise<void> {
    await this.adalService.clearTokenCache();
    this.clearLoginInfo();
    return;
  }

  login(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      try {
        if (this.wasUserLoggedin()) {
          this.silentLogin()
            .then((user) => resolve(user))
            .catch((error) => reject(error));
        } else {
          this.interactiveLogin()
            .then((user) => resolve(user))
            .catch((error) => reject(error));
        }
      } catch (error) {
        reject("Authentication failed: " + JSON.stringify(error));
      }
    });
  }

  interactiveLogin(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      try {
        const isMobile = this.platform.isMobile();
        if (!isMobile) {
          this.loginWithAD()
            .then((user) => {
              resolve(user);
            })
            .catch((error) =>
              reject("AD Login Failed" + JSON.stringify(error))
            );
        }
      } catch (error) {
        reject("Authentication failed");
      }
    });
  }

  saveUserDetails(username: string, password: string) {
    localStorage.setItem("username", username);
    localStorage.setItem("password", password);
  }

  async silentLogin(): Promise<User> {
    try {
      try {
        const user = await this.loginWithAD();
        return user;
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw "Silent Login failed: " + JSON.stringify(error);
    }
  }
}
