import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { MTAErrorType } from 'src/data/InternalTypes';
import { MTAError } from 'src/data/MTAError';
import { AnalyticsProvider } from 'src/shared/analytics/analytics';
import { AppVersionProvider } from 'src/shared/appVersion';
import { LoginProvider } from 'src/shared/login';
import { DbService } from '../db.service';
import { SyncProgress } from './sync-progress';
import { SyncManager } from './SyncManager';

@Injectable()
export class SyncProvider {
  constructor(
    private dm: DbService,
    public http: HttpClient,
    private toast: ToastController,
    private analytics: AnalyticsProvider,
    private loginProvider: LoginProvider,
    //private backgroundMode: BackgroundMode,
    //private network: Network,
    private appVersionProvider: AppVersionProvider,
    private sm: SyncManager
  ) {}

  public sync(syncProgress: SyncProgress, projectId?: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.sm.sync(projectId).then(
        async () => {
          if (syncProgress && syncProgress.complete) {
            syncProgress.complete();
          }
          resolve();
        },
        (err) => {
          if (err instanceof MTAError && err.type === MTAErrorType.MULTIPLE_PROJECTS_ASSIGNED) {
            syncProgress.multipleProjectsAssigned(err.params); // params is projects data from service api
          } else {
            if (syncProgress && syncProgress.error) {
              syncProgress.error(err);
            }
            reject(err);
          }
        },
      );
    });
  }

  public hasOfflineData(projectId?: number): Promise<Set<number>> {
    return this.sm.hasOfflineData(projectId);
  }
}
