// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  display: flex;
  justify-content: center;
}

img {
  max-width: 15%;
  max-height: 50%;
  min-width: 150px;
  object-fit: contain;
  padding-bottom: 10px;
}

@media (max-width: 576px) {
  h1 {
    font-size: 2em;
  }
}
@media (max-width: 820px) and (min-width: 576px) {
  h1 {
    font-size: 2.3em;
  }
}
@media (max-width: 1024px) and (min-width: 820px) {
  h1 {
    font-size: 3.3em;
  }
}
.title-ios-short {
  font-family: "Gotham-Bold";
  font-size: 2em;
  text-align: center;
  color: #751669;
}`, "",{"version":3,"sources":["webpack://./src/components/brand/phone/brand.component-phone.scss","webpack://./colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AADF;;AAIA;EACE;IACE,cAAA;EADF;AACF;AAIA;EACE;IACE,gBAAA;EAFF;AACF;AAKA;EACE;IACE,gBAAA;EAHF;AACF;AAMA;EACE,0BAAA;EACA,cAAA;EACA,kBAAA;EACA,cCrCe;ADiCjB","sourcesContent":["@import './../../../../colors.scss';\n\n.logo {\n  display: flex;\n  justify-content: center;\n}\n\nimg {\n  max-width: 15%;\n  max-height: 50%;\n  min-width: 150px;\n  object-fit: contain;\n  padding-bottom: 10px;\n}\n\n@media (max-width: 576px) {\n  h1 {\n    font-size: 2em;\n  }\n}\n\n@media (max-width: 820px) and (min-width: 576px) {\n  h1 {\n    font-size: 2.3em;\n  }\n}\n\n@media (max-width: 1024px) and (min-width: 820px) {\n  h1 {\n    font-size: 3.3em;\n  }\n}\n\n.title-ios-short {\n  font-family: 'Gotham-Bold';\n  font-size: 2em;\n  text-align: center;\n  color: $darkish-purple;\n}\n","$darkish-purple: #751669;\n$white-two: #e5e5e5;\n$greyish-brown: #4a4a4a;\n$white-three: #ebebeb;\n$sun-yellow: #f8e71c;\n$pinkish-grey: #cbcbcb;\n$white-four: #e2e2e2;\n$white-five: #d8d8d8;\n$greyish-brown-two: #575757;\n$warm-grey: #979797;\n$white-six: #f4f4f4;\n$black-33: rgba(0, 0, 0, 0.33);\n$white-seven: #eeeeee;\n$scarlet: #d0021b;\n$warm-purple: #ba3093;\n$apple-green: #7ed321;\n$pale-grey: #dad8da;\n$eggplant: #3a0d36;\n$slate-grey: #6b6d6f;\n$white-eight: #ececec;\n$avocado-green: #65a420;\n$tomato: #e5601f;\n$greyish: #ababab;\n$brownish-grey: #666666;\n$white: white;\n$black: black;\n$greyish-two: #a9a9a9;\n$white-nine: #ededed;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
