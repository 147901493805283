import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Justification } from 'src/data/EntityIndex';
import { CommentType, CommentTypeDescription } from 'src/data/InternalTypes';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { CommentsPhoneComponent } from './phone/comments.component-phone';
import { CommentsTabletComponent } from './tablet/comments.component-tablet';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends MTAContainerComponent implements OnInit, OnChanges {
  @Input() comments: Justification[];

  constructor(injector: Injector, public translate: TranslateService) {
    super(injector);
    this.inputs = {};
   }

  ngOnInit() {
    this.subComponentJson = { tablet: CommentsTabletComponent, phone: CommentsPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      comments: this.comments,
      getCommentType: value => {
        return this.getCommentType(value);
      }
    };
    this.outputs = {};
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'comments') {
        const change = changes[propName];
        this.comments = change.currentValue;
        this.inputs.comments = this.comments;
      }
    }
  }

  getCommentType(commentType: CommentType): CommentTypeDescription {
    switch (commentType) {
      case CommentType.ActivityComment: {
        return this.translate.instant('COMMENT.ActivityComment');
      }
      case CommentType.ProjectedEndDate: {
        return this.translate.instant('COMMENT.ProjectedEndDate');
      }
      case CommentType.RejectComment: {
        return this.translate.instant('COMMENT.RejectComment');
      }
      case CommentType.SchedulerReject: {
        return this.translate.instant('COMMENT.SchedulerReject');
      }
      case CommentType.SchedulerOverride: {
        return this.translate.instant('COMMENT.SchedulerOverride');
      }
      default: {
        return this.translate.instant('COMMENT.ActivityComment');
      }
    }
  }

}
