import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable, IUploadable } from 'src/services/sync/SyncInterface';
import { ActivityStartJustificationEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class ActivityStartJustificationServiceProvider extends BaseServiceProvider implements IDownloadable, IUploadable {
  public _entityManger = ActivityStartJustificationEntityManager;

  public serviceName = 'activityStartJustifications';
  public currentVersionForDownload = '1.0';
  public currentVersionForUpload = '2.0';

  public justificationsForUpload: any[];

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }

  public getUploadUrl(): string {
    if (this.isUploadable) {
      return `${this._baseURL}/${this.serviceName}`;
    } else {
      return null;
    }
  }

  public getOptions(config?: SyncConfig): { headers: any; params: any } {
    const opt = super.getOptions(config);
    if (config) {
      opt.params = opt.params.set('projectId', config.project + '');
    }
    return opt;
  }

  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }

  public async upload(config: SyncConfig): Promise<any> {
   // return new Promise<any>(async (resolve, reject) => {
      try {
        const em = new this._entityManger();
        this.justificationsForUpload = await em.getDeltaData(config);
        if (this.justificationsForUpload && this.justificationsForUpload.length > 0) {
          return(super.upload_base(config));
        } else {
          return(null);
        }
      } catch (e) {
        throw(e);
      }
  //  });
  }

  getBodyForUpload(config: SyncConfig): any {
    let justifications = [];
    if (this.justificationsForUpload && this.justificationsForUpload.length > 0) {
      justifications = this.justificationsForUpload;
    }
    const body = {
      SyncId: config.syncId,
      ActivityStartJustifications: justifications,
    };
    return body;
  }
}
