import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { CrewAssignmentEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class CrewAssignmentServiceProvider extends BaseServiceProvider implements IDownloadable {
  _entityManger = CrewAssignmentEntityManager;

  serviceName = 'crewassignment';

  getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${config.project}`;
    } else {
      return null;
    }
  }

  download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
