import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DynamicModule } from 'ng-dynamic-component';
import { SliderModule } from 'primeng/slider';
import { SharedModule } from 'src/shared/shared.module';
import { PipesModule } from './../pipes/pipes.module';
import { ActivityActionsComponent } from './activity-actions/activity-actions.component';
import { ActivityActionsPhoneComponent } from './activity-actions/phone/activity-actions.component-phone';
import { ActivityActionsTabletComponent } from './activity-actions/tablet/activity-actions.component-tablet';
import { ActivityActualStartFinishModalComponent } from './activity-actual-start-finish-modal/activity-actual-start-finish-modal.component';
import { ActivityActualStartFinishComponent } from './activity-actual-start-finish/activity-actual-start-finish.component';
import { ActivityActualStartFinishPhoneComponent } from './activity-actual-start-finish/phone/activity-actual-start-finish.component-phone';
import { ActivityActualStartFinishTabletComponent } from './activity-actual-start-finish/tablet/activity-actual-start-finish.component-tablet';
import { ActivityCellComponent } from './activity-cell/activity-cell.component';
import { ActivityCellPhoneComponent } from './activity-cell/phone/activity-cell.component-phone';
import { ActivityCellTabletComponent } from './activity-cell/tablet/activity-cell.component-tablet';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { ActivityDetailPhoneComponent } from './activity-detail/phone/activity-detail.component-phone';
import { ActivityDetailTabletComponent } from './activity-detail/tablet/activity-detail.component-tablet';
import { ActivityFilterComponent } from './activity-filter/activity-filter.component';
import { BrandComponent } from './brand/brand.component';
import { BrandPhoneComponent } from './brand/phone/brand.component-phone';
import { BrandTabletComponent } from './brand/tablet/brand.component-tablet';
import { CommentsComponent } from './comments/comments.component';
import { CommentsPhoneComponent } from './comments/phone/comments.component-phone';
import { CommentsTabletComponent } from './comments/tablet/comments.component-tablet';
import { DependencyListCellComponent } from './dependency-list-cell/dependency-list-cell.component';
import { DependencyListCellPhoneComponent } from './dependency-list-cell/phone/dependency-list-cell.component-phone';
import { DependencyListCellTabletComponent } from './dependency-list-cell/tablet/dependency-list-cell.component-tablet';
import { DependencyListComponent } from './dependency-list/dependency-list.component';
import { DependencyListPhoneComponent } from './dependency-list/phone/dependency-list.component-phone';
import { DependencyListTabletComponent } from './dependency-list/tablet/dependency-list.component-tablet';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { FilterSearchPhoneComponent } from './filter-search/phone/filter-search.component-phone';
import { FilterSearchTabletComponent } from './filter-search/tablet/filter-search.component-tablet';
import { JustificationCellComponent } from './justification-cell/justification-cell.component';
import { JustificationCellPhoneComponent } from './justification-cell/phone/justification-cell.component-phone';
import { JustificationCellTabletComponent } from './justification-cell/tablet/justification-cell.component-tablet';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PercentageBarModalComponent } from './percentage-bar-modal/percentage-bar-modal.component';
import { PercentageBarModalPhoneComponent } from './percentage-bar-modal/phone/percentage-bar-modal.component-phone';
import { PercentageBarModalTabletComponent } from './percentage-bar-modal/tablet/percentage-bar-modal.component-tablet';
import { PercentageBarComponent } from './percentage-bar/percentage-bar.component';
import { PercentageBarPhoneComponent } from './percentage-bar/phone/percentage-bar.component-phone';
import { PercentageBarTabletComponent } from './percentage-bar/tablet/percentage-bar.component-tablet';
import { PreviousJustificationsPhoneComponent } from './previous-justification/phone/previous-justifications.component-phone';
import { PreviousJustificationComponent } from './previous-justification/previous-justification.component';
import { PreviousJustificationsTabletComponent } from './previous-justification/tablet/previous-justifications.component-tablet';
import { ResourceCellPhoneComponent } from './resource-cell/phone/resource-cell.component-phone';
import { ResourceCellComponent } from './resource-cell/resource-cell.component';
import { ResourceCellTabletComponent } from './resource-cell/tablet/resource-cell.component-tablet';
import { ResourceListPhoneComponent } from './resource-list/phone/resource-list.component-phone';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { ResourceListTabletComponent } from './resource-list/tablet/resource-list.component-tablet';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { TriTogglePhoneComponent } from './tri-toggle/phone/tri-toggle.component-phone';
import { TriToggleTabletComponent } from './tri-toggle/tablet/tri-toggle.component-tablet';
import { TriToggleComponent } from './tri-toggle/tri-toggle.component';
import { UtilCalendarPhoneComponent } from './util-calendar/phone/util-calendar.component-phone';
import { UtilCalendarTabletComponent } from './util-calendar/tablet/util-calendar.component-tablet';
import { UtilCalendarComponent } from './util-calendar/util-calendar.component';
import { WBSPhoneComponent } from './wbs/phone/wbs.component-phone';
import { WBSTabletComponent } from './wbs/tablet/wbs.component-tablet';
import { WbsComponent } from './wbs/wbs.component';
import { LookaheadWBSPhoneComponent } from './lookahead-wbs/phone/lookahead-wbs.component-phone';
import { LookaheadWBSTabletComponent } from './lookahead-wbs/tablet/lookahead-wbs.component-tablet';
import { LookaheadWbsComponent } from './lookahead-wbs/lookahead-wbs.component';




@NgModule({
  declarations: [
    ActivityActionsTabletComponent,
    ActivityActionsPhoneComponent,
    ActivityActionsComponent,
    ActivityActualStartFinishComponent,
    ActivityActualStartFinishPhoneComponent,
    ActivityActualStartFinishTabletComponent,
    ActivityActualStartFinishModalComponent,
    ActivityCellPhoneComponent,
    ActivityCellTabletComponent,
    ActivityCellComponent,
    ActivityDetailTabletComponent,
    ActivityDetailPhoneComponent,
    ActivityDetailComponent,
    ActivityFilterComponent,
    BrandTabletComponent,
    BrandPhoneComponent,
    BrandComponent,
    CommentsTabletComponent,
    CommentsPhoneComponent,
    CommentsComponent,
    DependencyListTabletComponent,
    DependencyListPhoneComponent,
    DependencyListComponent,
    DependencyListCellTabletComponent,
    DependencyListCellPhoneComponent,
    DependencyListCellComponent,
    FeedbackModalComponent,
    FilterSearchTabletComponent,
    FilterSearchPhoneComponent,
    FilterSearchComponent,
    JustificationCellTabletComponent,
    JustificationCellPhoneComponent,
    JustificationCellComponent,
    PageHeaderComponent,
    PercentageBarTabletComponent,
    PercentageBarPhoneComponent,
    PercentageBarComponent,
    PercentageBarModalTabletComponent,
    PercentageBarModalPhoneComponent,
    PercentageBarModalComponent,
    PreviousJustificationComponent,
    PreviousJustificationsTabletComponent,
    PreviousJustificationsPhoneComponent,
    ResourceCellTabletComponent,
    ResourceCellPhoneComponent,
    ResourceCellComponent,
    ResourceListTabletComponent,
    ResourceListPhoneComponent,
    ResourceListComponent,
    StatusFilterComponent,
    TriToggleTabletComponent,
    TriTogglePhoneComponent,
    TriToggleComponent,
    UtilCalendarTabletComponent,
    UtilCalendarPhoneComponent,
    UtilCalendarComponent,
    WBSTabletComponent,
    WBSPhoneComponent,
    WbsComponent,
    LookaheadWBSPhoneComponent,
    LookaheadWBSTabletComponent,
    LookaheadWbsComponent
  ],
  imports: [IonicModule, DynamicModule, CommonModule, FormsModule, PipesModule, SliderModule, ReactiveFormsModule, SharedModule],
  exports: [
    ActivityActionsTabletComponent,
    ActivityActionsPhoneComponent,
    ActivityActionsComponent,
    ActivityActualStartFinishModalComponent,
    ActivityActualStartFinishComponent,
    ActivityActualStartFinishPhoneComponent,
    ActivityActualStartFinishTabletComponent,
    ActivityCellPhoneComponent,
    ActivityCellTabletComponent,
    ActivityCellComponent,
    ActivityDetailTabletComponent,
    ActivityDetailPhoneComponent,
    ActivityDetailComponent,
    BrandTabletComponent,
    BrandPhoneComponent,
    BrandComponent,
    CommentsTabletComponent,
    CommentsPhoneComponent,
    CommentsComponent,
    DependencyListTabletComponent,
    DependencyListPhoneComponent,
    DependencyListComponent,
    DependencyListCellTabletComponent,
    DependencyListCellPhoneComponent,
    DependencyListCellComponent,
    FeedbackModalComponent,
    FilterSearchTabletComponent,
    FilterSearchPhoneComponent,
    FilterSearchComponent,
    JustificationCellTabletComponent,
    JustificationCellPhoneComponent,
    JustificationCellComponent,
    PageHeaderComponent,
    PercentageBarTabletComponent,
    PercentageBarPhoneComponent,
    PercentageBarComponent,
    PercentageBarModalTabletComponent,
    PercentageBarModalPhoneComponent,
    PercentageBarModalComponent,
    PreviousJustificationComponent,
    PreviousJustificationsTabletComponent,
    PreviousJustificationsPhoneComponent,
    ResourceCellTabletComponent,
    ResourceCellPhoneComponent,
    ResourceCellComponent,
    ResourceListTabletComponent,
    ResourceListPhoneComponent,
    ResourceListComponent,
    StatusFilterComponent,
    TriToggleTabletComponent,
    TriTogglePhoneComponent,
    TriToggleComponent,
    UtilCalendarTabletComponent,
    UtilCalendarPhoneComponent,
    UtilCalendarComponent,
    WBSTabletComponent,
    WBSPhoneComponent,
    WbsComponent,
    LookaheadWBSPhoneComponent,
    LookaheadWBSTabletComponent,
    LookaheadWbsComponent
  ],
})
export class ComponentsModule {}
