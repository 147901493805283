import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Activity } from 'src/data/EntityIndex';

@Component({
	selector: 'app-activity-detail-phone',
	styleUrls: ['./activity-detail.component-phone.scss'],
	templateUrl: 'activity-detail.component-phone.html',
})
export class ActivityDetailPhoneComponent extends MTAContainerComponent {
	@Input() activity: Activity;
	showActivities = false;
	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}
	toggleActivities() {
		this.showActivities = !this.showActivities;
	}
}
