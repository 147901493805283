import { MTABaseEntity } from './MTABaseEntity';

export class Role extends MTABaseEntity {
  Id: number;
  Type: string;
  Name: string;
  CreatedBy: string;
  CreateDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
  IsDeleted: boolean;
  init(json: any): void {
    this.IsDeleted = json.IsDeleted;
    this.Type = json.Type;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.Id = json.Id;
    this.Name = json.Name;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
  }
}
