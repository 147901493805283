import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-welcome-slides',
	templateUrl: './welcome-slides.page.html',
	styleUrls: ['./welcome-slides.page.scss'],
})
export class WelcomeSlidesPage {
	@Input() isModal: boolean;
	constructor(private modal: ModalController) {}

	navigateHome() {
		this.modal.dismiss();
	}
}
