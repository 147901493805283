import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from './../../../MTAContainerComponent';
import { Justification } from 'src/data/EntityIndex';
import { CommentTypeDescription } from 'src/data/InternalTypes';

@Component({
	selector: 'app-comments-tablet',
	templateUrl: 'comments.component-tablet.html',
	styleUrls: ['./comments.component-tablet.scss'],
})
export class CommentsTabletComponent extends MTAContainerComponent {
	@Input()
	comments: Justification[];
	@Input()
	getCommentType: (value) => CommentTypeDescription;
	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}
}
