import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppStorageProvider } from './appStorage/app-storage';
import { LoadingControllerHandler } from './loading-controller-handler';
import { SharedPlatformProvider } from './platform';

@NgModule({
  declarations: [],
  imports: [IonicModule],
  providers: [AppStorageProvider,  LoadingControllerHandler, SharedPlatformProvider],
  exports: [TranslateModule]
})
export class SharedModule {}
