import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

interface Option { name: string; selected: boolean; }
@Component({
  selector: 'app-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss'],
})

export class ActivityFilterComponent implements OnInit {

  options: Array<Option>;
  constructor(private popoverCtrl: PopoverController, private navParams: NavParams) { }

  selectOption(selectedOption: Option) {
    this.options.forEach( o => o.selected = false);
    selectedOption.selected = true;
    this.popoverCtrl.dismiss({ selectedOption });
  }

  ngOnInit() {
    this.options = this.navParams.data.options;
  }

}
