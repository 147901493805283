import { Injectable } from '@angular/core';
import { Guid } from 'src/shared/GUID';
import { ActivityPSRSnapshot } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

@Injectable({
	providedIn: 'root',
})
export class ActivityPSRSnapshotEntityManager extends BaseEntityManager {
	entityType = ActivityPSRSnapshot;
	entityTableName = 'activityPSRSnapshot';

	private async _deletePSRForProject(projectId: number): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
			try {
				// queryRunner.connection
				//   .createQueryBuilder(queryRunner)
				//   .delete()
				//   .from(this.entityType)
				//   .where('ProjectId = :projectId', { projectId })
				//   .execute();

				await db.activityPSRSnapshot
					.where('ProjectId')
					.equals(projectId)
					.delete();
					
				return;
			} catch (e) {
				throw(e);
			}
		//});
	}

	async bulkInsertWithQueryRunnerForSync(
		projectId: number,
		data: ActivityPSRSnapshot[] | any
	): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
			try {
				// delete PSR for project
				await this._deletePSRForProject(projectId);

				// generate ID for records
				for (const element of data) {
					element.Id = Guid.newGuid().toString();
				}

				// insert PSR
				await this.bulkInsertWithQueryRunner(data);
				return;
			} catch (error) {
				throw(error);
			}
		//});
	}
}
