import { MTABaseEntity } from './MTABaseEntity';

export class Shift extends MTABaseEntity {
  Id: number;
  ProjectId: number;
  Sequence: number;
  Name: string;
  StartTime: string;
  EndTime: string;
  EndTimeOffset: string;
  CreateDate: string;
  CreatedById: number;
  UpdatedDate: string;
  UpdatedById: number;
  Enabled: boolean;
  init(json: any): void {
    this.Id = json.Id;
    this.Name = json.Name;
    this.ProjectId = json.ProjectId;
    this.Sequence = json.Sequence;
    this.StartTime = json.StartTime;
    this.EndTime = json.EndTime;
    this.EndTimeOffset = json.EndTimeOffset;
    this.CreateDate = json.CreateDate;
    this.CreatedById = json.CreatedById;
    this.UpdatedById = json.UpdatedById;
    this.UpdatedDate = json.UpdatedDate;
    this.Enabled = json.Enabled;
  }
}
