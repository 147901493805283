import { MTABaseEntity } from './MTABaseEntity';

export class Activity extends MTABaseEntity {
  Id: number;
  ActivityId: string;
  GUID: string;
  DataDate: string;
  UpdatedDate: string;
  UpdatedBy: string;
  Name: string;
  ProjectId: number;
  StartDate: string;
  ProjectedEndDate: string;
  Status: string;
  InternalStatus: string;
  Type: string;
  WbsId: number;
  WbsPath: string;
  PercentComplete: number;
  InternalPercentComplete: number;
  FinishDate: string;
  PlannedStartDate: string;
  LateStartDate: string;
  EarlyStartDate: string;
  BaselineStartDate: string;
  PlannedFinishDate: string;
  LateFinishDate: string;
  EarlyFinishDate: string;
  BaselineFinishDate: string;
  PrimaryResourceId: number;
  ActualStartDate: string;
  ActualFinishDate: string;
  InternalActualStartDate: string;
  InternalActualFinishDate: string;
  ActualsManuallyUpdated: boolean;
  CanUpdateActuals: boolean;
  IsDeleted: boolean;
  PlannedDuration: number;
  RemainingDuration: number;
  OutOfSequence: boolean;
  // helper properties
  isCommentAvailable: boolean;
  activityPriority: string;
  isReadOnly: boolean;
  isReady: boolean;
  justifications: any[];
  lastRejectComment: any;
  CrewIdListStr: string;
  Text: string;
  UDFTypeTitle: string;
  HasNoContractors: boolean;


  init(json: any): void {
    this.ActivityId = json.ActivityId;
    this.ActualFinishDate = json.ActualFinishDate;
    this.ActualStartDate = json.ActualStartDate;
    this.BaselineFinishDate = json.BaselineFinishDate;
    this.BaselineStartDate = json.BaselineStartDate;
    this.DataDate = json.DataDate;
    this.EarlyFinishDate = json.EarlyFinishDate;
    this.EarlyStartDate = json.EarlyStartDate;
    this.FinishDate = json.FinishDate;
    this.GUID = json.GUID;
    this.Id = json.Id;
    this.InternalPercentComplete = json.InternalPercentComplete;
    this.InternalStatus = json.InternalStatus;
    this.LateFinishDate = json.LateFinishDate;
    this.LateStartDate = json.LateStartDate;
    this.Name = json.Name;
    this.PercentComplete = json.PercentComplete;
    this.PlannedFinishDate = json.PlannedFinishDate;
    this.PlannedStartDate = json.PlannedStartDate;
    this.PrimaryResourceId = json.PrimaryResourceId;
    this.ProjectedEndDate = json.ProjectedEndDate;
    this.ProjectId = json.ProjectId;
    this.StartDate = json.StartDate;
    this.Status = json.Status;
    this.Type = json.Type;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.WbsId = json.WbsId;
    this.InternalActualFinishDate = json.InternalActualFinishDate;
    this.InternalActualStartDate = json.InternalActualStartDate;
    this.ActualsManuallyUpdated = json.ActualsManuallyUpdated;
    this.CanUpdateActuals = json.CanUpdateActuals;
    this.IsDeleted = json.IsDeleted;
    this.PlannedDuration = json.PlannedDuration;
    this.RemainingDuration = json.RemainingDuration;
    this.OutOfSequence = json.OutOfSequence;
  }
}
