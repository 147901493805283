import { Component, OnInit } from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivityInternalStatus } from 'src/data/ActivityInternalStatus';
import { RoleType } from 'src/data/InternalTypes';
import { Filter_Shift } from './../../data/Filters';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
})
export class StatusFilterComponent implements OnInit {
  selectedShiftFilter = this.translate.instant('INSTANT.SelectShiftFilter');
  selectedStatusFilter: ActivityInternalStatus[] = [];
  isCompanyRep = false;
  role: RoleType;
  filters = Filter_Shift;
  statusBtnText = this.translate.instant('INSTANT.FilterSelectAll');

  filterKeys = Object.keys(Filter_Shift);

  showAlertFlag = false;
  showPopupFlag = true;
  alertText = this.translate.instant('INSTANT.FilterStatusAlert');

  statusList = [
    { id: ActivityInternalStatus.NoUpdate, status: this.translate.instant('INSTANT.FilterStatusList.Pending'), checked: false },
    { id: ActivityInternalStatus.Approved, status: this.translate.instant('INSTANT.FilterStatusList.Approved'), checked: false },
    { id: ActivityInternalStatus.Updated, status: this.translate.instant('INSTANT.FilterStatusList.AwaitingApproval'), checked: false },
    { id: ActivityInternalStatus.CantWork, status: this.translate.instant('INSTANT.FilterStatusList.CantWork'), checked: false },
    { id: ActivityInternalStatus.Rejected, status: this.translate.instant('INSTANT.FilterStatusList.Rejected'), checked: false }
  ];
  constructor(private popOverCtrl: PopoverController,  private navParams: NavParams, private alert: AlertController, private translate: TranslateService) {
    this.isCompanyRep = this.navParams.data.isCompanyRep;
    this.selectedShiftFilter = this.navParams.data.shift;
    this.selectedStatusFilter = this.navParams.data.status;
  }

  selectAllOrClear(event) {
    const newStatus = this.statusBtnText === this.translate.instant('INSTANT.Clear') ? false : true;
    this.statusList.forEach(statusObj => {
      statusObj.checked = newStatus;
    });

    this.checkUI();
  }

  ngOnInit() {
    this.statusList.forEach(statusObj => {
      const statusId = statusObj.id;
      if (this.selectedStatusFilter.indexOf(statusId) !== -1) {
        statusObj.checked = true;
      }
    });

    this.checkUI();
  }

  changeStatus() {
    this.checkUI();
  }

  checkStatusBtnText() {
    let isAllSelected = true;
    this.statusList.forEach(statusObj => {
      if (!statusObj.checked) {
        isAllSelected = false;
      }
    });

    this.statusBtnText = isAllSelected ? this.translate.instant('INSTANT.Clear') : this.translate.instant('INSTANT.FilterSelectAll');
  }

  changeShift() {
    this.checkShowAlertFlag();
  }

  checkUI() {
    this.checkStatusBtnText();
    this.checkShowAlertFlag();
  }

  async checkShowAlertFlag() {
    this.showAlertFlag = false;

    if (this.selectedShiftFilter !== Filter_Shift.Shift_All) {
      for (const element of this.statusList) {
        if (element.id === ActivityInternalStatus.Updated) {
          if (element.checked) {
            this.showAlertFlag = true;

            if (this.showPopupFlag) {
              const alert = await this.alert.create({
                header: this.translate.instant('INSTANT.AwaitingApproval'),
                message: this.translate.instant('INSTANT.AwaitingApprovalMessage'),
                buttons: [
                  {
                    text: this.translate.instant('INSTANT.No'),
                    role: 'cancel',
                    handler: () => {
                      this.showPopupFlag = false;
                    }
                  },
                  {
                    text: this.translate.instant('INSTANT.Yes'),
                    handler: () => {
                      this.selectedShiftFilter = Filter_Shift.Shift_All;
                    }
                  }
                ]
              });
              await alert.present();
            }
          }
        }
      }
    }
  }

  submit() {

    this.popOverCtrl.dismiss({ shift: this.selectedShiftFilter, status: this.statusList });
  }

}
