// Activity Internal Status
export enum ActivityInternalStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Updated = 'Updated',
  CantWork = `CantWork`,
  Completed = 'Completed',
  NoUpdate = 'NoUpdate',
}
