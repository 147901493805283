import { Component, Injector } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

/**
 * Generated class for the ActivityActionsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-activity-actions-phone',
  styleUrls: ['./activity-actions.component-phone.scss'],
  templateUrl: 'activity-actions.component-phone.html'
})
export class ActivityActionsPhoneComponent extends MTAContainerComponent {


  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

}
