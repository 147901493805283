import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MTAUtility } from '../../shared/utility';
import { MTAContainerComponent } from './../../MTAContainerComponent';
import { PercentageBarPhoneComponent } from './phone/percentage-bar.component-phone';
import { PercentageBarTabletComponent } from './tablet/percentage-bar.component-tablet';

@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss']
})
export class PercentageBarComponent extends MTAContainerComponent implements OnInit, OnChanges{
  @Input() selectedValue: number;
  @Output() changedValue: EventEmitter<number> = new EventEmitter();
  @Input() disabled = false;
  @Input() minValue: number;
  percentageArray = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

  constructor( public injector: Injector) {
    super(injector);
    this.inputs = {};
   }

  ngOnInit() {
    this.subComponentJson = { tablet: PercentageBarTabletComponent, phone: PercentageBarPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      percentageArray: this.percentageArray,
      disabled: this.disabled,
      selectedValue: this.selectedValue,
      minValue: this.minValue,
      getStyle: value => {
        return this.getStyle(value);
      }
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        const params = data.params;
        if(opt === 'changeValue')
          this.changeValue(params);
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'selectedValue' && this.inputs) {
        const change = changes[propName];
        this.inputs.selectedValue = change.currentValue;
      }
    }
  }

  changeValue(value: number): void {
    this.changedValue.emit(value);
  }

  getStyle(value: number) {
    let style = '';

    if (value === MTAUtility.getRoundedMinValue(this.minValue)) {
      style += 'minValue ';
    }
    if (this.selectedValue === value) {
      style += 'selectedValue ';
    } else {
      if (value >= this.minValue * 100) {
        style += 'availableValue ';
      }
    }

    return style;
  }

}
