import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
  selector: 'app-dependency-list-cell-tablet',
  templateUrl: 'dependency-list-cell.component-tablet.html',
  styleUrls: ['./dependency-list-cell.component-tablet.scss'],
})
export class DependencyListCellTabletComponent extends MTAContainerComponent {
  @Input() activity: any;
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
