import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class LoadingControllerHandler {
  constructor(private _loadingController: LoadingController, private _alertController: AlertController) {}

  public loadingController = null;
  public alertController = null;
  public showAlert = true;

  public async createLoading(config: any): Promise<void> {
    //return new Promise<void>(async (resolve, reject) => {
      this.loadingController = await this._loadingController.create(config);
      return;
   // });
  }

  public async present() {
    await this.loadingController.present();
  }

  public async dismissAllLoaders(): Promise<void> {
  //  return new Promise<void>(async (resolve, reject) => {
      if (this.loadingController) {
        await this.loadingController.dismiss();
      }
      return;
  //  });
  }

  public async presentAlert(errorId) {
    this.alertController = await this._alertController.create({
      header: 'Technical Error',
      subHeader: 'Please contact helpdesk with the following ID:',
      message: `${errorId}`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.alertController = null;
          },
        },
      ],
    });
    await this.alertController.present();
  }
}
