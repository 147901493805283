import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Activity } from 'src/data/EntityIndex';

@Component({
	selector: 'app-util-calendar-phone',
	styleUrls: ['./util-calendar.component-phone.scss'],
	templateUrl: 'util-calendar.component-phone.html',
})
export class UtilCalendarPhoneComponent extends MTAContainerComponent {
	@Input()
	activity: Activity;
	@Input()
	navigateToJustification: () => void;
	constructor(public mtaPlatform: Injector) {
		super(mtaPlatform);
	}
}
