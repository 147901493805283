import { Component, Injector, Input } from '@angular/core';
import { WBS } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-lookahead-wbs-tablet',
	styleUrls: ['./lookahead-wbs.component-tablet.scss'],
	templateUrl: 'lookahead-wbs.component-tablet.html',
})
export class LookaheadWBSTabletComponent extends MTAContainerComponent {
	@Input()
	mtaWbs: WBS;
	@Input()
	isCompanyRep: boolean;
	@Input()
	isChevronUser: boolean;
	@Input()
	isExpanded: boolean;
	@Input()
	iconExpand: string;
	@Input()
	crewsIdStr: string;
	@Input()
	isCompletedAllBtnDisabled: boolean;
	@Input()
	isApproveAllToggleDisabled: boolean;
	@Input()
	getWBSHierarchyNames: string;

	constructor(public injector: Injector) {
		super(injector);
	}

	toggle() {
		this.isExpanded = !this.isExpanded;
		this.iconExpand = this.isExpanded
			? 'chevron-up-outline'
			: 'chevron-down-outline';
		this.something.emit({ operation: 'toggle', params: null });
	}
}
