import { Injectable } from '@angular/core';
import { User } from 'src/data/EntityIndex';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	getUser(): User {
		const firstName = localStorage.getItem('FirstName');
		const lastName = localStorage.getItem('LastName');
		const uid = localStorage.getItem('UserId');
		const cai = localStorage.getItem('CAI');
		if (firstName === null || lastName === null || uid === null) {
			return null;
		} else {
			const user: User = new User();
			user.FirstName = firstName;
			user.LastName = lastName;
			user.Id = parseInt(uid, 10);
			user.Cai = cai;
			return user;
		}
	}
}
