import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ADALProvider } from 'src/shared/adal/adal';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public adalService: ADALProvider, private platform: Platform) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('login') === false && request.url.includes('user/current') === false) {
      request = this.addAuthHeader(request);
      return next.handle(request).pipe(
        catchError((err) => {
          if (err.status === 401) {
            //  this.adalService.msalLogin();
              request = this.addAuthHeader(request);
              return next.handle(request);
           // }
          } else {
            return throwError(err);
          }
        }),
      );
    } else {
      return next.handle(request);
    }
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const token = this.adalService.getToken();

    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return request;
    }
  }
}
