import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { UserEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable()
export class UserServiceProvider extends BaseServiceProvider implements IDownloadable {
  public _entityManger = UserEntityManager;

  public serviceName = 'user';

  public downloadOperationName = 'byproject';

  public getDownloadUrl(config?: SyncConfig): string {
    if (this.isDownloadable) {
      return `${this._baseURL}/${this.serviceName}/${this.downloadOperationName}/${config.project}`;
    } else {
      return null;
    }
  }

  public download(config: SyncConfig): Promise<any> {
    return super.download_base(config);
  }
}
