import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Activity } from 'src/data/EntityIndex';

@Component({
  selector: 'app-percentage-bar-modal',
  templateUrl: 'percentage-bar-modal.component-phone.html',
  styleUrls: ['./percentage-bar-modal.component-phone.scss'],
})
export class PercentageBarModalPhoneComponent extends MTAContainerComponent {
	@Input()
	activity: Activity;
	@Input()
	isCompanyRep: boolean;
	@Input()
	dismissPopOver: () => void;
	@Input()
	changePercentage: (value) => void;
	@Input()
	navigateToJustification: () => void;
  
  isExpanded: boolean;
  iconExpand: string;
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'chevron-up' : 'chevron-down';
    this.something.emit({ operation: 'toggle', params: null });
  }

  confirmCompleteAll() {
    this.something.emit({ operation: 'confirmCompleteAll', params: null });
  }

  confirmApproveAll() {
    this.something.emit({ operation: 'confirmApproveAll', params: null });
  }

  completeActivityIOS(activity, item) {
    const data = { activity, item };
    this.something.emit({ operation: 'completeActivityIOS', params: data });
  }

  cannotWork(activity, item) {
    const data = { activity, item };
    this.something.emit({ operation: 'cannotWork', params: data });
  }
  presentProgressModal(event, item, activity) {
    const data = { event, activity, item };
    this.something.emit({ operation: 'presentProgressModal', params: data });
  }
}
