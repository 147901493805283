import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActivityStartJustificationType } from 'src/data/EntityIndex';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { PreviousJustificationsPhoneComponent } from './phone/previous-justifications.component-phone';
import { PreviousJustificationsTabletComponent } from './tablet/previous-justifications.component-tablet';

@Component({
  selector: 'app-previous-justification',
  templateUrl: './previous-justification.component.html',
  styleUrls: ['./previous-justification.component.scss'],
})
export class PreviousJustificationComponent extends MTAContainerComponent implements OnInit {
  previousJustificationsList: any[];
  @Input() previousJustifications: any[];
  @Input() justificationTypes: ActivityStartJustificationType[];

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.subComponentJson = { tablet: PreviousJustificationsTabletComponent, phone: PreviousJustificationsPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = { previousJustifications: this.previousJustifications, justificationTypes: this.justificationTypes };
    this.outputs = {
      // something: data => {
      //   let opt = data['operation'];
      //   let params = data['params'];
      //   switch (opt) {
      //     case '':
      //       break;
      //   }
      // }
    };
  }

}
