import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { ActivityStartJustification } from 'src/data/EntityIndex';

@Component({
  selector: 'app-justification-cell-phone',
  templateUrl: 'justification-cell.component-phone.html',
  styleUrls: ['./justification-cell.component-phone.scss'],
})
export class JustificationCellPhoneComponent extends MTAContainerComponent {
	@Input()
	justification: ActivityStartJustification;
	@Input()
	shiftName: string;
	@Input()
	shiftIcon: string;
	@Input()
	getUserInitial: () => string;
	@Input()
	getUserFullname: () => string;
	@Input()
	getJustificationTypeName: (value) => string;
  
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
