import { UDFCodeValue } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';

export class UDFCodeValueEntityManager extends BaseEntityManager {
  entityType = UDFCodeValue;
	entityTableName = 'udfCodeValue';

  async insertUDFCodeValueData(udfCodeValues: UDFCodeValue[]) {
    await this.insertData(udfCodeValues);
  }

  async insertorUpdateUDFCodeValueData(udfCodeValues: UDFCodeValue[]) {
    await this.insertOrUpdateData(udfCodeValues);
  }
}
