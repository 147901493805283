import { Injectable } from '@angular/core';
import { MTAUtility } from 'src/shared/utility';
import { ActivityInternalStatus } from '../ActivityInternalStatus';
import { Activity } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';
import * as moment from 'moment';

@Injectable()
export class ActivityEntityManager extends BaseEntityManager {
	entityType = Activity;
	entityTableName = 'activity';

	async insertorUpdateActivityData(activities: Activity[]) {
		await this.insertOrUpdateData(activities);
	}

	changePercentage(
		activity: Activity,
		value: number,
		isCompanyRep: boolean
	): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
			// Adding rounding to match the rounding done in activity cell minValue
			const minValue = MTAUtility.getRoundedMinValue(
				Math.round(activity.PercentComplete * 100) / 100
			);
			if (value < minValue || value > 100) {
				throw(
					new Error(
						'Internal percent must be equal or higher than primavera percent and less or equal to 100'
					)
				);
			}

			activity.InternalPercentComplete = value;
			if (value === minValue) {
				this.changeStatus(activity, null);
				return;
			}

			if (isCompanyRep) {
				this.changeStatus(activity, ActivityInternalStatus.Approved);
			} else {
				this.changeStatus(activity, ActivityInternalStatus.Updated);
			}

			return;
		//});
	}

	changeStatus(activity: Activity, status: ActivityInternalStatus) {
		activity.InternalStatus = status;
		if (activity) this.setAndRestActivityDates(activity);
		activity.UpdatedDate = new Date().toISOString();
		activity.UpdatedBy = localStorage.getItem('UserId');
	}

	completeAllActivities(activities: Activity[], isCompanyRep: boolean) {
		for (const activity of activities) {
			if (activity.InternalPercentComplete !== 100) {
				this.changePercentage(activity, 100, isCompanyRep);
			} else {
				if (
					activity.InternalStatus !== ActivityInternalStatus.Approved &&
					isCompanyRep
				) {
					this.changeStatus(activity, ActivityInternalStatus.Approved);
				}
			}
		}
	}

	approveAllActivities(activities: Activity[]) {
		for (const activity of activities) {
			if (
				activity.InternalStatus !== ActivityInternalStatus.Approved &&
				activity.InternalPercentComplete !==
					MTAUtility.getRoundedMinValue(activity.PercentComplete)
			) {
				this.changeStatus(activity, ActivityInternalStatus.Approved);
			}
		}
	}

	setAndRestActivityDates(activity: Activity) {
		if (
			!activity.ActualFinishDate &&
			activity.InternalPercentComplete === 100
		) {
			activity.ActualFinishDate = MTAUtility.getCurrentProjectTimeString();
			activity.FinishDate = activity.ActualFinishDate;
			activity.ActualStartDate =
				MTAUtility.checkAndSetActualStartDateWithHourDelay(
					activity.ActualStartDate,
					activity.ActualFinishDate
				);
		}
		if (
			!activity.ActualStartDate &&
			activity.PercentComplete === 0 &&
			activity.InternalPercentComplete > 0
		) {
			activity.ActualStartDate = MTAUtility.getCurrentProjectTimeString();
		}
		if (
			activity.ActualStartDate &&
			activity.InternalPercentComplete === 0 &&
			activity.PercentComplete === 0
		) {
			activity.ActualStartDate = null;
		}
		if (
			activity.ActualFinishDate &&
			activity.InternalPercentComplete !== 100 &&
			activity.PercentComplete * 100 !== 100
		) {
			activity.ActualFinishDate = null;
			activity.FinishDate = activity.PlannedFinishDate;
		}

		// for reject workflow
		if (activity.InternalStatus === ActivityInternalStatus.Rejected) {
			if (activity.ActualFinishDate) {
				activity.ActualFinishDate = null;
				activity.FinishDate = activity.PlannedFinishDate;
			}

			if (activity.ActualStartDate && activity.PercentComplete === 0) {
				activity.ActualStartDate = null;
			}
		}
	}

	getDisplayPercentage(activity: Activity): number {
		let displayVal = 0;
		if (activity) {
			let percentComplete = 0;
			if (activity.PercentComplete !== 0) {
				if (activity.PercentComplete <= 0.1) {
					percentComplete = 10;
				} else if (
					activity.PercentComplete > 0.9 &&
					activity.PercentComplete < 1
				) {
					percentComplete = Math.trunc(activity.PercentComplete * 10) * 10;
				} else {
					percentComplete = Math.round(activity.PercentComplete * 10) * 10;
				}
			}
			displayVal =
				activity.InternalPercentComplete >= percentComplete
					? activity.InternalPercentComplete
					: percentComplete;
		}
		return displayVal;
	}

	async getActivityLatestRejectComment(activity: Activity): Promise<any[]> {
		//return new Promise<any[]>(async (resolve, reject) => {
			try {
				const justifications = await db.justification
					.where('ActivityId')
					.equals(activity.Id)
					.and(
						(j) => j.IsDeleted === false && ['CR', 'SR', 'SO', 'AC'].includes(j.Type)
					).reverse()
					.offset(0)
					.sortBy('UpdatedDate');
				const user = await db.user
					.where('Id')
					.equals(justifications[0].CreatedUserId)
					.first();
				return([
					{
						...justifications[0],
						firstName: user?.FirstName,
						lastName: user?.LastName,
						nickName: user?.PersonalName,
						id: user.Id,
					},
				]);
			} catch (e) {
				throw(e);
			}
		//});
	}

	async getRejectCommentsForActivities(activities: Activity[]): Promise<any[]> {
		//return new Promise<any[]>(async (resolve, reject) => {
			if (activities && activities.length > 0) {
				const idStr = activities.map((a) => a.Id);
				try {
					const justifications = await db.justification
						.where('ActivityId')
						.anyOf(idStr)
						.and(
							(j) =>
								j.IsDeleted === false && ['CR', 'SR', 'SO', 'AC'].includes(j.Type)
						)
						.sortBy('UpdatedDate');
					const users = await db.user
						.where('Id')
						.anyOf(justifications.map((j) => j.CreatedUserId))
						.toArray();
					return(
						justifications
							.map((j) => {
								const user = users.find((u) => u.Id == j.CreatedUserId);
								return {
									...j,
									firstName: user?.FirstName,
									lastName: user?.LastName,
									nickName: user?.PersonalName,
									id: user.Id,
								};
							})
							.sort((a, b) =>
								moment.utc(a.UpdatedDate).diff(moment.utc(b.UpdatedDate))
							).reverse()
					);
				} catch (e) {
					throw(e);
				}
			} else {
				return(null);
			}
	//	});
	}

	async getActivity(activityId: number): Promise<Activity> {
		//return new Promise<Activity>(async (resolve, reject) => {
			try {
				const activity = await db.activity
					.where('Id')
					.equals(activityId)
					.first();
				if (activity) {
					return(activity as Activity);
				} else {
					return(null);
				}
			} catch (e) {
				throw(e);
			}
		//});
	}
}
