import { Component, EventEmitter, Injector } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { MTAContainerComponent } from 'src/MTAContainerComponent';

@Component({
  selector: 'app-activity-actual-start-finish-modal',
  templateUrl: './activity-actual-start-finish-modal.component.html',
  styleUrls: ['./activity-actual-start-finish-modal.component.scss'],
})
export class ActivityActualStartFinishModalComponent extends MTAContainerComponent {
  public startDate: Date;
  public finishDate: Date;
  public maxStartDate: string;
  public maxFinishDate: string;
  public minFinishDate: string;
  public dateFormat: string;
  public actualDisplayFormat: string;
  public percentCompleted: number;
  private finishDateEmitter: EventEmitter<Date>;
  private startDateEmitter: EventEmitter<Date>;

  constructor(public injector: Injector, private navParams: NavParams, private popoverCtrl: PopoverController, private toastCtrl: ToastController) {
    super(injector);
    this.startDateEmitter = navParams.data.startDateEmitter;
    this.finishDateEmitter = navParams.data.finishDateEmitter;
    this.percentCompleted = navParams.data.percentCompleted;
    this.startDate = navParams.data.startDate;
    this.actualDisplayFormat = moment.localeData().longDateFormat('L') + ' HH:mm';
    this.minFinishDate =  this.toISOLocal(new Date(this.startDate));
    this.maxFinishDate =  this.toISOLocal(new Date());
    if (navParams.data.finishDate) {
      this.finishDate = navParams.data.finishDate;
      this.maxStartDate = this.toISOLocal(new Date(this.finishDate));
    } else {
      this.maxStartDate = this.maxFinishDate;
    }
  }
  async updateStartDate() {
    this.minFinishDate =  this.toISOLocal(new Date(this.startDate));
    this.startDateEmitter.emit(this.startDate);
  }

  async updateFinishDate() {
    this.maxStartDate = this.toISOLocal(new Date(this.finishDate));
    this.finishDateEmitter.emit(this.finishDate);
  }
  async closePopover() {
    this.popoverCtrl.dismiss();
  }

  toISOLocal(d) {
    const z = n => (n < 10 ? '0' : '') + n;
    let off = d.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);
    // tslint:disable-next-line:no-bitwise
    return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' + z(d.getDate()) + 'T' + z(d.getHours()) + ':' + z(d.getMinutes()) + ':' + z(d.getSeconds()) + sign + z(off / 60 | 0) + ':' + z(off % 60);
  }
}
