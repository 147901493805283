import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Shift } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

@Injectable({ providedIn: 'root' })
export class ShiftEntityManager extends BaseEntityManager {
	entityType = Shift;
	entityTableName = 'shift';

	async getProjectSift(projectId: number): Promise<Shift[]> {
		//return new Promise<Shift[]>(async (resolve, reject) => {
		try {
			const shift = await db.shift
				.where('ProjectId')
				.equals(projectId)
				.and((s) => s.Enabled === true)
				.sortBy('Sequence');
			return shift as Shift[];
		} catch (e) {
			throw e;
		}
		//});
	}

	async getCurrentShift(projectId: number): Promise<Shift | null> {
		try {
			const shifts: Shift[] = await this.getProjectSift(projectId);
			let currentShift = null;
			if (shifts?.length > 0) {
				let shiftPos = 0;
				const now1 = moment();
				for (let i = 0; i < shifts.length; i++) {
					shiftPos = this._evalShiftPos(shifts, i, now1);
				}
				currentShift = shifts[shiftPos];
			}
			return currentShift;
		} catch (e) {
			return null;
		}
	}

	async getShiftBySequence(
		projectId: number,
		sequence: number
	): Promise<Shift> {
		//	return new Promise<Shift>(async (resolve, reject) => {
		try {
			const shift = await db.shift
				.where({
					Sequence: sequence,
					ProjectId: projectId,
				})
				.first();
			return shift as Shift;
		} catch (e) {
			throw e;
		}
		//	});
	}

	private _evalShiftPos(
		shifts: Shift[],
		i: number,
		now1: moment.Moment
	): number {
		const shift = shifts[i];
		const startTime = moment(shift.StartTime, 'hh:mm:ss');
		const endTime = moment(shift.EndTime, 'hh:mm:ss');
		const nextShiftStartTime =
			i < shifts.length - 1
				? moment(shifts[i + 1].StartTime, 'hh:mm:ss')
				: moment(shift.StartTime, 'hh:mm:ss');
		const isOverNightShift = endTime.isBefore(startTime);
		const condition = isOverNightShift
			? now1.isSameOrAfter(startTime) || now1.isSameOrBefore(endTime)
			: now1.isSameOrAfter(startTime) && now1.isSameOrBefore(endTime);
		if (condition) {
			return i;
		} else if (now1.isAfter(endTime) && now1.isBefore(nextShiftStartTime)) {
			return i < shifts.length - 1 ? i + 1 : shifts.length - i;
		}
	}
}
