import { Component, Injector, Input } from '@angular/core';
import { Activity } from '../../../data/EntityIndex';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

/**
 * Generated class for the ActivityActionsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
	selector: 'app-activity-actual-start-finish-tablet',
	styleUrls: ['./activity-actual-start-finish.component-tablet.scss'],
	templateUrl: 'activity-actual-start-finish.component-tablet.html',
})
export class ActivityActualStartFinishTabletComponent extends MTAContainerComponent {
	@Input()
	activity: Activity;
	@Input()
	maxStartDate: string;
	@Input()
	maxFinishDate: string;
	@Input()
	minFinishDate: string;
	@Input()
	actualDisplayFormat: string;
	@Input()
	disabled: boolean;
	@Input()
	updateDates: (eve) => void;
	@Input()
	updateFinishDate: () => void;
	@Input()
	updateStartDate: () => void;

	constructor(public injector: Injector) {
		super(injector);
	}
}
