import { MTABaseEntity } from './MTABaseEntity';

export class Crew extends MTABaseEntity {
  CrewId: number;
  ProjectId: number;
  Name: string;
  Description: string;
  Type: string;
  ParentCrewId: number;
  CreatedBy: string;
  CreateDate: string;
  IsDeleted: boolean;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.CrewId = json.CrewId;
    this.IsDeleted = json.IsDeleted;
    this.Type = json.Type;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.ProjectId = json.ProjectId;
    this.Name = json.Name;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.ParentCrewId = json.ParentCrewId;
  }
}
