import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MsalService } from '@azure/msal-angular';
@Injectable({
	providedIn: 'root',
})
export class ADALProvider {
	private isInited: boolean = false;
	private adToken: string;
	private _upn: string;
	get upn(): string {
		if (this._upn) {
			return this._upn;
		} else {
			return localStorage.getItem('UPN');
		}
	}
	set upn(value: string) {
		localStorage.setItem('UPN', value);
		this._upn = value;
	}

	constructor(
		private readonly _msalService: MsalService,
		private readonly _platform: Platform
	) {
		_platform.ready().then(async () => {
			this._init();
		});
	}


	getToken(): string {
		return this.adToken;
	}

	clearTokenCache(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			try {
				this.adToken = undefined;
				this._msalService.logout();
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	logOut(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			try {
				this._msalService.logoutPopup({
					mainWindowRedirectUri: '/',
				});
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}
	private _init(): void {
		if (!this.isInited) {
			this.isInited = true;
		}
	}
}
