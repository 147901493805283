import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from './../../../MTAContainerComponent';
import { Justification } from 'src/data/EntityIndex';
import { CommentTypeDescription } from 'src/data/InternalTypes';

@Component({
  selector: 'app-comments-phone',
  templateUrl: 'comments.component-phone.html',
  styleUrls: ['./comments.component-phone.scss'],
})
export class CommentsPhoneComponent extends MTAContainerComponent {
	@Input()
	comments: Justification[];
	@Input()
	getCommentType: (value) => CommentTypeDescription;
  
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }
}
