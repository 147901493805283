// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-finish-date-content {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/activity-actual-start-finish-modal/activity-actual-start-finish-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".start-finish-date-content {\r\n    padding: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
