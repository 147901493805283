import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Activity } from 'src/data/EntityIndex';

/**
 * Generated class for the ActivityActionsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-activity-actual-start-finish-phone',
  styleUrls: ['./activity-actual-start-finish.component-phone.scss'],
  templateUrl: 'activity-actual-start-finish.component-phone.html'
})
export class ActivityActualStartFinishPhoneComponent extends MTAContainerComponent {
	@Input()
	activity: Activity;
	@Input()
	maxStartDate: string;
	@Input()
	maxFinishDate: string;
	@Input()
	minFinishDate: string;
	@Input()
	actualDisplayFormat: string;
	@Input()
	disabled: boolean;
	@Input()
	updateDates: (eve) => void;
	@Input()
	updateFinishDate: () => void;
	@Input()
	updateStartDate: () => void;
  
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }


}
