import { Component, Injector, Input } from '@angular/core';
import { MTAContainerComponent } from '../../../MTAContainerComponent';
import { Activity } from 'src/data/EntityIndex';


@Component({
  selector: 'app-activity-detail-tablet',
  styleUrls: ['./activity-detail.component-tablet.scss'],
  templateUrl: 'activity-detail.component-tablet.html'
})
export class ActivityDetailTabletComponent extends MTAContainerComponent {

  @Input() activity: Activity;
  constructor(public mtaPlatform: Injector) {
    super(mtaPlatform);
  }

}
