import { Crew } from './Crew';
import { MTABaseEntity } from './MTABaseEntity';

export class CrewAssignment extends MTABaseEntity {
  CrewId: number;
  ProjectId: number;
  UserId: number;
  CreatedBy: string;
  CreateDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
  IsDeleted: boolean;
  IsReadOnly: boolean;
  Crew: Crew;
  HasNoContractors: boolean;
  init(json: any): void {
    this.IsDeleted = json.IsDeleted;
    this.ProjectId = json.ProjectId;
    this.UserId = json.UserId;
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.CrewId = json.CrewId;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
    this.IsReadOnly = json.IsReadOnly;
  }
}
