import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ActivityInternalStatus } from './../../data/ActivityInternalStatus';
import { Activity } from './../../data/EntityIndex';
import { ActivityActionsPhoneComponent } from './phone/activity-actions.component-phone';
import { ActivityActionsTabletComponent } from './tablet/activity-actions.component-tablet';

@Component({
  selector: 'app-activity-actions',
  templateUrl: './activity-actions.component.html',
  styleUrls: ['./activity-actions.component.scss'],
})
export class ActivityActionsComponent extends MTAContainerComponent implements OnInit {
  @Input() isCompanyRep = false;
  @Input() activity: Activity;
  @Input() status: ActivityInternalStatus;
  @Input() disabled = false;
  @Output() changedStatus: EventEmitter<ActivityInternalStatus> = new EventEmitter();
  @Output() viewDetails: EventEmitter<Activity> = new EventEmitter();
  @Output() cantWork: EventEmitter<Activity> = new EventEmitter();
  @Output() viewTONotes: EventEmitter<number> = new EventEmitter<number>();

  constructor(public injector: Injector) {
    super(injector);
   }

   ngOnInit() {
    this.subComponentJson = { tablet: ActivityActionsTabletComponent, phone: ActivityActionsPhoneComponent };
    this.subComponent = this.getComponentType();
    this.inputs = {
      activity: this.activity,
      status: this.status,
      isCompanyRep: this.isCompanyRep
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        const params = data.params;
        switch (opt) {
          case 'viewActivity':
            this.viewActivity();
            break;
          case 'changeStatus':
            this.changeStatus(params);
            break;
          case 'cantWork':
            this.navJustification();
            break;
          case 'navigateToTONotes':
            this.navigateToTONotes(params);
            break;
        }
      }
    };

  }
  navJustification() {
    this.cantWork.emit(this.activity);
  }
  viewActivity() {
    //// to view activity details
    this.viewDetails.emit(this.activity);
  }


  changeStatus(status: any) {
    this.changedStatus.emit(status);
  }

  navigateToTONotes(hierarchyId: number) {
    this.viewTONotes.emit(hierarchyId);
  }
}
