import { Injectable } from '@angular/core';
import { WBS } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

@Injectable({
  providedIn:'root'
})
export class WBSEntityManager extends BaseEntityManager {
  entityType = WBS;
	entityTableName = 'wbs';

  async insertWBSData(wbsData: WBS[]) {
    await this.insertData(wbsData);
  }

  async insertorUpdateWBSData(wbsData: WBS[]) {
    await this.insertOrUpdateData(wbsData);
  }

  async getHierarchyForEnvent(eventId: number): Promise<WBS[]> {
   // return new Promise<WBS[]>(async (resolve, reject) => {
      const hierarchies = await db.wbs.where("ProjectId").equals(eventId).sortBy('Id');
      return(hierarchies as WBS[]);
   // });
  }
}
