import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map, retry, timeout } from 'rxjs/operators';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  protected baseURL = environment.BaseAPI;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  
  constructor(
    private http: HttpClient,
    ) {}
   
  async getLastLoginTime() {
    let LastLogin: Date;
    return LastLogin;
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;       
      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  pingServer() {
   this.checkNetworkStatus();
    return new Promise<any>((resolve, reject) => {
      try {      
         if (this.networkStatus) {
          this.http
            .get(`${this.baseURL}/datetime`)
            .pipe(retry(10))
            .pipe(timeout(180000))
            .subscribe(
              () => {
                resolve(true); // TODO - How to filter exception from Azure API's
              },
              () => {
                resolve(false);
              },
            );
        } else {
          resolve(false);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
