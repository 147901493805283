import { MTABaseEntity } from './MTABaseEntity';

export class ProjectConfig extends MTABaseEntity {
  Id: number;
  ProjectId: number;
  ActivityCodeTypeId: number;
  Type: string;
  CreatedBy: string;
  CreateDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
  init(json: any): void {
    this.CreateDate = json.CreateDate;
    this.CreatedBy = json.CreatedBy;
    this.Id = json.Id;
    this.Type = json.Type;
    this.ActivityCodeTypeId = json.ActivityCodeTypeId;
    this.ProjectId = json.ProjectId;
    this.UpdatedBy = json.UpdatedBy;
    this.UpdatedDate = json.UpdatedDate;
  }
}
