import { Injectable } from '@angular/core';
import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable } from 'src/services/sync/SyncInterface';
import { RelationshipEntityManager } from '../EntityManagerIndex';
import { BaseServiceProvider } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class RelationshipServiceProvider
	extends BaseServiceProvider
	implements IDownloadable
{
	_entityManger = RelationshipEntityManager;
	serviceName = 'relationship';

	getDownloadUrl(config?: SyncConfig): string {
		if (this.isDownloadable) {
			return `${this._baseURL}/${this.serviceName}/${config.project}`;
		} else {
			return null;
		}
	}

	download(config: SyncConfig): Promise<any> {
		return super.download_base(config);
	}
}
